import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setCompanyName } from '../../state/globalsReducer';

export const useCompanyName = () => {
  const name = useSelector(state => state.globals.companyName);
  const dispatch = useDispatch();

  const setName = (args) => {
    dispatch(setCompanyName(args));
  };

  return [name, setName];
};

export default useCompanyName;
