import { Badge } from '@lightspeed/flame/Badge/next';
import { useTranslation } from 'react-i18next';

export const SyncStatusBadge = ({ type: typeRaw }) => {
  const { t } = useTranslation();

  let type = 3;
  if ([1, true].includes(typeRaw)) {
    type = 1;
  } else if ([0, false].includes(typeRaw)) {
    type = 0;
  } else if (typeRaw === 2) {
    type = 2;
  } else if (typeRaw === 4) {
    type = 4;
  }
  const typeString = ['danger', 'success', 'default', 'default', 'warning'][type];
  const label = [t('Failed'), t('Success'), t('Queued'), t('No Data'), t("hold")][type];
  return <Badge variant={typeString}>
    {label}
  </Badge>;
};