import { TextLink, Heading3, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Autocomplete } from '../fields';
import useAccounts from '../../util/useAccounts';
import { useToasts } from '@lightspeed/flame/Toaster';
import useAppSubmitting from '../../util/useAppSubmitting';
import { LabelRequired } from '../custom/LabelRequired';
import { useTranslation } from 'react-i18next';
import useIsInputsOnlyLayout from '../../util/hooks/useIsInputsOnlyLayout';

const DefaultAccountLayout = ({ heading, description, children }) => {
  return <Box mb="1.5rem">
    <Heading3 mb="0.375rem">
      {heading}
    </Heading3>
    <Text color="gray-600" mr="3.25rem"
      mb="1.5rem"
      size="small"
    >
      {description}
    </Text>
    {children}
  </Box>;
}

const DefaultInventoryAccount = (props) => {
  const [isLoading, accountsList] = useAccounts('inventory');
  const { t } = useTranslation();

  return <DefaultAccountLayout
    heading={t('Default inventory account')}
    description={t('Default Inventory desc')}
  >
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="defaultInventoryAccount">
          {t('Default inventory account')}
        </LabelRequired>
        <Autocomplete
          id="defaultInventoryAccount"
          name="defaultInventoryAccount"
          isLoading={isLoading}
          options={accountsList}
          placeholder={t('Select an account')}
        />
      </Box>
    </Flex>
  </DefaultAccountLayout>;
};

const DefaultCogsAccount = (props) => {
  const [isLoading, accountsList] = useAccounts('cogs');
  const { t } = useTranslation();

  return <DefaultAccountLayout
    heading={t('Default COGS account full')}
    description={t('Default COGS desc')}
  >
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="defaultCogsAccount">
          {t('Default COGS account')}
        </LabelRequired>
        <Autocomplete
          id="defaultCogsAccount"
          name="defaultCogsAccount"
          isLoading={isLoading}
          options={accountsList}
          placeholder={t('Select an account')}
        />
      </Box>
    </Flex>
  </DefaultAccountLayout>;
};

export const MapDefaultIndividualCogsInventory = ({ formik, fetchedConfig, ...props }) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const {
    isMapPurchaseInvoice,
    isTrackInventory,
    isMapCogs,
    defaultInventoryAccount,
    defaultCogsAccount,
  } = formik.values;
  const showCogsMapping = (isMapCogs || (isMapPurchaseInvoice && isTrackInventory));
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const [isAppSubmitting] = useAppSubmitting();

  const gotoIndividualMapping = () => {
    const state = {
      fetchedMapping: {
        itemsMapping: fetchedConfig && fetchedConfig.itemSpecificInventoryCogsAccounts,
        categoriesMapping: fetchedConfig && fetchedConfig.categorySpecificInventoryCogsAccounts,
      },
    };

    state.isParentCategory = fetchedConfig && fetchedConfig.swapSuperParentCategoryFromProducts;
    state.swapCategoryFromProducts = fetchedConfig && fetchedConfig.swapCategoryFromProducts;

    props.navigate('../inventory-cogs-mapping', { state });
  };

  const handleNavigation = () => {
    if (isAppSubmitting) {
      addToast(t('Please wait for data to save'), { appearance: 'error' });
    } else if (defaultInventoryAccount && (!showCogsMapping || defaultCogsAccount)) {
      gotoIndividualMapping();
    } else {
      addToast(t(isInputsOnlyLayout ? 'DefaultAccountTextInputFirst' : 'DefaultAccountFirst'), { appearance: 'error' });
    }
  };

  return <Box>
    {(isMapCogs || isMapPurchaseInvoice) &&
      <DefaultInventoryAccount />
    }
    {showCogsMapping &&
      <DefaultCogsAccount />
    }
    <TextLink fontWeight="bold" fontSize="text-s" onClick={handleNavigation}>
      Map individual accounts
    </TextLink>
  </Box>;
};
