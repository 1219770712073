import { useField } from 'formik';
import { Text } from '@lightspeed/flame/Text';
import { Select } from '@lightspeed/flame/Select';
import { Radio } from '@lightspeed/flame/Radio';
import { Input } from '@lightspeed/flame/Input';
import { FormHelper } from '@lightspeed/flame/FormField';
import { Switch } from '@lightspeed/flame/Switch';
import { Bone } from '@lightspeed/flame/Bone';
import { Autocomplete, Creatable, formatCreateLabel } from '@lightspeed/flame/Autocomplete';
import { useAppSubmitting } from '../../util/useAppSubmitting';
import { withTheme } from 'emotion-theming';
import AutocompleteStyle from '@lightspeed/flame/Autocomplete/Autocomplete.style';
import AUTOCOMPLETE_CLASS_NAME from '@lightspeed/flame/Autocomplete/autocomplete-classname';
import { useHasSubmittedWithErrors } from '../../util/hooks/useHasSubmittedWithErrors';
import { Checkbox } from '@lightspeed/flame/Checkbox';
import useIsInputsOnlyLayout from '../../util/hooks/useIsInputsOnlyLayout';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setErrorFields } from '../../state/globalsReducer';
import { IconRemove } from '@lightspeed/flame/Icon/Remove';
import { Flex } from '@lightspeed/flame/Core';

const AutocompleteField = ({ theme, hasError: hasErrorInitial, ...props }) => {
  const [field, meta, helpers] = useField(props.name);
  const selectedOption = props.options.find(option => (option.value === meta.value));
  const [isAppSubmitting] = useAppSubmitting();
  const [forceShowErrors] = useHasSubmittedWithErrors();
  const errFields = useSelector(state => state.globals.errorFields)
  const { t } = useTranslation();
  let status = {};
  let conditionalProps = {};
  let SelectField = Autocomplete;
  let hasError = hasErrorInitial ?? false;
  const dispatch = useDispatch()

  const handleChange = (option, effect) => {
    let newfields = errFields;
    const fieldname = props?.fieldName ?? props?.name

    if (newfields) {
      for (let key = 0; key < Object.keys(newfields)?.length; key++) {
        let filtered = newfields[Object.keys(newfields)[key]]?.filter((item) => item !== fieldname)
        if (filtered?.length !== newfields[Object.keys(newfields)[key]]?.length) {
          newfields = {
            ...newfields,
            [Object.keys(newfields)[key]]: filtered
          }
        }
      }
    }
    dispatch(setErrorFields(newfields))

    if (effect?.action === "clear") {
      helpers.setValue("");
    } else {
      helpers.setValue(option.value);
    }

  };

  const checkAnyErrors = () => {

    if (errFields) {
      for (let key = 0; key < Object.keys(errFields)?.length; key++) {
        for (let fields = 0; fields < errFields[Object.keys(errFields)[key]]?.length; fields++) {
          if (errFields[Object.keys(errFields)[key]][fields] === props?.name || errFields[Object.keys(errFields)[key]][fields] === props?.fieldName) {
            return true;
          }
        }
      }
    }

    return false;
  }

  if ((((meta.touched || forceShowErrors) && meta.error) || checkAnyErrors()) && !props?.disabled) {
    status = { type: 'error', message: meta.error };
    hasError = true;
  } else {
    hasError = false;
  }

  // Show "Add account" option 
  if (props.onCreateOption) {
    SelectField = Creatable;
    if (hasError) {
      conditionalProps.className = `${AUTOCOMPLETE_CLASS_NAME}--error`;
    }
  }

  // Fixe the overlapping problem of the component
  const flameStyles = AutocompleteStyle(theme);
  const flameMenuStyle = flameStyles.menu;
  const menuStyle = provided => ({
    ...flameMenuStyle(provided),
    zIndex: 5,
  });
  const placeholderOverflowStyle = provided => ({
    ...(flameStyles.placeholder(provided)),
    whiteSpace: 'nowrap',
  });

  return <>
    <Flex flexDirection={"row"} alignItems={"center"}>
      <div style={{ width: "100%" }}>
        <SelectField
          {...field} {...meta}
          value={selectedOption || ''}
          onChange={handleChange}
          isDisabled={isAppSubmitting || props.isLoading || props.disabled}
          menuPlacement="auto"
          hasError={hasError}
          styles={Object.assign({}, flameStyles, { menu: menuStyle, placeholder: placeholderOverflowStyle })}
          formatCreateLabel={
            (label) =>
              formatCreateLabel(<span>{t('Create a new account')}</span>)
          }
          isClearable
          status={status}
          isLoading={props.isLoading || props.isLoadingOptions}
          {...conditionalProps}
          {...props}
        />
      </div>
    </Flex>
    {
      status.message &&
      <FormHelper mt={1} status={status.type}>
        {status.message}
      </FormHelper>
    }
  </>;
};

const AutocompleteFieldWithTheme = withTheme(AutocompleteField);

const AutocompleteFieldProtected = (props) => {
  const isLoading = !props.options;
  return <>
    {isLoading ?
      <Bone height="2rem" /> :
      <AutocompleteFieldWithTheme {...props} />
    }
  </>;
};

export const RadioField = ({ description, confirmChange, ...props }) => {
  const [field, meta] = useField(props.name);
  let label = props.label;
  const [isAppSubmitting] = useAppSubmitting();

  if (meta.error && meta.touched && props.showErrors === true) {
    label = <Text color="red">{props.label}</Text>;
  }

  const handleChange = (event) => {
    /**
     * For react v17, e.persist() will not be needed as the SyntheticEvent is no longer pooled
     */
    event.persist();
    if (confirmChange) {
      confirmChange({ event, field, meta }).then(() => {
        field.onChange(event);
      }).catch(() => { });
    } else {
      field.onChange(event);
    }
  };

  return <Radio
    {...field}
    {...props}
    disabled={props.disabled || isAppSubmitting}
    onChange={handleChange}
    checked={field.value === props.value}
    label={label}
    description={description}
  />;
};

const TextInput = (props) => {
  const [field, meta, helpers] = useField(props);
  const [isAppSubmitting] = useAppSubmitting();
  const [forceShowErrors] = useHasSubmittedWithErrors();

  let status = {};

  if ((meta.touched || forceShowErrors) && meta.error) {
    status = { type: 'error', message: meta.error };
  }

  const handleChange = (args) => {
    helpers.setTouched();
    field.onChange(args);
  };

  return (
    <Input
      {...field}
      {...props}
      disabled={props.disabled || isAppSubmitting}
      onChange={handleChange}
      status={status}
      value={field.value === undefined ? '' : field.value}
    />
  );
};

const TextInputProtected = ({ isLoading, ...props }) => {
  return <>
    {isLoading ?
      <Bone height="2rem" /> :
      <TextInput {...props} />
    }
  </>;
};


const SelectField = (props) => {
  const [field, meta] = useField(props);
  const [isAppSubmitting] = useAppSubmitting();
  const [forceShowErrors] = useHasSubmittedWithErrors();

  let status;

  if (meta.error && (meta.touched || forceShowErrors)) {
    status = { type: 'error', message: meta.error };
  }

  return <>
    <Select
      status={status} {...field} {...props}
      disabled={props.disabled || isAppSubmitting}
    />
    {
      status &&
      <FormHelper mt={1} status={status.type}>
        {status.message}
      </FormHelper>
    }
  </>
};

const SelectFieldProtected = ({ isLoading, ...props }) => {
  return <>
    {isLoading ?
      <Bone height="2rem" /> :
      <SelectField {...props} />
    }
  </>;
};

export const CheckboxField = ({ description, confirmChange, ...props }) => {
  const [field, meta] = useField(props.name);
  let label = props.label;
  const [isAppSubmitting] = useAppSubmitting();

  if (meta.error && meta.touched && props.showErrors === true) {
    label = <Text color="red">{props.label}</Text>;
  }

  return <Checkbox
    {...field}
    {...props}
    disabled={props.disabled || isAppSubmitting}
    checked={field.value === true}
    label={label}
    description={description}
  />;
};

export const SwitchField = ({ values, ...props }) => {
  const [field, meta] = useField(props.name);
  const [isAppSubmitting] = useAppSubmitting();

  return <Switch
    {...field}
    checked={meta.value === true}
    {...props}
    disabled={props.disabled || isAppSubmitting}
  />;
};

const AutocompleteTextInput = (props) => {
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

  let result = <></>;
  if (isInputsOnlyLayout === true) {
    result = <TextInputProtected {...props} />;
  } else if (isInputsOnlyLayout === false) {
    result = <AutocompleteFieldProtected {...props} />
  }

  return result;
};

export {
  SelectFieldProtected as SelectField,
  TextInputProtected as TextInput,
  AutocompleteFieldProtected as AutocompleteOnly,
  AutocompleteTextInput as Autocomplete,
};
