import { useState } from 'react';
import { Heading3, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import useConnectionMode from '../../util/useConnectionMode';
import useAccounts from '../../util/useAccounts';
import { CreateAccount } from '../createAccount/CreateAccount';
import { useTranslation } from 'react-i18next';
import useIsInputsOnlyLayout from '../../util/hooks/useIsInputsOnlyLayout';
import { SwitchAutoPayoutTips } from '../custom/SwitchLayout';


export const MapTips = ({ formik }) => {
  const [, softwareName] = useConnectionMode();
  const [isLoading, accountsList] = useAccounts('tips');
  const [createAccountData, setCreateAccountData] = useState({
    isOpen: false,
    accountName: '',
  });
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const { isTipsPayout } = formik.values


  const handleCreateAccount = (newAccountName) => {
    setCreateAccountData({
      isOpen: true,
      accountName: newAccountName,
    });
  };

  const closeAccountModal = () => {
    setCreateAccountData({ isOpen: false });
  };

  return <>
    <Box padding="0.75rem">
      {createAccountData.isOpen === true &&
        <CreateAccount
          accountType="Other Current Liability"
          closeModal={closeAccountModal}
          accountName={createAccountData.accountName}
        />
      }
      <Heading3 mb="0.375rem">{t('Tips')}</Heading3>
      <Text color="gray-600" mb="1.5rem" mr="3.25rem" size="small">
        {t('MapTipsDesc', { swName: softwareName })}
      </Text>
      <Flex>
        <Box width="49%" pr="1.1rem">
          <LabelRequired htmlFor="tipsAccount">
            {
              t(isInputsOnlyLayout ? 'Enter tips account' : 'Select a tips account')
            }
          </LabelRequired>
          <Autocomplete
            formik={formik}
            isLoadingOptions={isLoading}
            id="tipsAccount"
            name="tipsAccount"
            options={accountsList}
            placeholder={
              t(isInputsOnlyLayout ? 'Enter tips account' : 'Select an account')
            }
            onCreateOption={handleCreateAccount}
          />
        </Box>
      </Flex>
    </Box>
    <Box mb='0.25rem'>
      <SwitchAutoPayoutTips
        switchFieldName="isTipsPayout"
        isSwitchOn={isTipsPayout}
        isLoading={isLoading}
        heading={t("Auto-payout tips")}
      />
    </Box>
  </>
};
