import { useEffect, useState } from 'react';
import { Box } from '@lightspeed/flame/Core';
import { DateRangePicker } from '@lightspeed/cirrus-datepicker';
import { useTranslation } from 'react-i18next';
import useLocale from '../../util/hooks/useLocale';

const adjustDateRange = (dateRange) => {
  // Move starDate time to start of day
  dateRange.startDate.setHours(0);
  dateRange.startDate.setMinutes(0);
  dateRange.startDate.setSeconds(0);
  // Move endDate time to start of day
  dateRange.endDate.setHours(23);
  dateRange.endDate.setMinutes(59);
  dateRange.endDate.setSeconds(59);

  return dateRange;
};

const getAllTimeRange = () => {
  const startDate = new Date();
  startDate.setFullYear(2019);
  startDate.setMonth(1);
  startDate.setDate(1);
  const endDate = new Date();

  return { startDate, endDate };
};

const getLastYearRange = () => {
  const startDate = new Date();
  startDate.setDate(1);
  startDate.setMonth(0);
  startDate.setFullYear(startDate.getFullYear() - 1);
  const endDate = new Date();
  endDate.setDate(31);
  endDate.setMonth(11);
  endDate.setFullYear(endDate.getFullYear() - 1);

  return { startDate, endDate };
};

const getYesterdayRange = () => {
  const [startDate, endDate] = [new Date(), new Date()].map(dateObj => {
    dateObj.setDate(dateObj.getDate() - 1);
    return dateObj;
  });

  return { startDate, endDate };
};

const getLastWeekRange = () => {
  const startDate = new Date();
  // Last sunday
  startDate.setDate(startDate.getDate() - 7 - startDate.getDay());
  const endDate = new Date();
  // Last saturday
  endDate.setDate(endDate.getDate() - 1 - endDate.getDay());

  return { startDate, endDate };
};

const getLastMonthRange = () => {
  const startDate = new Date();
  startDate.setDate(1);
  startDate.setMonth(startDate.getMonth() - 1);
  const endDate = new Date();
  /**
   * Setting day to 0 makes JS automatically
   * calculate the last day of last month
   */
  endDate.setDate(0);

  return { startDate, endDate };
};

const getThisWeekRange = () => {

  const startDate = new Date();
  startDate.setDate(startDate.getDate() - startDate.getDay());
  const endDate = new Date();
  endDate.setDate(endDate.getDate() - endDate.getDay()+6);

  return { startDate, endDate };;
};

const getThisMonthRange = () => {
  
  const startDate = new Date();
  startDate.setDate(1);
  startDate.setMonth(startDate.getMonth());
  const endDate = new Date();
  endDate.setDate(30);

  return { startDate, endDate };
};

const getThisYearRange = () => {

  const startDate = new Date();
  startDate.setDate(1);
  startDate.setMonth(0);
  startDate.setFullYear(startDate.getFullYear());
  const endDate = new Date();
  endDate.setDate(31);
  endDate.setMonth(12);
  endDate.setFullYear(endDate.getFullYear());

  return { startDate, endDate };
};

const CustomDatePicker = ({ onApplyFilter, loadingStatus }) => {
  const [dates, setDates] = useState(getAllTimeRange());
  const { t } = useTranslation();
  const locale = useLocale();
  const [dateFormat, setDateFormat] = useState('yyyy/MM/dd');

  useEffect(() => {
    if (!locale.startsWith('en')) {
      const dateString = new Intl.DateTimeFormat(locale).format(new Date(3333, 10, 22));
      setDateFormat(dateString.replace('11', 'MM').replace('22', 'dd').replace('3333', 'yyyy'));
    }
  }, [locale]);

  const translations = {
    START_DATE_NOT_DEFINED: 'The start date is not defined',
    END_DATE_NOT_DEFINED: 'The end date is not defined',
    START_DATE_FORMAT_NOT_VALID: 'The start date is not valid',
    END_DATE_FORMAT_NOT_VALID: 'The end date is not valid',
    START_DATE_MUST_BE_BEFORE_END_DATE: 'The start date should be before the end date',
    END_DATE_MUST_BE_AFTER_START_DATE: 'The end date should be after the start date',
    START_DATE_NOT_WITHIN_RANGE: 'The start date is not within the accepted range',
    END_DATE_NOT_WITHIN_RANGE: 'The end date is not within the accepted range',
    APPLY: t('Apply'),
    DAY_TITLE_FREE: (date) => `Choose: ${date}`,
    DAY_TITLE_SELECTED: (date) => `Selected. ${date}`,
  };

  return (
    <DateRangePicker
      disabled={loadingStatus}
      startDate={dates.startDate}
      endDate={dates.endDate}
      translations={translations}
      locale={locale}
      dateFormat={dateFormat}
      presetDates={[
        {
          label: t('Today'),
          ...adjustDateRange({
            startDate: new Date(),
            endDate: new Date()
          })
        },
        {
          label: t('Yesterday'),
          ...getYesterdayRange()
        },
        {}, // this makes a separator
        {
          label: t('Last week'),
          ...getLastWeekRange()
        },
        {
          label: t('Last month'),
          ...getLastMonthRange()
        },
        {
          label: t('Last year'),
          ...getLastYearRange()
        },  
        {
          label: t('This week'),
          ...getThisWeekRange()
        },
        {
          label: t('This month'),
          ...getThisMonthRange()
        },  
        {
          label: t('This year'),
          ...getThisYearRange()
        },
        {}, // this makes a separator
        {
          label: t('All time'),
          ...getAllTimeRange()
        },
      ]}
      onSubmit={dates => {
        const datesAdjusted = adjustDateRange(dates);
        setDates(dates);
        onApplyFilter(datesAdjusted);
      }}
    />
  );
};

const DatePickerWrapper = (props) => {
  return <Box mr="1rem">
    <CustomDatePicker {...props} />
  </Box>
};

export {
  DatePickerWrapper as DatePicker,
};