import { Suspense } from 'react';
import { FlameTheme, FlameGlobalStyles } from '@lightspeed/flame/Core';
import { ToasterProvider } from '@lightspeed/flame/Toaster';
import './App.css';
import { Main } from './components/main/Main';

function App() {
  return (
    <FlameTheme>
      {/* Wrapping `<div>` is necessary since `<FlameTheme>` is a Provider */}
      <div style={{ height: '100%' }}>
        {/* We set some global styles, like fonts and minimal resets */}
        <FlameGlobalStyles />
        <ToasterProvider>
          <Main />
        </ToasterProvider>
      </div>
    </FlameTheme>
  );
}

export default function AppSuspense() {
  return (
    <Suspense fallback="loading">
      <App />
    </Suspense>
  );
};
