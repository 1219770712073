import { Button } from '@lightspeed/flame/Button';
import { IconSync } from '@lightspeed/flame/Icon/Sync';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllAccounts } from '../../state/accountsReducer';
import useLocation from '../../util/useLocation';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../../util/hooks/useMessage';

export const RefreshAccountsSkeleton = ({ handleClick }) => {
  const { t } = useTranslation();

  return <Button mt="1.125rem" padding="9px" pr="18px" onClick={handleClick}>
    <IconSync />
    {t('Refresh accounts')}
  </Button>;
};

export const RefreshAccounts = () => {
  const { status } = useSelector(state => state.accounts);
  const dispatch = useDispatch();
  const currentLocation = useLocation();
  const { addMessage } = useMessage();
  const { t } = useTranslation();

  const handleClick = () => {
    if (status !== 'pending') {
      dispatch(fetchAllAccounts(currentLocation.id)).then(res => {
        if (fetchAllAccounts.rejected.match(res)) {
          addMessage(res.payload && res.payload.response, { default: t('Fetch failure') });
        }
      });
    }
  };

  return <RefreshAccountsSkeleton handleClick={handleClick} />;
};
