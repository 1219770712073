import { useState, useEffect } from 'react';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { PageLayout } from '../pageLayout/PageLayout';
import useLocation from '../../util/useLocation';
import { getSalesSyncData } from '../../api/dashboard';
import { SyncDetails } from '../syncDetails/SyncDetails';
import useConnectionMode from '../../util/useConnectionMode';
import { HistoricalSync } from './HistoricalSync';
import { useTranslation } from 'react-i18next';
import useRole from '../../util/hooks/useRole';
import { useMessage } from '../../util/hooks/useMessage';
import queryString from 'query-string';
import { SyncTable } from './SyncTable';

export const SyncLogs = (props) => {
  let params = queryString.parse(props.location.search)
  const isTypePo = params?.isTypePo === 'true'
  const currentLocation = useLocation();
  const [rowData, setRowData] = useState();
  const [, swName] = useConnectionMode();
  const [syncDetailsInfo, setSyncDetailsInfo] = useState({
    show: false,
  });
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const { t } = useTranslation();
  const { addMessage } = useMessage();
  const [data, setData] = useState();
  const [isFilterApplied, setFilterApplied] = useState(false)
  const [isUpdating, setUpdating] = useState(false);
  const [user] = useRole();
  const [isLastPage, setLastPage] = useState(true);
  const [pbt, setPbt] = useState(null);
  const [pot, setPot] = useState(null);
  const [appliedPostStatus, setAppliedPostStatus] = useState()

  const openSyncDetailsModal = (data) => {
    setSyncDetailsInfo({
      show: true,
      data,
    });
  };

  const closeSyncDetailsModal = (operation) => {
    if (operation) {
      if (['repost', 'resync', "post"].includes(operation.type)) {
        const { id } = operation;
        let filtered = rowData.map(data => {
          let newData = data;
          if (data.id === id) {
            newData = Object.assign({}, data, {
              success: 2,
            });
          }
          return newData;
        })

        console.log(filtered, operation);

        setRowData(filtered);
        setData(filtered)
      }
    }
    setSyncDetailsInfo({ show: false });
  };

  const handleFetchError = (res) => {
    setLoadingStatus('error');
    console.error(res)
    setRowData([]);
    addMessage(res, { default: t('Fetch failure') });
  };

  useEffect(() => {
    let mounted = true;
    if (loadingStatus === 'loading') {
      setRowData(null);
      setData(null);
      getSalesSyncData(currentLocation.id, null, null, null, null, null, isTypePo).then(res => {
        if (mounted) {
          const resData = res.data

          if (resData) {
            let rowsData = resData.data || [];

            if (resData?.pageValues) {
              let obj = resData?.pageValues;
              let pbt = obj?.pageBackupTable;
              let pot = obj?.pageOriginalTable;

              setLastPage(rowsData?.length < 20)

              setPbt(pbt)
              setPot(pot)
            }

            setData([...rowsData])
            setRowData([...rowsData])
            setLoadingStatus('loaded');
          } else {
            handleFetchError(res);
          }
        }
      }).catch(err => {
        if (mounted) {
          handleFetchError(err);
        }
      });
    }

    return () => { mounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingStatus]);

  const refreshData = () => {
    setFilterApplied(false);
    setLastPage(true);

    setAppliedPostStatus()

    setPbt(null)
    setPot(null)
    if (['error', 'loaded'].includes(loadingStatus)) {
      setLoadingStatus('loading');
    }
  };

  const fetchMoreData = () => {
    getSalesSyncData(currentLocation.id, pbt, pot, null, null, null, isTypePo).then(res => {
      const resData = res.data;
      if (resData) {
        let rowsData = resData.data || [];

        if (resData?.pageValues) {
          let obj = resData?.pageValues;
          let pbt = obj?.pageBackupTable;
          let pot = obj?.pageOriginalTable;

          setLastPage(rowsData?.length < 20)
          setPbt(pbt)
          setPot(pot)
        }

        let d = [...rowData, ...rowsData]

        if (appliedPostStatus) {
          d = statusFilter(appliedPostStatus)

          if (d.length < 20) {
            setLastPage(true)
          }
        }

        setRowData(d)
        setData(d)
      } else {
        handleFetchError(res);
      }
    }).catch(err => {
      handleFetchError(err.response)
    });
  }

  const searchData = (s) => {
    if (!s || s === "") {
      setFilterApplied(false);
      return
    }
    setFilterApplied(true);
    setUpdating(true);
    getSalesSyncData(currentLocation.id, null, null, s, null, null, isTypePo).then(res => {
      const resData = res.data;
      let rowsData = resData.data || [];

      if (resData) {
        setRowData([...rowsData])
        setData([...rowsData])
        setPbt(null);
        setPot(null);
      } else {
        handleFetchError(res);
      }
      setLastPage(false)
      setUpdating(false);
    }).catch(err => {
      setUpdating(false);
      handleFetchError(err.response)
    })
  }

  const filterDataByDateRange = (dateRange) => {
    let result = data;

    if (data && dateRange) {
      result = data.filter(rowData => {
        // Append time for correct date comparison and to avoid date shift
        const rowDate = new Date(`${rowData.actionDate}T06:00`);
        return ((rowDate >= dateRange.startDate) && (rowDate <= dateRange.endDate));
      });
    }

    if (result.length < 20) {
      setLastPage(true)
    } else {
      setLastPage(false)
    }

    setRowData([...result])
  };

  const statusFilter = (status) => {
    let result = data;

    if (data && status && status !== "-1") {
      result = data.filter(rowData => {
        return (rowData.success === parseInt(status));
      });
    }

    return result
  };

  const filterDataByPostStatus = (status) => {
    if (status === "-1") {
        refreshData()
    } else {
      const result = statusFilter(status)

      setAppliedPostStatus(status)

      if (result.length < 20) {
        setLastPage(true)
      } else {
        setLastPage(false)
      }

      setRowData([...result])
    }
  }

  return <>
    {syncDetailsInfo.show &&
      <SyncDetails
        closeModal={closeSyncDetailsModal}
        rowData={syncDetailsInfo.data}
        swName={swName}
        isTypePo={isTypePo}
      />
    }
    <PageLayout
      title={t('Sync history')}
      description={t('SyncHistoryDescription')}
      showBack={true}
      navigate={props.navigate}
    >
      <>
        {false && <HistoricalSync isTypePo={isTypePo} />}
        <Card>
          <CardSection style={{ padding: 0 }}>
            <SyncTable
              refreshData={refreshData}
              loadingStatus={loadingStatus === "loading" || isUpdating}
              rows={rowData}
              getData={fetchMoreData}
              openSyncDetailsModal={openSyncDetailsModal}
              triggerSearch={searchData}
              isEnd={Boolean(!isLastPage && !isFilterApplied)}
              handleDateFilter={filterDataByDateRange}
              handlePostStatus={filterDataByPostStatus}
              selectedPostStatus={appliedPostStatus}
              isTypePo={isTypePo}
            />
          </CardSection>
        </Card>
      </>
    </PageLayout>
  </>;
};