import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MapGeneric } from '../mapGeneric/MapGeneric';
import { getPaymentMethodsList } from '../../api/advancedConfig';
import useLocation from '../../util/useLocation';
import useAccounts from '../../util/useAccounts';
import useConnectionMode from '../../util/useConnectionMode';
import { CreateAccount } from '../createAccount/CreateAccount';
import { getCurrentTarget } from '../../util';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../../util/hooks/useMessage';
import usePosType from '../../util/hooks/usePosType';

const isGiftCard = (rowData) => {
  return rowData.typeId && rowData.typeId.toLowerCase() === 'liability';
};

export const IndividualPaymentMethods = (props) => {
  const { isChangedPostingMethod } = useSelector(state => state.forms.meta);
  const payments = useSelector(state => state?.globals?.config?.payments)
  const fetchedMapping = isChangedPostingMethod ? null : payments;
  const location = useLocation();
  const [, liabilityAccounts] = useAccounts('giftCard');
  const [, assetAccounts] = useAccounts('payment');
  const [, adjustmentAccounts] = useAccounts('adjustment');
  const [, itemAccounts]=useAccounts('items');
  const { splitCreditPayments, defaultPaymentAccount: defaultAccountValue } = useSelector(state =>
    state.forms.entities.dashboard?.values
  ) || {};
  const selectAsdraftAccount  = useSelector(state =>
    state?.forms?.entities?.dashboard?.values?.createReconAs
  )|| {};
  const placeholderAccount = defaultAccountValue && assetAccounts.find(account => (account.value === defaultAccountValue));
  const placeholder = placeholderAccount && placeholderAccount.label;
  const draftPlaceholderAccount = defaultAccountValue && itemAccounts.find(account => (account.value === defaultAccountValue));
  const draftplaceholder = draftPlaceholderAccount && draftPlaceholderAccount.label;
  const [, softwareName] = useConnectionMode();
  const [createAccountData, setCreateAccountData] = useState({
    isOpen: false,
    accountName: '',
    accountType: '',
  });
  const target = getCurrentTarget();
  const { t } = useTranslation();
  const { addMessage } = useMessage();
  const posType = usePosType();

  const handleCreateAccount = (newAccountName, rowData) => {
    const isCard = isGiftCard(rowData);

    setCreateAccountData({
      isOpen: true,
      accountName: newAccountName,
      accountType: isCard ? 'Other Current Liability' : rowData.typeId,
    });
  };

  const closeAccountModal = () => {
    setCreateAccountData({ isOpen: false });
  };

  const getAccountList = (rowData) => {
    let result = assetAccounts;

    if (rowData) {
      if (isGiftCard(rowData)) {
        result = liabilityAccounts;
      } else if (posType.isRetail && rowData.id.toLowerCase() === 'adjustment') {
        result = adjustmentAccounts;
      }
    }

    return result;
  };
  const getAccountListAsDraft = (rowData) => {
    let result = itemAccounts;

    if (rowData) {
      if (isGiftCard(rowData)) {
        result = liabilityAccounts;
      } else if (posType.isRetail && rowData.id.toLowerCase() === 'adjustment') {
        result = adjustmentAccounts;
      }
    }

    return result;
  };

  const config = [
    {
      title: t('LsPayments', { target: target.fullName }),
      name: 'payment',
    },
    {
      title: t('SwAccounts', { swName: softwareName }),
      name: 'account',
      optionsGetter:selectAsdraftAccount && selectAsdraftAccount==='approved'?getAccountList:getAccountListAsDraft,
      type: 'dropdown',
      placeholder: selectAsdraftAccount && selectAsdraftAccount==='approved'?placeholder:draftplaceholder,
      onCreateOption: handleCreateAccount,
    }
  ];

  const handleError = (res) => {
    addMessage(res, { default: t('Fetch failure') });
  };

  const fetchRows = () => {
    const params = {
      isIntegratedPayment: splitCreditPayments === '1',
    };

    return getPaymentMethodsList(params, location.id).then(res => {
      let rowsList;
      if (res.data) {
        const resData = res.data;
        rowsList = resData.map(rowInfoRaw => {
          const formValues = {
            [config[0].name]: rowInfoRaw.name,
            [config[1].name]: (fetchedMapping && fetchedMapping[rowInfoRaw.id]) || '',
            id: rowInfoRaw.id,
            typeId: rowInfoRaw.typeId,
          };

          return formValues;
        });

        return rowsList;
      } else {
        handleError(res);
      }
    }).catch(err => {
      handleError(err.response);
    });
  };

  return <>
    {createAccountData.isOpen === true &&
      <CreateAccount
        accountType={createAccountData.accountType}
        closeModal={closeAccountModal}
        accountName={createAccountData.accountName}
      />
    }
    <MapGeneric
      pageTitle={t('Map payment types')}
      pageDescription={t('MapPaymentPageDesc', { swName: softwareName })}
      title={t('Payment types')}
      description={t('IndividualPaymentsDesc', { swName: softwareName })}
      config={config}
      fetchRows={fetchRows}
      name="paymentsMapping"
      persistFormName="paymentsMapping"
      {...props}
    />
  </>;
};
