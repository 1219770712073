import { Flex, Box } from '@lightspeed/flame/Core';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import { useToasts } from '@lightspeed/flame/Toaster';
import { Button } from '@lightspeed/flame/Button';
import { Label } from '@lightspeed/flame/FormField';
import { Input } from '@lightspeed/flame/Input';
import { Card, CardHeader, CardSection, CardFooter } from '@lightspeed/flame/Card';
import { useDispatch } from 'react-redux';
import { moveToNextScreen } from '../../util/navigation';
import { TextLink } from '@lightspeed/flame/Text';
import { getCurrentTarget } from '../../util';
import useConnectionMode from '../../util/useConnectionMode';
import { Freshchat } from 'reactjs-freshchat';
import 'reactjs-freshchat/dist/index.css';

const doLogin = (props, addToast, setSubmitting, dispatch, payload, setConnectionMode) => {
  const target = getCurrentTarget();
  /**
   * Do not use variable for module path
   * Source: https://github.com/webpack/webpack/issues/6680#issuecomment-370800037
   */
  import(`../../api/revel/login`).then(({ sendLoginData }) => {
    sendLoginData(payload).then(res => {

      const resData = res?.data

      setConnectionMode(resData?.connectionMode)

      moveToNextScreen(props.navigate, dispatch, '../dashboard', resData);
    }).catch(err => {

      console.log(err)

      if (err.response) {
        const data = err.response.data;
        let message = (data && data.errorMessage) || 'Error logging in';

        if (message?.key) {
          message = message?.key
        }

        addToast(message, { appearance: 'error' });
      } else {
        addToast('Failed to connect to server', { appearance: 'error' });
      }

      setSubmitting(false);
    });
  }).catch(() => {
    addToast('Build error', { appearance: 'error' });
  });
};

const TextInput = (props) => {
  const [field, meta] = useField(props);

  let status = {};

  if (meta.touched && meta.error) {
    status = { type: 'error', message: meta.error };
  }

  return (
    <Input
      status={status} {...field} {...props}
      value={field.value === undefined ? '' : field.value}
    />
  );
};

const createValidationSchema = () => {
  const schemaObject = {
    email: Yup.string()
      .email('Invalid email address')
      .required('Email  is required'),
    password: Yup.string()
      .required('Password is required'),
  };

  return Yup.object().shape(schemaObject);
};

const LoginForm = ({ formik, ...props }) => {

  return <Form>
    <Box mb={5}>
      <Label htmlFor="email">Email</Label>
      <TextInput id="email" name="email" placeholder="Enter email" />
    </Box>
    <Box mb={5}>
      <Label htmlFor="password">Password</Label>
      <TextInput id="password" name="password" placeholder="Enter password" type="password" />
    </Box>
    <Button variant="primary" type="submit" fill={true}
      disabled={!formik.isValid}
      loading={formik.isSubmitting}
      width="100%"
      mb={2}
    >
      Login
    </Button>
  </Form>;
};

export const Login = (props) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [, , setConnectionMode] = useConnectionMode()

  const onSubmit = (values, { setSubmitting }) => {
    // Send data to backend
    const payload = {
      email: values.email,
      password: values.password
    };

    doLogin(props, addToast, setSubmitting, dispatch, payload, setConnectionMode);
  };

  const moveToSignup = () => {
    props.navigate('../signup');
  };

  return (
    <Flex justifyContent="center">
      <Box width={450} marginTop={50}>
        <Card>
          <CardHeader title="Login" />
          <CardSection>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={createValidationSchema()}
              onSubmit={onSubmit}
            >
              {formik =>
                <LoginForm formik={formik} {...props} />
              }
            </Formik>
          </CardSection>
          <CardFooter>
            <Flex justifyContent="center">
              New User? <TextLink onClick={() => { moveToSignup() }} ml={1}>Signup</TextLink>
            </Flex>
          </CardFooter>
        </Card>
      </Box>
      <Freshchat
        token={'00b6ddea-bee5-4a57-b767-e51cb812490d'}
      />
    </Flex>
  );
};
