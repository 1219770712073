import { Heading3, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { SwitchField } from '../fields';

export const SwitchLayout = ({ children, heading, isSwitchOn, description, switchFieldName, isLoading }) => {
  return <Box padding="0.75rem">
    <Flex justifyContent="space-between">
      <Heading3 mb="0.375rem">
        {heading}
      </Heading3>
      <SwitchField id={switchFieldName} name={switchFieldName} disabled={isLoading} />
    </Flex>
    <Text color="gray-600" mr="3.25rem"
      mb={isSwitchOn ? '1.5rem' : 0}
      size="small"
    >
      {description}
    </Text>
    {(isSwitchOn === true) && <>{children}</>}
  </Box>;
};

export const SwitchAutoPayoutTips = ({ children, heading, isSwitchOn, description, switchFieldName, isLoading }) => {
  return <Box padding="0.75rem">
    <Flex justifyContent="space-between">
      <Heading3 mb="0.375rem">
        {heading}
      </Heading3>
      <SwitchField id={switchFieldName} name={switchFieldName} disabled={isLoading} />
    </Flex>
    <Text color="gray-600" mr="3.25rem"
      size="small"
    >
      {description}
    </Text>
    {(isSwitchOn === true) && <>{children}</>}
  </Box>;
};
