import { useEffect, useState } from 'react';
import { Spinner } from '@lightspeed/flame/Spinner'
import { Heading1, Text, TextLink } from '@lightspeed/flame/Text';
import { parse as parseQuery } from 'query-string';
import {
  authorizeIdRetail as authorizeIdRequest,
} from './authorize';
import { moveToNextScreen } from '../util/navigation';
import { useDispatch } from 'react-redux';
import { useToasts } from '@lightspeed/flame/Toaster';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../util/hooks/useMessage';
import i18n from '../i18n';
import { AlertInCard } from '@lightspeed/flame/Alert';
import { Button } from '@lightspeed/flame/Button';
import { Box } from '@lightspeed/flame/Core';
import { setFloorDetails, setFloorinfo } from '../state/portalInfoReducer';
import useConnectionMode from '../util/useConnectionMode';

const LoadingScreen = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading1>{t('Authorizing')}</Heading1>
      <Spinner size="40px" />
    </>
  );
}

const Retry = (props) => {
  const { t } = useTranslation();

  const retry = (clickEvent) => {
    clickEvent.preventDefault();
    props.retry();
  }
  return (
    <>
      <Heading1>{t('Error')}</Heading1>
      <Text>
        {t('AuthorizeError.0')}
        <TextLink onClick={retry}>
          {' ' + t('AuthorizeError.1') + ' '}
        </TextLink>
        {t('AuthorizeError.2')}
      </Text>
    </>
  );
};

export const CompleteOAuth = ({ fromOauth, ...props }) => {
  const states = {
    loading: 1,
    error: 2,
  };
  const [state, setState] = useState(states.loading);
  const [credentialsmsg, setCredentialsmsg] = useState();
  const [redirecturl, setRedirecturl] = useState();
  const params = parseQuery(props.location.search);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { addMessage } = useMessage();
  const { t } = useTranslation();
  const [, , setConnectionMode] = useConnectionMode();

  const authorizeId = () => {
    setState(states.loading);

    authorizeIdRequest(params).then(res => {
      const resData = res.data;
      const nextPath = `${props.path === '/' ? '.' : '..'}/dashboard`;

      const multipleFloor = resData && resData.multipleFloor
      const floorDetail = resData && resData.floorDetail

      setConnectionMode(resData?.connectionMode);
      dispatch(setFloorinfo(multipleFloor));
      dispatch(setFloorDetails(floorDetail));
      moveToNextScreen(props.navigate, dispatch, nextPath, resData);
    }).catch(err => {
      if (err.response.status === 406) {
        const data = err.response.data;
        const message = (data && data?.errorMessage?.key);
        if (message === 'Invalid Credentials' || message === 'errorAccessToken') {
          const url = (data && data.responseUrl.url)
          setRedirecturl(url)
        }
        message === 'Invalid Credentials' && addToast(t(message), { appearance: 'error' });
        setCredentialsmsg(message)
        setState(states.error);
      } else {
        addMessage(err.response, { default: t('Fetch failure') });
        setState(states.error);
      }
    });
  };

  const CredentialErrorMsg = ({credentialsmsg}) => {
    const { t } = useTranslation();
    return (
      <Box width="40%" padding="0.75rem" >
        <AlertInCard
          type="danger"
          noCloseBtn
        >
        {
          credentialsmsg === "errorAccessToken" ?
          <Text mt='2.1rem' textAlign='justify'>{t("errorAccessToken")}</Text>
          :
          <Text mt='2.1rem' textAlign='justify'>{t('The account used for login and authorize access to the accounting does not have access to this location. Kindly login with the location specific account to provide the required access')}</Text>
        }
          <Button fill="true" variant='danger' style={{ float: 'right' }} href={redirecturl} target="_parent">{t('Dismiss')}</Button>
        </AlertInCard>
      </Box>
    )
  }
  
  const startProcessing = () => {
    authorizeId();
  };

  // Start auth automatically on load
  useEffect(() => {
    startProcessing(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set language code
  useEffect(() => {
    if (params && params.language) {
      i18n.changeLanguage(params.language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{
      display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%',
      flexDirection: 'column'
    }}>
      {state === states.loading && <LoadingScreen />}
      {(credentialsmsg === 'Invalid Credentials' || credentialsmsg === 'errorAccessToken') ?
        <>
          {state === states.error && <CredentialErrorMsg credentialsmsg={credentialsmsg} />}
        </>
        :
        <>
          {state === states.error && <Retry retry={startProcessing} />}
        </>
      }
    </div>
  );
};