import { useState } from 'react';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Button } from '@lightspeed/flame/Button';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Divider } from '@lightspeed/flame/Divider';
import { useSelector } from 'react-redux';
import { softwareImages } from '../../util/softwareImages';
import { Heading2, Text, TextLink } from '@lightspeed/flame/Text';
import { PageLayout } from '../pageLayout/PageLayout';
import { AuthorizeSoftware } from '../authorizeSoftware/AuthorizeSoftware';
import { AnnotatedLayout } from '@lightspeed/flame/Layout';
import { getConnectionModeName } from '../../util';
import { useTranslation } from 'react-i18next';
import { LearnMore } from '../custom/LearnMore';
import { useLocationData } from '../../util/useLocation';

const SoftwareRow = ({ swData, onConnect }) => {
  const { t } = useTranslation();

  const handleSoftwareSelection = (swData) => {
    onConnect(swData);
  };

  return <>
    <CardSection>
      <Flex minHeight="100px">
        <Flex width="108px" minWidth="108px" justifyContent="center" alignItems="center">
          <img src={softwareImages[swData.id]} alt={swData.accountingSoftware}
            style={{ width: '60px' }}
          />
        </Flex>
        <Flex flexDirection="column" justifyContent="center">
          <Heading2>{getConnectionModeName(t, swData.id)}</Heading2>
          {swData.id === 'QB Desktop Edition' ?
            <Text>
              {t('DesktopSwDesc')} {<TextLink><LearnMore isInline={true} /></TextLink>}
            </Text>
            :
            <Text>
              {t('OnlineSwDesc')} {<TextLink><LearnMore isInline={true} /></TextLink>}
            </Text>
          }
        </Flex>
        <Flex justifyContent="center" alignItems="center" width="200px" minWidth="200px">
          <Button
            variant="secondary"
            fill={true}
            onClick={() => handleSoftwareSelection(swData)}
          >{t('Connect')}</Button>
        </Flex>
      </Flex>
    </CardSection>
    <Divider />
  </>;
};


const SelectSoftwareContent = (props) => {
  const [selectedSw, setSelectedSw] = useState();
  const swList = useSelector(state => state.softwareList);

  const setupSoftware = (swData) => {
    setSelectedSw(swData);
  };

  const resetSw = () => {
    setSelectedSw(null);
  };

  return (
    <>
      {
        selectedSw &&
        <AuthorizeSoftware
          swData={selectedSw}
          closeModal={resetSw}
          {...props}
        />
      }
      <Box>
        <Card>
          {
            swList && swList.map((swData, index) => {
              return <SoftwareRow key={index} swData={swData} index={index} onConnect={setupSoftware} />;
            })
          }
        </Card>
      </Box>
    </>
  );
};

export const SelectSoftware = (props) => {
  const { t } = useTranslation();
  const { isMultiLocation } = useLocationData();

  return <PageLayout
    showBack={isMultiLocation}
    path={props.path}
    navigate={props.navigate}
  >
    <AnnotatedLayout
      title={t('Accounting Software')}
      description={t('SelectSw')}
      renderExtras={
        <LearnMore />
      }
    >
      <SelectSoftwareContent {...props} />
    </AnnotatedLayout>
  </PageLayout>;
};
