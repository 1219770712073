import { useState } from 'react';
import { TextLink, Heading3, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Bone } from '@lightspeed/flame/Bone';
import { Autocomplete } from '../fields';
import { useSelector } from 'react-redux';
import useAccounts from '../../util/useAccounts';
import { useToasts } from '@lightspeed/flame/Toaster';
import useConnectionMode from '../../util/useConnectionMode';
import useAppSubmitting from '../../util/useAppSubmitting';
import { LabelRequired } from '../custom/LabelRequired';
import { CreateAccount } from '../createAccount/CreateAccount';
import { usePosType } from '../../util/hooks/usePosType';
import { useTranslation } from 'react-i18next';
import { getKeyByPosType } from '../../util/translation';
import useIsInputsOnlyLayout from '../../util/hooks/useIsInputsOnlyLayout';

export const MapSalesByProducts = ({ fetchedConfig, formik, ...props }) => {
  const { addToast } = useToasts();
  const [isAppSubmitting] = useAppSubmitting();
  const { postingMethod, defaultProductsAccount: defaultAccount } = useSelector(state =>
    state.forms.entities.dashboard?.values
  ) || {};
  const isTypeCategory = postingMethod !== 'invoice';
  const [isLoadingAccounts, accountsList] = useAccounts('items');
  const [, softwareName] = useConnectionMode();
  const [createAccountData, setCreateAccountData] = useState({
    isOpen: false,
    accountName: '',
  });
  const posType = usePosType();
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const isLoading = isInputsOnlyLayout ? false : isLoadingAccounts;

  const handleCreateAccount = (newAccountName) => {
    setCreateAccountData({
      isOpen: true,
      accountName: newAccountName,
    });
  };

  const closeAccountModal = () => {
    setCreateAccountData({ isOpen: false });
  };

  const gotoTaxMapping = () => {
    const state = {
      fetchedMapping: {
        itemsMapping: fetchedConfig && fetchedConfig.items,
        categoriesMapping: fetchedConfig && fetchedConfig.categories,
      },
    };

    if (posType.isRetail) {
      state.isParentCategory = fetchedConfig && fetchedConfig.isParentCategory;
      state.recordCategoryForInvoiceByProduct = fetchedConfig && fetchedConfig.recordCategoryForInvoiceByProduct;
    }

    props.navigate('../product-mapping', { state });
  };

  const handleNavigation = () => {
    if (isAppSubmitting) {
      addToast(t('Please wait for data to save'), { appearance: 'error' });
    } else if (defaultAccount) {
      gotoTaxMapping();
    } else {
      addToast(t(isInputsOnlyLayout ? 'DefaultAccountTextInputFirst' : 'DefaultAccountFirst'), { appearance: 'error' });
    }
  }

  return <Box padding="0.75rem">
    {createAccountData.isOpen === true &&
      <CreateAccount
        accountType="Income"
        closeModal={closeAccountModal}
        accountName={createAccountData.accountName}
      />
    }
    <Heading3 mb="0.375rem">
      {t(getKeyByPosType(
        isTypeCategory ? 'Sales by categories' : 'Sales by products',
        posType))
      }
    </Heading3>
    <Text color="gray-600" mb="1.5rem" size="small">
      {t(getKeyByPosType(
        isTypeCategory ? 'SalesByCategoryDescription' : 'SalesByProductDescription',
        posType), {
        swName: softwareName,
      })}
    </Text>
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="defaultProductsAccount">
          {t('Default sales account')}
        </LabelRequired>
        <Autocomplete
          id="defaultProductsAccount"
          name="defaultProductsAccount"
          isLoading={isLoading}
          formik={formik}
          options={accountsList}
          placeholder={
            t(isInputsOnlyLayout ? 'Enter default sales account' : 'Select an account')
          }
          onCreateOption={handleCreateAccount}
        />
      </Box>
      <Flex mt="1.4rem" alignItems="center" height="2.25rem">
        {isLoading ?
          <Bone height="1rem" width="12rem" /> :
          <TextLink size="small" fontWeight="bold" onClick={handleNavigation}>
            {t(getKeyByPosType(
              isTypeCategory ? 'MapIndividualCategory' : 'MapIndividualProduct',
              posType))
            }
          </TextLink>
        }
      </Flex>
    </Flex>
  </Box>;
};
