import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getConnectionModeName } from './index';
import { setConnectionMode } from '../state/portalInfoReducer';
import { useIsInputsOnlyLayout } from './hooks/useIsInputsOnlyLayout';
import usePosType from './hooks/usePosType';

export const useConnectionMode = () => {
  const connectionMode = useSelector(state => state.portalInfo.connectionMode);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, setIsInputsOnlyLayout] = useIsInputsOnlyLayout();
  const posType = usePosType();

  const setValue = (newValue) => {
    dispatch(setConnectionMode(newValue));
    setIsInputsOnlyLayout((posType.isKSeries && newValue === 'Datev') || newValue === 'Acomba');
  };

  return [connectionMode, getConnectionModeName(t, connectionMode), setValue];
};

export default useConnectionMode;
