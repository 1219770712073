import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSubmitting: false,
  isConnected: null,
  isAppSyncing: false,
  hasSubmittedWithErrors: false,
  companyName: '',
  isJeAsCategory: false,
  isInputsOnlyLayout: null,
  isTaxAgency: false,
  config: {},
  locations: [],
  classes: [],
  errorFields: {},
  hasAnyErrorMsg: "",
  isReload: false,
  taxAccounts: [],
  taxLabels: [],
  supplierList: [],
  refreshSyncCount: true
};

const globalsSlice = createSlice({
  name: 'globals',
  initialState,
  reducers: {
    setRefetchSyncCount: (state, action) => {
      state.refreshSyncCount = action.payload
    },
    setSupplierList: (state, action) => {
      state.supplierList = action.payload;
    },
    setTaxLabels: (state, action) => {
      state.taxLabels = action.payload;
    },
    setTaxAccounts: (state, action) => {
      state.taxAccounts = action.payload;
    },
    setReload: (state, action) => {
      state.isReload = action.payload
    },
    setIfAnyErrorMsg: (state, action) => {
      state.hasAnyErrorMsg = action.payload
    },
    setErrorFields: (state, action) => {
      state.errorFields = action.payload;
    },
    setIsTaxAgency: (state, action) => {
      state.isTaxAgency = action.payload;
    },
    setConfigurations: (state, action) => {
      state.config = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    setClasses: (state, action) => {
      state.classes = action.payload;
    },
    setAppSubmitting: (state, action) => {
      const value = Boolean(action.payload.value);
      // Set flag only after API call completes, if not already set
      if (state.isSubmitting && !value) {
        state.hasSubmittedWithErrors = action.payload.showErrors === true;
      }  
      state.isSubmitting = value;
    },
    setAppConnected: (state, action) => {
      state.isConnected = Boolean(action.payload);
    },
    setCompanyName: (state, action) => {
      state.companyName = `${action.payload}`;
    },
    setAppSyncing: (state, action) => {
      state.isAppSyncing = Boolean(action.payload);
    },
    setJeAsCategory: (state, action) => {
      state.isJeAsCategory = Boolean(action.payload);
    },
    setIsInputsOnlyLayout: (state, action) => {
      state.isInputsOnlyLayout = Boolean(action.payload);
    },
  }
});

export const {
  setAppSubmitting,
  setAppConnected,
  setAppSyncing,
  setAppRefreshing,
  setCompanyName,
  setJeAsCategory,
  setIsInputsOnlyLayout,
  setConfigurations,
  setIsTaxAgency,
  setLocations,
  setErrorFields,
  setIfAnyErrorMsg,
  setClasses,
  setReload,
  setTaxAccounts,
  setTaxLabels,
  setSupplierList,
  setRefetchSyncCount
} = globalsSlice.actions;

export default globalsSlice.reducer;