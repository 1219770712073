import { AnnotatedLayout } from '@lightspeed/flame/Layout';
import { TextLink } from '@lightspeed/flame/Text';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { useTranslation } from 'react-i18next';
import { Divider } from '@lightspeed/flame/Divider';
import { RefreshAccounts } from '../refreshAccounts/RefreshAccounts';
import { Box } from '@lightspeed/flame/Core';
import { SwitchLayout } from '../custom/SwitchLayout';
import { useEffect } from 'react';
import useConnectionMode from '../../util/useConnectionMode';
import { MapPurchaseTax } from './MapPurchaseTax';
import { MappingOptions } from './MappingOptions';
import { ShippingSection } from './ShippingSection';
import { MapDefaultIndividualCogsInventory } from './MapDefaultIndividualCogsInventory';
import useIsInputsOnlyLayout from '../../util/hooks/useIsInputsOnlyLayout';

export const InventoryCogsSwitch = ({ formik, ...props }) => {
  const {
    isMapPoInventoryCogs,
    postingMethod,
    isMapPurchaseInvoice,
    isTrackInventory,
    isMapCogs,
  } = formik.values;
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();
  const isMethodAuto = postingMethod === 'auto';

  // Auto disable track inventory
  useEffect(() => {
    if (isMapCogs && isTrackInventory) {
      formik.setFieldValue('isTrackInventory', false);
    }
  }, [formik, isMapCogs, isTrackInventory]);

  const description = `${t('PO, inventory and COGS desc.0', { swName })} ${(isMapPoInventoryCogs && !isMethodAuto) ?
    t('PO, inventory and COGS desc.1') : ''}`;

  return <SwitchLayout
    heading={t('PO, Inventory and COGS')}
    description={
      description
    }
    switchFieldName="isMapPoInventoryCogs"
    isSwitchOn={isMapPoInventoryCogs}
  >
    <Box>
      {isMethodAuto ?
        <MapPurchaseTax {...props} />
        :
        <MappingOptions
          isMapPurchaseInvoice={isMapPurchaseInvoice}
          isTrackInventory={isTrackInventory}
          isMapCogs={isMapCogs}
        />
      }
    </Box>
  </SwitchLayout>;
};

const CogsInventorySectionContent = (props) => {
  const {
    postingMethod,
    isMapPurchaseInvoice,
    isMapCogs,
    isMapPoInventoryCogs,
  } = props.formik.values;
  const isMethodAuto = postingMethod === 'auto';

  return <>
    <Card ml="1rem">
      <CardSection>
        <InventoryCogsSwitch {...props} />
      </CardSection>
      {!isMethodAuto && isMapPurchaseInvoice && isMapPoInventoryCogs &&
        <>
          <Divider />
          <CardSection pt="1.5rem" pb="1.5rem">
            <ShippingSection />
            <Box ml="0.75rem" mr="0.75rem" mt="1.5rem">
              <MapPurchaseTax {...props} />
            </Box>
          </CardSection>
        </>
      }
      {!isMethodAuto && (isMapPurchaseInvoice || isMapCogs) && isMapPoInventoryCogs &&
        <>
          <Divider />
          <CardSection pt="1.5rem" pb="1.5rem" pl="1.5rem" pr="1.5rem">
            <MapDefaultIndividualCogsInventory {...props} />
          </CardSection>
        </>
      }
    </Card>
  </>;
};

export const CogsInventorySection = (props) => {
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

  return <AnnotatedLayout
    title={t('Map Purchase Orders, COGS and Inventory')}
    description={'Description'}
    renderExtras={
      <>
        {false && <Box>
          <TextLink size="small" fontWeight="bold">
            {t('Learn more')}
          </TextLink>
        </Box>}
        {(isInputsOnlyLayout === false) && <RefreshAccounts />}
      </>
    }
    mb="2.25rem"
    mt="2.25rem"
  >
    <CogsInventorySectionContent {...props} />
  </AnnotatedLayout>;
};