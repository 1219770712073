import { useSelector } from 'react-redux';

export const usePosType = () => {
  const posType = useSelector(state => state.initialParams.posType);

  const isRetail = process.env.REACT_APP_TARGET === 'retail'

  /**
   * For L-Series, type is 'resto'
   * This way it defaults to L-Series
   */
  return {
    name: isRetail ? 'retail' : posType,
    isLSeries: posType === 'resto',
    isKSeries: posType === 'kseries',
    isRetail,
  };
};

export default usePosType;