import { useToasts } from '@lightspeed/flame/Toaster';
import { useTranslation } from 'react-i18next';

export const useMessage = () => {
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const addMessage = (res, options = {}) => {
    let msg = options.default || t('Fetch failure');
    let type = options.type || 'error';

    if (res && res.data) {
      const resData = res.data;
      const msgObj = resData.message || resData.errorMessage || resData.error;

      if (typeof msgObj === 'string') {
        msg = msgObj;
      } else if ((typeof msgObj === 'object') && msgObj.key) {
        msg = t(msgObj.key);
      }
    }

    addToast(msg, { appearance: type });
  };

  return { addMessage };
};

export default useMessage;