import { useState } from 'react';
import { Heading2, Text, TextLink } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { IconChevronLeft } from '@lightspeed/flame/Icon/ChevronLeft';
import { Button } from '@lightspeed/flame/Button';
import { useSelector } from 'react-redux';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Divider } from '@lightspeed/flame/Divider';
import { sendConfigData } from '../../api/syncConfig';
import { createPayload } from '../../util/dashboard';
import { useLocationData } from '../../util/useLocation';
import { useToasts } from '@lightspeed/flame/Toaster';
import { setFormsClean } from '../../state/formsReducer';
import { useDispatch } from 'react-redux';
import { useAppSubmitting } from '../../util/useAppSubmitting';
import useAppConnected from '../../util/hooks/useAppConnected';
import useAppSyncing from '../../util/hooks/useAppSyncing';
import useConnectionMode from '../../util/useConnectionMode';
import { Alert } from '@lightspeed/flame/Alert';
import { useEffect } from 'react';
import { forgetSavedForms } from '../../state/formsReducer';
import { fetchAllAccounts } from '../../state/accountsReducer';
import { Modal, ModalBody, ModalHeader, ModalFooter } from '@lightspeed/flame/Modal';
import { getCurrentTarget } from '../../util';
import usePosType from '../../util/hooks/usePosType';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../../util/hooks/useMessage';
import useRole from '../../util/hooks/useRole';
import { setErrorFields, setIfAnyErrorMsg } from '../../state/globalsReducer';

const HeadingSection = ({ title, description }) => {
  const target = getCurrentTarget();
  const { t } = useTranslation();

  return <Box pb="35px">
    <Heading2 mb="0.375rem">{title || t('LsAccounting', { target: target.fullName })}</Heading2>
    <Text size="small" color="gray-600">
      {description || t('AppDesc')}
    </Text>
  </Box>;
};

const NavigateBack = ({ isSubmitting, path, currentLocation, isMultiLocation, disableBack, ...props }) => {
  const { addToast } = useToasts();
  const target = getCurrentTarget();
  const { t } = useTranslation();
  const isLocationLink = ['/dashboard', '/selectsw'].includes(path);
  const targetName = (!isLocationLink && isMultiLocation) ? currentLocation.name : target.fullName;

  const gotoDashboard = () => {
    if (isSubmitting) {
      addToast(t('Please wait for data to save'), { appearance: 'error' });
    } else {
      if (isLocationLink) {
        props.navigate('../select-location');
      } else {
        props.navigate('../dashboard');
      }
    }
  };

  return <Box mt="1.125rem" mb="0.8rem">
    <TextLink mb="0.75rem" onClick={gotoDashboard}>
      <IconChevronLeft color="blue-500"
        style={{
          width: '0.75rem',
          height: '0.75rem',
          mr: '0.563rem',
          marginTop: '0.2rem',
          marginBottom: '0.2rem',
          marginRight: '0.375rem',
        }}
      />
      {t('LsAccounting', { target: targetName })}
    </TextLink>
  </Box>
};

const DiscardWarning = ({ closeModal, confirm }) => {
  const { t } = useTranslation();

  return <Modal
    isOpen={true}
    onRequestClose={closeModal}
  >
    <ModalHeader>{t('Warning')}</ModalHeader>
    <ModalBody style={{ width: '37.5rem', minWidth: '37.5rem' }}>
      <Text fontWeight="bold" fontSize="text-s" mb="0.8rem">
        {t('DiscardWarning.0')}
      </Text>
      <Text mb="0.5rem" color="gray-600" fontSize="text-s">
        {t('DiscardWarning.1')}
      </Text>
    </ModalBody>
    <ModalFooter>
      <Flex justifyContent="flex-end">
        <Button onClick={closeModal} mr="0.8rem">{t('Cancel')}</Button>
        <Button fill="true" variant="danger" onClick={confirm}>
          {t('Discard changes')}
        </Button>
      </Flex>
    </ModalFooter>
  </Modal>;
};

const DiscardWarningForDisconnect = ({ closeModal, confirm, disabled, softwareName }) => {
  const { t } = useTranslation();
  return <Modal
    isOpen={true}
    onRequestClose={closeModal}
  >
    <ModalHeader>{t('Warning')}</ModalHeader>
    <ModalBody style={{ width: '37.5rem', minWidth: '37.5rem' }}>
      <Text fontWeight="bold" fontSize="text-s" mb="0.8rem">
        {t('AutomaticDisconnectWarning')}
      </Text>
      <Text mb="0.5rem" color="gray-600" fontSize="text-s">
        {t('All of your current mapped data will be deleted')}
      </Text>
    </ModalBody>
    <ModalFooter>
      <Flex justifyContent="flex-end">
        <Button onClick={closeModal} mr="0.8rem">{t('Cancel')}</Button>
        <Button fill="true" disabled={disabled} variant="danger" onClick={confirm}>
          {t('Disconnect')}
        </Button>
      </Flex>
    </ModalFooter>
  </Modal>;
};

const ConfirmationMessageUserDisconnected = () => {
  const { t } = useTranslation();

  return <Modal
    isOpen={true}
  >
    <ModalBody style={{ width: '37.5rem', minWidth: '37.5rem' }}>
      <Text fontWeight="bold" fontSize="text-s" mb="0.8rem">
        {t('Account has been de-activated. Please refresh the screen to activate it again')}
      </Text>
    </ModalBody>
  </Modal>;
};

const BottomBar = ({ children }) => {
  return <>
    <Divider />
    <Box pt="3rem"></Box>
    <Box
      width="100%"
      style={{
        position: 'fixed', bottom: 0, left: 0, zIndex: 3, borderRadius: 0,
        boxShadow: '0px -2px 4px rgba(12, 13, 13, 0.2)'
      }}
      boxShadow="shadow"
    >
      <Card>
        <CardSection
          pl="1.125rem" pr="1.125rem"
          pt="0.563rem" pb="0.563rem"
        >
          {children}
        </CardSection>
      </Card>
    </Box>
  </>;
};

const SaveBar = ({ isSubmitting, isDisabled, discardData, submitData, disconnectUser, deactivateUserConfirmBox, softwareName }) => {
  const [showWarning, setShowWarning] = useState(false);
  const [showWarningForDisconnect, setShowWarningForDisconnect] = useState(false);
  const [disabledConfirmButton, setDisabledConfirmButton] = useState(false);
  const [userRole] = useRole();
  const { t } = useTranslation();

  const handleCloseWarning = () => {
    setShowWarning(false);
  };

  const handleConfirm = () => {
    discardData();
  };

  const handleClick = () => {
    setShowWarning(true);
  };

  const handleClickForDisconnect = () => {
    setShowWarningForDisconnect(true);
  };

  const handleCloseWarningForDisconnect = () => {
    setShowWarningForDisconnect(false);
  };

  const handleConfirmForDisconnect = () => {
    setDisabledConfirmButton(true);
    disconnectUser();
  };


  return <>
    {showWarning &&
      <DiscardWarning
        closeModal={handleCloseWarning}
        confirm={handleConfirm}
      />
    }
    {(!deactivateUserConfirmBox && showWarningForDisconnect) &&
      <DiscardWarningForDisconnect
        closeModal={handleCloseWarningForDisconnect}
        confirm={handleConfirmForDisconnect}
        disabled={disabledConfirmButton}
        softwareName={softwareName}
      />
    }
    {
      deactivateUserConfirmBox &&
      <ConfirmationMessageUserDisconnected
      />

    }
    <BottomBar>
      <Flex justifyContent="space-between" alignItems="center">
        <Text>{t('Unsaved changes')}</Text>
        <Flex>
          <Button
            disabled={isSubmitting}
            onClick={handleClick}
            mr="0.75rem"
          >
            {t('Discard')}
          </Button>
          {userRole.isSupport && deactivateUserConfirmBox === false &&
            <Button
              //disabled={isSubmitting}
              fill="true"
              variant="danger"
              onClick={handleClickForDisconnect}
              mr="0.75rem"
            >
              {t('Disconnect')}
            </Button>
          }
          <Button
            fill="true"
            variant="secondary"
            loading={isSubmitting}
            onClick={submitData}
            disabled={isDisabled}
          >
            {t('Save changes')}
          </Button>
        </Flex>
      </Flex>
    </BottomBar>
  </>;
};
const DisconnectButtonshow = ({ isSubmitting, discardData, disconnectUser, deactivateUserConfirmBox, softwareName }) => {
  const [showWarning, setShowWarning] = useState(false);
  const [showWarningForDisconnect, setShowWarningForDisconnect] = useState(false);
  const [disabledConfirmButton, setDisabledConfirmButton] = useState(false);
  const [userRole] = useRole();
  const { t } = useTranslation();

  const handleCloseWarning = () => {
    setShowWarning(false);
  };

  const handleConfirm = () => {
    discardData();
  };

  const handleClickForDisconnect = () => {
    setShowWarningForDisconnect(true);
  };

  const handleCloseWarningForDisconnect = () => {
    setShowWarningForDisconnect(false);
  };

  const handleConfirmForDisconnect = () => {
    setDisabledConfirmButton(true);
    disconnectUser();
  };


  return <>
    {showWarning &&
      <DiscardWarning
        closeModal={handleCloseWarning}
        confirm={handleConfirm}
      />
    }
    {(!deactivateUserConfirmBox && showWarningForDisconnect) &&
      <DiscardWarningForDisconnect
        closeModal={handleCloseWarningForDisconnect}
        confirm={handleConfirmForDisconnect}
        disabled={disabledConfirmButton}
        softwareName={softwareName}
      />
    }
    {
      deactivateUserConfirmBox &&
      <ConfirmationMessageUserDisconnected
      />

    }

    <BottomBar>
      <Flex justifyContent="space-between" alignItems="center">
        <Text>{t('')}</Text>
        <Flex>

          {userRole.isSupport && deactivateUserConfirmBox === false &&
            <Button
              disabled={isSubmitting}
              fill="true"
              variant="danger"
              onClick={handleClickForDisconnect}
              mr="0.75rem"
            >
              {t('Disconnect')}
            </Button>
          }

        </Flex>
      </Flex>
    </BottomBar>
  </>;
};


const SwitchMappingBar = ({ value, isDisabled, setMappingType }) => {
  const { t } = useTranslation();

  const switchOptions = {
    category: {
      label: t('Map Products'),
      switchTo: 'product',
    },
    product: {
      label: t('Map Categories'),
      switchTo: 'category',
    },
  };

  const handleMappingChange = () => {
    setMappingType(switchOptions[value].switchTo);
  };

  return <BottomBar>
    <Flex justifyContent="flex-end" alignItems="center">
      <Flex>
        <Button
          fill="true"
          variant="secondary"
          onClick={handleMappingChange}
          disabled={isDisabled}
        >
          {switchOptions[value].label}
        </Button>
      </Flex>
    </Flex>
  </BottomBar>;
};

const ConnectionLost = ({ swName }) => {
  const { t } = useTranslation();

  return <Box pb="35px">
    <Alert
      noCloseBtn={true}
      type="danger"
      title={t('ConnectionLost', { swName })}
    >
      {t('Reconnect to resume syncing')}
    </Alert>
  </Box>;
};

const Accounts = ({ dispatch, currentLocation }) => {
  const { status, entities, error } = useSelector(state => state.accounts);
  const { addMessage } = useMessage();
  const { t } = useTranslation();

  // Refresh accounts if not already
  useEffect(() => {
    // TODO: check
    if (status !== 'pending' && entities.length === 0 && error === null) {
      dispatch(fetchAllAccounts(currentLocation.id)).then(res => {
        if (fetchAllAccounts.rejected.match(res)) {
          addMessage(res.payload && res.payload.response, { default: t('Fetch failure') });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export const PageLayout = ({
  title,
  description,
  children,
  showBack,
  loadAccounts,
  showDisconnected,
  switchMapping,
  disableBack,
  ...props
}) => {
  const [isSubmitting, setIsSubmitting] = useAppSubmitting();
  const [isConnected] = useAppConnected();
  const [connectionMode, connectionModeName] = useConnectionMode();
  const [, setAppSyncing] = useAppSyncing();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { entities: savedForms, meta: savedFormsMeta } = useSelector(state => state.forms);
  const hasChanges = Object.keys(savedForms).some(formName => {
    return formName !== 'meta' && (savedForms[formName]?.dirty || savedForms[formName]?.values?.hasMappingLevelChanged);
  });
  const { currentLocation, isMultiLocation } = useLocationData();
  const posType = usePosType();
  const { t } = useTranslation();
  const { addMessage } = useMessage();
  const JeAsInvoice = useSelector(state => state.globals.isJeAsCategory);
  const defaultConfigs = useSelector(state => state.globals.config)

  const errorFields = useSelector(state => state.globals.errorFields);

  const submitData = () => {
    setIsSubmitting({ value: true });
    const payload = createPayload(posType, savedForms, savedFormsMeta, connectionMode, JeAsInvoice, defaultConfigs);
    sendConfigData(payload, currentLocation.id).then(res => {
      const resData = res.data;
      const hasErrors = Boolean(resData.error);

      // Update form initialValues, if needed
      if (props.handleSuccessfulSave) {
        props.handleSuccessfulSave();
      }

      if (Object.entries(errorFields).length !== 0) {
        try {
          const keys = Object.keys(errorFields);
          let isAllFieldsCompleted = true;
          for (let field = 0; field < Object.entries(errorFields).length; field++) {
            if (errorFields[keys[field]].length !== 0) {
              isAllFieldsCompleted = false;
            }
          }

          isAllFieldsCompleted && dispatch(setIfAnyErrorMsg(""));
        } catch (e) {
          console.error(e)
        }
      }

      // Handle errors and update state
      if (hasErrors) {
        addMessage(res);
        // Show sync error
        setAppSyncing(false);
      } else {
        // Fetch latest sync status
        setAppSyncing(true);

        // reset error if all configured.
        dispatch(setErrorFields({}))
        dispatch(setIfAnyErrorMsg(""))
        
        addToast(t('Config saved'), { appearance: 'success' });
      }
      dispatch(setFormsClean());
      setIsSubmitting({ value: false, showErrors: hasErrors });
    }).catch(err => {
      console.log(err)
      if (err.response) {
        const data = err.response.data;
        let messageObj = data.errorMessage || data.error || t('Communication failure');
        if (data?.error?.key === "invalidJwt") {
          addToast(t(data?.error?.key), { appearance: 'error' });
        } else {
          const message = typeof messageObj === 'object' ? messageObj[0] : messageObj;
          addToast(message, { appearance: 'error' });
        }
      } else {
        addToast(t('Fetch failure'), { appearance: 'error' });
      }

      setIsSubmitting({ value: false })
    });
  };

  const discardData = () => {
    const { navigate } = props;
    if (props.discardData) {
      props.discardData();
    } else {
      dispatch(forgetSavedForms());
      if (navigate) {
        navigate(`${process.env.REACT_APP_BASEPATH}/dashboard`);
      }
    }
  };

  const disconnectUser = () => {
    props.disconnectUser();
  };
  /**
   * Clear saved forms. This has a delay due to debounce
   */
  useEffect(() => {
    if ((Object.keys(savedForms).length > 0) && (!isConnected)) {
      dispatch(forgetSavedForms());
    }
  }, [dispatch, isConnected, savedForms]);

  let extraStyleProps = {};

  if (posType.isKSeries) {
    extraStyleProps = { pt: '180px' };
  }

  return <Box padding="1.125rem" pb={5} maxWidth="1024px" {...extraStyleProps}>

    {loadAccounts && <Accounts currentLocation={currentLocation} dispatch={dispatch} />}
    {showBack &&
      <NavigateBack
        isSubmitting={isSubmitting}
        currentLocation={currentLocation}
        isMultiLocation={isMultiLocation}
        disableBack={disableBack}
        {...props}
      />
    }
    <HeadingSection title={title} description={description} />
    {showDisconnected && <ConnectionLost swName={connectionModeName} />}
    {children}
    {switchMapping && switchMapping.isShown ?
      <SwitchMappingBar {...switchMapping} />
      :
      (hasChanges ?
        <>
          <SaveBar
            isSubmitting={isSubmitting}
            disconnectUser={disconnectUser}
            discardData={discardData}
            submitData={submitData}
            deactivateUserConfirmBox={props.isUserDeactivated}
            softwareName={connectionModeName} />
        </>
        :
        <DisconnectButtonshow
          isSubmitting={isSubmitting}
          disconnectUser={disconnectUser}
          submitData={submitData}
          deactivateUserConfirmBox={props.isUserDeactivated}
          softwareName={connectionModeName} />
      )
    }
  </Box>
};
