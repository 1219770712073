import { useState } from 'react';
import { Heading3, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import useConnectionMode from '../../util/useConnectionMode';
import useAccounts from '../../util/useAccounts';
import { CreateAccount } from '../createAccount/CreateAccount';

export const MapServiceCharges = ({ formik }) => {
  const [, softwareName] = useConnectionMode();
  const [isLoading, accountsList] = useAccounts('serviceCharge');
  const [createAccountData, setCreateAccountData] = useState({
    isOpen: false,
    accountName: '',
  });

  const handleCreateAccount = (newAccountName) => {
    setCreateAccountData({
      isOpen: true,
      accountName: newAccountName,
    });
  };

  const closeAccountModal = () => {
    setCreateAccountData({ isOpen: false });
  };

  return <Box padding="0.75rem">
    {createAccountData.isOpen === true &&
      <CreateAccount
        accountType="Other Current Liability"
        closeModal={closeAccountModal}
        accountName={createAccountData.accountName}
      />
    }
    <Heading3 mb="0.375rem">Service charges</Heading3>
    <Text color="gray-600" mb="1.5rem" mr="3.25rem" size="small">
      {`Service charges can be mapped to an account in ${softwareName}.`}
    </Text>
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="serviceChargeAccount">
          Select a service charges account
        </LabelRequired>
        <Autocomplete
          isLoadingOptions={isLoading}
          id="serviceChargeAccount"
          name="serviceChargeAccount"
          options={accountsList}
          placeholder="Select an account"
          onCreateOption={handleCreateAccount}
          formik={formik}
        />
      </Box>
    </Flex>
  </Box>;
};
