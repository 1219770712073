import { Router, Redirect } from "@reach/router";
import { Authorize } from '../authorize/Authorize';
import { SelectSoftware } from '../selectSoftware/SelectSoftware';
import { Dashboard } from '../dashboard/Dashboard';
import { SelectLocation } from '../selectLocation/SelectLocation';
import { MapIndividualProducts } from '../mapSalesByProducts/MapIndividualProducts';
import { IndividualPaymentMethods } from '../mapPaymentMethods/IndividualPaymentMethods';
import { IndividualDiscounts } from '../mapDiscounts/IndividualDiscounts';
import { SyncLogs } from '../syncLogs/SyncLogs';
import { Login } from '../login/Login';
import { Signup } from '../signup/Signup';
import { getCurrentTarget } from '../../util';
import { IndividualDifferences } from '../mapDifferences/IndividualDifferences';
import { AuthorizeEpos } from '../authorize/AuthorizeEpos';
import { OverShortIndividual } from '../overShort/OverShortIndividual';
import { InventoryCogsIndividual } from '../cogsInventory/InventoryCogsIndividual';
import { MapIndividualPurchaseTax } from '../cogsInventory/MapIndividualPurchaseTax';

/* Chart of accounts */
import ChartAccountsList from "../chartOfAccounts/AccountsTable"
import { CompleteOAuth } from "../../api/CompleteOAuth";

export const Main = () => {
  const target = getCurrentTarget();

  return (
    <Router style={{ height: '100%' }} basepath={process.env.REACT_APP_BASEPATH}>
      {target.isLs && <Authorize path="/" />}
      {(target.isRetail || target.isMindbody) &&
        <>
          <Redirect from="/" to="./login" noThrow />
          <Login path="/login" />
          <Signup path="/signup" />
        </>
      }
      {target.isEposNow && <AuthorizeEpos path="/" />}
      <Authorize path="/authorize-existing" />
      <Authorize path="/authorize-new" fromOauth={true} />
      <CompleteOAuth path="/completeOAuth" />
      <SelectSoftware path="/selectsw" />
      <Dashboard path="/dashboard" />
      <SelectLocation path="/select-location" />
      <MapIndividualProducts path="/product-mapping" />
      <IndividualPaymentMethods path="/payment-method" />
      <IndividualDiscounts path="/map-discounts" />
      <SyncLogs path="/sync-logs" />
      <IndividualDifferences path="/map-differences" />
      <OverShortIndividual path="/map-over-short" />
      <InventoryCogsIndividual path="/inventory-cogs-mapping" />
      <MapIndividualPurchaseTax path="/map-purchase-tax" />
      <ChartAccountsList path="/chart-of-account" />
      </Router>
  );
};
