import axios from 'axios';
import {
  getChangeSyncStatusUrl,
  getSubmitConfigUrl,
  getSalesSyncDataUrl,
  getPoSyncDataUrl,
  getSalesOrderDetailsUrl,
  getPurchaseOrderDetailsUrl,
  getRegisterSwUrl,
  getReconnectAccountUrl,
  getAccountsUrl,
  getDeatactivateUserUrl,
  getSyncStatusUrl
} from './urls';
import { getHeaders } from '../util/api';
import { getCurrentTarget } from '../util';

const target = getCurrentTarget();

export const getSalesSyncData = (location, pbt, pot, s, sd, ed, isTypePo) => {
  return axios.get(isTypePo ? getPoSyncDataUrl() : getSalesSyncDataUrl(), {
    headers: getHeaders(location),
    params: {
      pageBackupTable: pbt,
      pageOriginalTable: pot,
      s,
      sd,
      ed
    }
  });
};

export const getPoSyncData = (location) => {
  return axios.get(getPoSyncDataUrl(), {
    headers: getHeaders(location)
  });
};

export const changeSyncStatus = (newValue, location) => {
  const payload = {
    syncEnabled: newValue,
  };
  return axios.put(getChangeSyncStatusUrl(), payload, {
    headers: getHeaders(location),
  });
};

export const fetchConfig = (location) => {
  return axios.get(getSubmitConfigUrl(), {
    headers: getHeaders(location),
  });
};

export const getSalesOrderDetails = (orderNumber, location) => {
  const options = {
    headers: getHeaders(location),
  };

  let url = getSalesOrderDetailsUrl();

  if (target?.isLs || target?.isRetail) {
    url = `${url}${orderNumber}`;
  } else {
    options.params = { orderNumber };
  }

  return axios.get(url, options);
};

export const getPurchaseOrderDetails = (orderNumber, location) => {
  
  let url = getPurchaseOrderDetailsUrl();

  if (target?.isLs || target?.isRetail) {
    url = `${url}${orderNumber}`;
  }

  return axios.get(url, {
    headers: getHeaders(location)
  });
};

export const deactivateAccount = (location) => {
  return axios.delete(getRegisterSwUrl(), {
    headers: getHeaders(location),
  });
};

export const reconnectAccount = (location) => {
  return axios.get(getReconnectAccountUrl(), {
    headers: getHeaders(location),
  });
};

export const createAccount = (payload, locationId) => {
  return axios.post(getAccountsUrl(), payload, {
    headers: getHeaders(locationId)
  });
};

export const getDisconnectUser = (location) => {
  return axios.get(getDeatactivateUserUrl(), {
    headers: getHeaders(location)
  });
};

export const getSyncStatus = (location) => {
  return axios.get(getSyncStatusUrl(), {
    headers: getHeaders(location)
  });
};