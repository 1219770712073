import {
  Table,
  Thead,
  Th,
  Td,
  Tr,
} from '@lightspeed/cirrus-table';
import { Bone } from '@lightspeed/flame/Bone';
import { TableWithHeading } from './TableWithHeading';
import { IconCheckmark } from '@lightspeed/flame/Icon/Checkmark';
import { useTranslation } from 'react-i18next';
import useCurrency from '../../util/hooks/useCurrency';

const TaxCell = ({ value }) => {
  return <>
    {
      value === true &&
      <IconCheckmark />
    }
  </>;
};

const TableContent = ({ data, postingType }) => {
  const { t } = useTranslation();
  const { toCurrency } = useCurrency();

  return <Table>
    <Thead>
      <Tr>
        {(postingType === 1) ?
          <>
            <Th>Asset Account</Th>
            <Th>Expense Account</Th>
            <Th>Product</Th>
            <Th>{t('Unit price')}</Th>
            <Th>{t('Quantity')}</Th>
            <Th>{t('Amount')}</Th>
            <Th>{t('Tax')}</Th>
            <Th>{t('Class')}</Th>
          </> :
          <>
            <Th>Product</Th>
            <Th>{t('Amount')}</Th>
            <Th>Account</Th>
            <Th>{t('Class')}</Th>
            <Th>{t('Tax')}</Th>
          </>
        }
      </Tr>
    </Thead>
    <tbody>
      {
        data ?
          <>
            {data.length === 0 ?
              <Tr>
                <td colSpan="2">{t('No rows to show')}</td>
              </Tr> :
              data.map((item, index) => (
                <Tr key={index}>
                  {(postingType === 1) ?
                    <>
                      <Td>{item.assetAccount}</Td>
                      <Td>{item.expenseAccount}</Td>
                      <Td>{item.name}</Td>
                      <Td style={{ whiteSpace: 'pre' }}>{toCurrency(item.unitPrice)}</Td>
                      <Td>{item.quantity}</Td>
                      <Td style={{ whiteSpace: 'pre' }}>{toCurrency(item.amount)}</Td>
                      <Td><TaxCell value={item.taxEnabled} /></Td>
                      <Td>{item.class}</Td>
                    </> :
                    <>
                      <Td>{item.name}</Td>
                      <Td style={{ whiteSpace: 'pre' }}>{toCurrency(item.amount)}</Td>
                      <Td>{item.account}</Td>
                      <Td>{item.class}</Td>
                      <Td><TaxCell value={item.taxEnabled} /></Td>
                    </>
                  }
                </Tr>
              ))
            }
          </> :
          [1, 2, 3, 4, 5].map((item, index) => (
            <Tr key={index}>
              <Td><Bone height="1rem" /></Td>
              <Td><Bone height="1rem" /></Td>
              <Td><Bone height="1rem" /></Td>
              <Td><Bone height="1rem" /></Td>
              <Td><Bone height="1rem" /></Td>
              <Td><Bone height="1rem" /></Td>
              <Td><Bone height="1rem" /></Td>
              {postingType === 1 &&
                <>
                  <Td><Bone height="1rem" /></Td>
                  <Td><Bone height="1rem" /></Td>
                </>
              }
            </Tr>
          ))
      }
    </tbody>
  </Table>;
};

export const PurchasesTable = ({ heading, ...props }) => {
  const { data } = props;
  const canShowTable = data ? data.length > 0 : true;
  return <>
    {canShowTable &&
      <TableWithHeading heading={heading ?? 'Purchases'}>
        <TableContent {...props} />
      </TableWithHeading>
    }
  </>;
};
