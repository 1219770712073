import {
  Table,
  Thead,
  Th,
  Td,
  Tr,
} from '@lightspeed/cirrus-table';
import { Bone } from '@lightspeed/flame/Bone';
import { TableWithHeading } from './TableWithHeading';
import { useTranslation } from 'react-i18next';
import { useCurrency } from '../../util/hooks/useCurrency';
import useLocale from '../../util/hooks/useLocale';

const DateCell = ({ value }) => {
  const locale = useLocale();
  const dateObj = new Date(value);
  const result = dateObj && new Intl.DateTimeFormat((locale.startsWith('en')) ? 'fr-CA' : locale).format(dateObj);
  return <>{result}</>;
};

const TableContent = ({ data, isJeLayout }) => {
  const { t } = useTranslation();
  const { toCurrency } = useCurrency();

  return <Table>
    <Thead>
      <Tr>
        <Th>{t('Account')}</Th>
        {!isJeLayout && <Th>{t('Payment Method')}</Th>}
        <Th>{t('Date')}</Th>
        {isJeLayout ?
          <>
            <Th>{t('Amount')}</Th>
            <Th>{t('Balance')}</Th>
            <Th>{t('Payment')}</Th>
          </>
          :
          <>
            <Th>{t('Reference Number')}</Th>
            <Th>{t('Amount')}</Th>
          </>
        }
      </Tr>
    </Thead>
    <tbody>
      {
        data ?
          <>
            {data.length === 0 ?
              <Tr>
                <td colSpan="5">{t('No rows to show')}</td>
              </Tr> :
              data.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.account}</Td>
                  {!isJeLayout && <Td>{item.name}</Td>}
                  <Td><DateCell value={item.date} /></Td>
                  {isJeLayout ?
                    <>
                      <Td style={{ whiteSpace: 'pre' }}>{toCurrency(item.amount)}</Td>
                      <Td>{item.balance}</Td>
                      <Td>{item.payment}</Td>
                    </>
                    :
                    <>
                      <Td>{item.referenceNumber}</Td>
                      <Td style={{ whiteSpace: 'pre' }}>{toCurrency(item.amount)}</Td>
                    </>
                  }
                </Tr>
              ))
            }
          </> :
          [1, 2, 3, 4, 5].map((item, index) => (
            <Tr key={index}>
              <Td><Bone height="1rem" /></Td>
              <Td><Bone height="1rem" /></Td>
              <Td><Bone height="1rem" /></Td>
              <Td><Bone height="1rem" /></Td>
              <Td><Bone height="1rem" /></Td>
            </Tr>
          ))
      }
    </tbody>
  </Table>;
};

export const PaymentsTable = ({ heading, ...props }) => {
  const { t } = useTranslation();
  const { data } = props;
  const canShowTable = data ? data.length > 0 : true;

  return <>
    {canShowTable &&
      <TableWithHeading
        heading={heading || t('Payments')}
      >
        <TableContent {...props} />
      </TableWithHeading>
    }
  </>;
};
