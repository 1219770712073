import {
  tokenInfoUrl,
} from './urls';
import axios from 'axios';

export const fetchCompanyDetails = (appToken) => {
  const url = tokenInfoUrl;

  const extras = {
    headers: {
      'Authorization': `Basic ${appToken}`,
    }
  };

  return axios.get(url, extras);
};
