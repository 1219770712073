import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MapGeneric } from '../mapGeneric/MapGeneric';
import { getDiscountsList } from '../../api/advancedConfig';
import useLocation from '../../util/useLocation';
import { useToasts } from '@lightspeed/flame/Toaster';
import useAccounts from '../../util/useAccounts';
import useConnectionMode from '../../util/useConnectionMode';
import { CreateAccount } from '../createAccount/CreateAccount';
import { getCurrentTarget } from '../../util';
import { useTranslation } from 'react-i18next';

export const IndividualDifferences = (props) => {
  const { isChangedPostingMethod } = useSelector(state => state.forms.meta);
  const fetchedMapping = isChangedPostingMethod ? null : props.location.state.fetchedMapping;
  const location = useLocation();
  const { addToast } = useToasts();
  const [, accountsList] = useAccounts('discount');
  const { defaultDiffAccount: defaultAccountValue } = useSelector(state =>
    state.forms.entities.dashboard?.values
  ) || {};
  const placeholderAccount = defaultAccountValue && accountsList.find(account => (account.value === defaultAccountValue));
  const placeholder = placeholderAccount && placeholderAccount.label;
  const [, softwareName] = useConnectionMode();
  const [createAccountData, setCreateAccountData] = useState({
    isOpen: false,
    accountName: '',
  });
  const target = getCurrentTarget();
  const { t } = useTranslation();

  const handleCreateAccount = (newAccountName) => {
    setCreateAccountData({
      isOpen: true,
      accountName: newAccountName,
    });
  };

  const closeAccountModal = () => {
    setCreateAccountData({ isOpen: false });
  };

  const config = [
    {
      title: t('LsDifferences', { target: target.fullName }),
      name: 'diff',
    },
    {
      title: t('SwFloatDiffAccount', { swName: softwareName }),
      name: 'account',
      options: accountsList,
      type: 'dropdown',
      placeholder: placeholder,
      onCreateOption: handleCreateAccount,
    }
  ];

  const handleError = (res, msgArg) => {
    let msg = msgArg || t('Fetch failure');

    if (res && res.data && res.data.message) {
      msg = res.data.message.errorMessage || msg;
    }
    addToast(msg, { appearance: 'error' });
  };

  const fetchRows = () => {
    return getDiscountsList(location.id).then(res => {
      let rowsList;
      if (res.data) {
        const resData = res.data;
        rowsList = resData.map(rowInfoRaw => {
          const formValues = {
            [config[0].name]: rowInfoRaw.name,
            [config[1].name]: (fetchedMapping && fetchedMapping[rowInfoRaw.id]) || '',
            id: rowInfoRaw.id,
          };

          return formValues;
        });

        return rowsList;
      } else {
        handleError(res);
      }
    }).catch(err => {
      handleError(err.response);
    });
  };

  return <>
    {
      createAccountData.isOpen === true &&
      <CreateAccount
        accountType="Income"
        closeModal={closeAccountModal}
        accountName={createAccountData.accountName}
      />
    }
    <MapGeneric
      pageTitle="Map float differences"
      pageDescription={t('FloatDiffPageDescription', { swName: softwareName })}
      title={t('Float differences')}
      description={t('FloatDiffDescription', { swName: softwareName })}
      config={config}
      fetchRows={fetchRows}
      name="differencesMapping"
      persistFormName="differencesMapping"
      {...props}
    />
  </>;
};
