import { useSelector } from 'react-redux';
import { AnnotatedLayout } from '@lightspeed/flame/Layout';
import { Heading3, Text } from '@lightspeed/flame/Text';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Divider } from '@lightspeed/flame/Divider';
import { Box } from '@lightspeed/flame/Core';
import { MapSalesByProducts } from '../mapSalesByProducts/MapSalesByProducts';
import { MapPaymentMethods } from '../mapPaymentMethods/MapPaymentMethods';
import { MapDiscounts } from '../mapDiscounts/MapDiscounts';
import { MapTips } from '../mapTips/MapTips';
import { MapServiceCharges } from '../mapServiceCharges/MapServiceCharges';
import { RefreshAccounts } from '../refreshAccounts/RefreshAccounts';
import { useShowTaxError } from '../../util/hooks/useShowTaxError';
import { getCurrentTarget } from '../../util';
import useConnectionMode from '../../util/useConnectionMode';
import { useTranslation } from 'react-i18next';
import { LearnMore } from '../custom/LearnMore';
import usePosType from '../../util/hooks/usePosType';
import useIsInputsOnlyLayout from '../../util/hooks/useIsInputsOnlyLayout';
import { MapTaxCodes } from '../mapTaxCodes/MapTaxCodes';
import { Button } from '@lightspeed/flame/Button';
import { IconEyeOn } from '@lightspeed/flame/Icon/EyeOn';
import useRole from '../../util/hooks/useRole';
const target = getCurrentTarget();

const MapTaxRates = ({ fetchedConfig, ...props }) => {
  //const [isAppSubmitting] = useAppSubmitting();
  const showError = useShowTaxError(fetchedConfig);
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();

  const agenciesMapping = props?.formik?.initialValues?.vendorNameTax;
  const taxMapping = props?.formik?.initialValues?.tax;
  const isTaxAgency = useSelector(state => state.globals.isTaxAgency);
  const isJournalEntry = useSelector(state => state.forms.entities.dashboard?.values.postingMethod) === 'journalEntry' || props?.formik?.values?.postingMethod === 'journalEntry'
  const isQB = swName === "QuickBooks Desktop"

  const isToShowTaxAgency = isTaxAgency && isQB && isJournalEntry

  return <Box padding="0.75rem">
    {swName === 'Datev' ?
      <Heading3 mb="0.375rem">{t('Revenue accounts')}</Heading3>
      :
      <Heading3 mb="0.375rem">{t('Tax rates')}</Heading3>
    }

    <Text color="gray-600" mb="1.125rem" size="small">
      {swName === 'Datev' ?
        <>{t('TaxRateDescriptionDatev')}</>
        :
        <>{t('TaxRateDescription', { swName })}</>
      }
    </Text>
    <MapTaxCodes isTaxAgency={isToShowTaxAgency} {...props} />
  </Box>;
};

const Section = ({ children }) => {
  return <>
    <CardSection>
      {children}
    </CardSection>
    <Divider />
  </>
};

const MapAccountsContent = (props) => {
  const { postingMethod } = props.formik.values;
  const dashboardForm = useSelector(state => state.forms.entities.dashboard);
  const posType = usePosType();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const [, swName] = useConnectionMode();

  return <Card ml="1rem">
    {dashboardForm && postingMethod && postingMethod !== 'auto' &&
      <>
        <Section>
          {
            (swName !== 'Datev') ?
              <MapSalesByProducts {...props} /> :
              ''
          }

        </Section>
        <Section>
          <MapPaymentMethods {...props} />
        </Section>
      </>
    }
    <Section>
      <MapTaxRates {...props} />
    </Section>
    {dashboardForm && postingMethod && postingMethod !== 'auto' &&
      <>
        {!isInputsOnlyLayout && (!posType.isKSeries) &&
          <Section>
            <MapDiscounts {...props} />
          </Section>
        }
        {(!posType.isRetail) &&
          <Section>
            <MapTips {...props} />
          </Section>
        }
        {target.isRevel &&
          <Section>
            <MapServiceCharges {...props} />
          </Section>
        }
      </>
    }
  </Card>;
};

export const MapAccounts = (props) => {
  const formik = props.formik;
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const role = useRole()
  const connectionMode = useConnectionMode()

  const handleClick = () => {
    props.navigate('../chart-of-account');
  };
  return <AnnotatedLayout
    title={t('Map Accounts')}
    description={t('MapAccountsDescription', { target: target.fullName })}
    renderExtras={
      <>
        <LearnMore />
        {formik &&
          formik?.values?.postingMethod !== 'auto' &&
          (isInputsOnlyLayout === false) &&
          <RefreshAccounts />
        }
        {role[0].type === 1 && connectionMode[0] !== 'Datev' ?
          <Button fontWeight="bold" style={{ marginTop: "0.75rem" }} onClick={handleClick} >
            <IconEyeOn /> {t('View charts of accounts')}
          </Button>
          :
          ''
        }
      </>

    }
    mt="2.25rem"
    mb="2.25rem"
  >
    <MapAccountsContent {...props} />
  </AnnotatedLayout>;
};
