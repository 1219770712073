import { useState } from 'react';
import { TextLink, Heading3, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Bone } from '@lightspeed/flame/Bone';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete, SwitchField } from '../fields';
import { useToasts } from '@lightspeed/flame/Toaster';
import useConnectionMode from '../../util/useConnectionMode';
import useAccounts from '../../util/useAccounts';
import useAppSubmitting from '../../util/useAppSubmitting';
import { CreateAccount } from '../createAccount/CreateAccount';
import { useTranslation } from 'react-i18next';
import useIsInputsOnlyLayout from '../../util/hooks/useIsInputsOnlyLayout';

export const MapDiscounts = ({ fetchedConfig, ...props }) => {
  const [isAppSubmitting] = useAppSubmitting();
  const formik = props.formik;
  const [, softwareName] = useConnectionMode();
  const { addToast } = useToasts();
  const [isLoadingAccounts, accountsList] = useAccounts('discount');
  const {
    isMapDiscounts,
    defaultDiscountsAccount: defaultAccount
  } = formik.values;
  const [createAccountData, setCreateAccountData] = useState({
    isOpen: false,
    accountName: '',
  });
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const isLoading = isInputsOnlyLayout ? false : isLoadingAccounts;

  const handleCreateAccount = (newAccountName) => {
    setCreateAccountData({
      isOpen: true,
      accountName: newAccountName,
    });
  };

  const closeAccountModal = () => {
    setCreateAccountData({ isOpen: false });
  };

  const gotoDiscountMapping = () => {
    const fetchedMappingRaw = fetchedConfig && fetchedConfig?.attributes?.discountsMapping;
    const fetchedMapping = {}
    if (fetchedMappingRaw) {
      fetchedMappingRaw.forEach((mapping) => {
        fetchedMapping[mapping.id] = mapping.account;
      });
    }
    const state = {
      fetchedMapping,
    };

    props.navigate('../map-discounts', { state });
  };

  const handleNavigation = () => {
    if (isAppSubmitting) {
      addToast(t('Please wait for data to save'), { appearance: 'error' });
    } else if (defaultAccount) {
      gotoDiscountMapping();
    } else {
      addToast(t(isInputsOnlyLayout ? 'DefaultAccountTextInputFirst' : 'SelectDiscountFirst'), {
        appearance: 'error'
      });
    }
  };

  return <Box padding="0.75rem">
    {createAccountData.isOpen === true &&
      <CreateAccount
        accountType="Income"
        closeModal={closeAccountModal}
        accountName={createAccountData.accountName}
      />
    }
    <Flex justifyContent="space-between">
      <Heading3 mb="0.375rem">{t('Discounts')}</Heading3>
      <SwitchField id="isMapDiscounts" name="isMapDiscounts" disabled={isLoading} />
    </Flex>
    <Text color="gray-600"
      mb={isMapDiscounts ? '1.5rem' : 0}
      mr="3.25rem"
      size="small"
    >
      {t('MapDiscountDescription', { swName: softwareName })}
    </Text>
    {isMapDiscounts &&
      <Flex>
        <Box width="49%" pr="1.1rem">
          <LabelRequired htmlFor="defaultDiscountsAccount">
            {t('Default discount account')}
          </LabelRequired>
          <Autocomplete
            id="defaultDiscountsAccount"
            name="defaultDiscountsAccount"
            isLoading={isLoading}
            options={accountsList}
            formik={formik}
            placeholder={
              t(isInputsOnlyLayout ? 'Enter default account' : 'Select an account')
            }
            onCreateOption={handleCreateAccount}
          />
        </Box>
        <Flex mt="1.4rem" alignItems="center" height="2.25rem">
          {isLoading ?
            <Bone height="1rem" width="12rem" /> :
            <TextLink size="small" fontWeight="bold"
              onClick={handleNavigation}
            >
              {t('Map individual discounts')}
            </TextLink>
          }
        </Flex>
      </Flex>
    }
  </Box>;
};