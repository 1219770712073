import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MapGeneric } from '../mapGeneric/MapGeneric';
import useLocation from '../../util/useLocation';
import useAccounts from '../../util/useAccounts';
import useConnectionMode from '../../util/useConnectionMode';
import { CreateAccount } from '../createAccount/CreateAccount';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../../util/hooks/useMessage';
import { getItemsList } from '../../api/advancedConfig';

const getAddRowRadioButtons = () => {
  return [
    {
      label: 'Add the highest level categories',
      value: 'highest',
    },
    {
      label: 'Add the deepest level categories',
      value: 'deepest'
    },
  ];
};

export const InventoryCogsIndividual = (props) => {
  const { isChangedPostingMethod } = useSelector(state => state.forms.meta);
  const isParentCategory = isChangedPostingMethod ? null : props.location.state.isParentCategory;
  const swapCategoryFromProducts = isChangedPostingMethod ? null : props.location.state.swapCategoryFromProducts;
  const location = useLocation();
  const [, inventoryAccounts] = useAccounts('inventory');
  const [, cogsAccounts] = useAccounts('cogs');
  const {
    isMapPurchaseInvoice,
    isTrackInventory,
    isMapCogs,
    defaultInventoryAccount,
    defaultCogsAccount,
    postingMethod
  } = useSelector(state =>
    state.forms.entities.dashboard?.values
  ) || {};
  const [placeholderAccountInventory, placeholderAccountCogs] = [defaultInventoryAccount, defaultCogsAccount].map((defaultAccount, index) => {
    const accountsList = [inventoryAccounts, cogsAccounts][index];
    return defaultAccount && accountsList.find(account => (account.value === defaultAccount));
  });
  const [, softwareName] = useConnectionMode();
  const [createAccountData, setCreateAccountData] = useState({
    isOpen: false,
    accountName: '',
  });
  const { t } = useTranslation();
  const { addMessage } = useMessage();
  const isSituationThree = (isMapPurchaseInvoice && !(isMapCogs || isTrackInventory));
  const [isTypeProduct, setIsTypeProduct] = useState(
    (postingMethod === 'invoiceCategory' ?
      false
      :
      !(swapCategoryFromProducts ?? true))
  );
  const formName = isTypeProduct ? 'poProductsMapping' : 'poCategoriesMapping';

  const handleCreateAccount = (newAccountName, accountType) => {
    setCreateAccountData({
      isOpen: true,
      accountName: newAccountName,
      accountType,
    });
  };

  const closeAccountModal = () => {
    setCreateAccountData({ isOpen: false });
  };

  const config = [
    {
      title: `Lightspeed ${isTypeProduct ? 'products' : 'categories'}`,
      name: 'itemName',
    },
    (!isSituationThree) && {
      title: t('Sw Inventory accounts', { swName: softwareName }),
      name: 'inventoryAccount',
      options: inventoryAccounts,
      type: 'dropdown',
      placeholder: placeholderAccountInventory ? placeholderAccountInventory.label : 'Select an account',
      onCreateOption: (args) => { handleCreateAccount(args, 'Income') },
    },
    {
      title: `${softwareName} ${isTypeProduct ? 'COGS' : 'Expense'} accounts`,
      name: 'cogsAccount',
      options: cogsAccounts,
      type: 'dropdown',
      placeholder: placeholderAccountCogs ? placeholderAccountCogs.label : 'Select an account',
      onCreateOption: (args) => { handleCreateAccount(args, 'Expense') },
    }
  ].filter(Boolean);

  const handleError = (res) => {
    addMessage(res, { default: t('Fetch failure') });
  };

  const fetchRows = (inputParams) => {
    const params = {
      recordItems: false,
    };

    let newFormName = formName;

    if (inputParams.mappingType) {
      params.recordItems = inputParams.mappingType === 'product';
      setIsTypeProduct(params.recordItems);
      newFormName = params.recordItems ? 'poProductsMapping' : 'poCategoriesMapping';
    }
    if (!params.recordItems) {
      params.superParentCategory = inputParams.breakCategories === 'highest';
    }

    const fetchedMapping = isChangedPostingMethod ? null : (
      newFormName === 'poProductsMapping' ? props.location.state.fetchedMapping.itemsMapping : props.location.state.fetchedMapping.categoriesMapping
    );

    return getItemsList(params, location.id).then(res => {
      let rowsList;
      if (res.data) {
        const resData = res.data;
        rowsList = resData.map((rowInfoRaw, rowIndex) => {
          const formValues = {
            [config[0].name]: rowInfoRaw.name,
            inventoryAccount: ((!inputParams.hasMappingLevelChanged) && (!isSituationThree) && fetchedMapping && fetchedMapping[rowInfoRaw.name] && fetchedMapping[rowInfoRaw.name].inventoryAccount) || '',
            cogsAccount: ((!inputParams.hasMappingLevelChanged) && fetchedMapping && fetchedMapping[rowInfoRaw.name] && fetchedMapping[rowInfoRaw.name].expenseAccount) || '',
            id: rowInfoRaw.id,
            rowId: rowIndex,
          };

          return formValues;
        });

        return {
          isTypeRowDataObject: true,
          formName: newFormName,
          rowsList,
        };
      } else {
        handleError(res);
      }
    }).catch(err => {
      handleError(err.response);
    });
  };

  const checkHasMapping = (rowData) => {
    let result = false

    if (rowData) {
      result = rowData.cogsAccount;

      if (!isSituationThree) {
        result = result || rowData.inventoryAccount;
      }
    }

    return result;
  };

  const createAddRowOption = (rowData) => {
    return {
      value: rowData.id,
      label: rowData[config[0].name],
      rowId: rowData.rowId,
    };
  };

  const handleRemoveMapping = (rowData) => {
    const newValues = {
      cogsAccount: '',
    };

    if (!isSituationThree) {
      newValues.inventoryAccount = '';
    }

    return Object.assign({}, rowData, newValues);
  };

  return <>
    {
      createAccountData.isOpen === true &&
      <CreateAccount
        accountType={createAccountData.accountType}
        closeModal={closeAccountModal}
        accountName={createAccountData.accountName}
      />
    }
    <MapGeneric
      pageTitle={`Map Inventory${!isSituationThree && (postingMethod !== 'invoiceCategory') ? ' and COGS' : ''} by ${isTypeProduct ? 'product' : 'category'}`}
      title={`${isTypeProduct ? 'Product' : 'Category'}`}
      description={`Individual ${isTypeProduct ? 'products' : 'sales categories'} can be mapped to unique accounts. All unassigned and new ${isTypeProduct ? 'products' : 'categories'} will be assigned to the default sales account.`}
      config={config}
      fetchRows={fetchRows}
      name={formName}
      persistFormName={formName}
      selectiveMapping={{
        enabled: true,
        heading: `Add ${isTypeProduct ? 'product' : 'category'}`,
        description: `Add a ${isTypeProduct ? 'product' : 'category'} to the list below to map it to a custom inventory and COGS accounts.`,
        placeholder: `Select ${isTypeProduct ? 'product' : 'category'} name`,
        hasMapping: checkHasMapping,
        rowToOption: createAddRowOption,
        removeMapping: handleRemoveMapping,
        radioButtons: {
          isShown: !isTypeProduct,
          buttons: getAddRowRadioButtons(),
          field: 'breakCategories',
          initialValue: (Boolean(isParentCategory) === false) ? 'deepest' : 'highest',
        }
      }}
      mappingSwitch={{
        isEnabled: postingMethod !== 'invoiceCategory',
        initialValue: isTypeProduct ? 'product' : 'category',
      }}
      {...props}
    />
  </>;
};
