import { useEffect, useState } from 'react';
import { useLocale } from './useLocale';

export const useCurrency = () => {
  const [formatter, setFormatter] = useState();
  const locale = useLocale();

  useEffect(() => {
    setFormatter(Intl.NumberFormat(locale));
  }, [locale]);

  const toCurrency = (value) => {
    let result = '';

    if (![null, undefined, ''].includes(value) && formatter) {
      result = formatter.format(value);
    }

    return result;
  };

  return { toCurrency };
};

export default useCurrency;