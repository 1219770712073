import { saveToken } from './api';
import { setLocationList, setLocation } from '../state/locationReducer';
import { setSoftwareList } from '../state/softwareListReducer';

export const moveToNextScreen = (navigate, dispatch, nextPageUrl, resData, initialLocationId, token, selectFirstLocation) => {
  let url = `${nextPageUrl.startsWith('..') ? '..' : '.'}/select-location`;
  saveToken(token || resData.token);
  dispatch(setSoftwareList(resData.accountingSoftware));
  const locations = resData.locationList;
  dispatch(setLocationList(locations));
  if (selectFirstLocation || initialLocationId || locations.length < 2) {
    url = nextPageUrl;
    const location = initialLocationId ? { id: initialLocationId } : locations[0];
    dispatch(setLocation(location));
  }

  navigate(url);
};
