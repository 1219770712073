import { Modal, ModalBody, ModalHeader, ModalFooter } from '@lightspeed/flame/Modal';
import { Flex, Box } from '@lightspeed/flame/Core';
import { Text } from '@lightspeed/flame/Text';
import { Button } from '@lightspeed/flame/Button';
import { AlertInCard } from '@lightspeed/flame/Alert';
import { Divider } from '@lightspeed/flame/Divider';
import { Label } from '@lightspeed/flame/FormField';
import { SyncStatusBadge } from '../custom/SyncStatusBadge';
import { SalesTable } from './SalesTable';
import { useState, useEffect } from 'react';
import { postOrder, repostOrder, repostPoOrder } from '../../api/syncLogs';
import useLocation from '../../util/useLocation';
import { useToasts } from '@lightspeed/flame/Toaster';
import { PaymentsTable } from './PaymentsTable';
import { useTranslation } from 'react-i18next';
import useRole from '../../util/hooks/useRole';
import styled from "@emotion/styled";
import { getPurchaseOrderDetails, getSalesOrderDetails } from '../../api/dashboard';
import { Bone } from '@lightspeed/flame/Bone';
import { getCurrentTarget } from '../../util';
import { useMessage } from '../../util/hooks/useMessage';
import { useCurrency } from '../../util/hooks/useCurrency';
import useLocale from '../../util/hooks/useLocale';
import { AdjustmentTable } from './AdjustmentTable';
import { PurchasesTable } from './PurchasesTable';

const target = getCurrentTarget();

const ErrorReason = () => {
  const { t } = useTranslation();

  return <AlertInCard
    type="danger"
    width="auto"
    noCloseBtn
    mb="1.5rem"
  >
    {t('PaymentNotEqual')}
  </AlertInCard>;
};

const RepostWarning = ({ closeModal, confirmAction, swName, actionDate }) => {
  const locale = useLocale();
  const transactionDate = new Date(actionDate.replace(/-/g, "/") + ' 0:0:0');
  const transactionDateText = new Intl.DateTimeFormat(locale, { dateStyle: 'long' }).format(transactionDate);
  const { t } = useTranslation();

  return <Modal
    isOpen={true}
    onRequestClose={closeModal}
  >
    <ModalHeader>{t('Warning')}</ModalHeader>
    <ModalBody style={{ maxWidth: '600px', width: '600px' }} scroll={true}>
      <Text fontWeight="bold" fontSize="text-s" mb="0.75rem">
        {t('RePostWarning.0', { transactionDate: transactionDateText })}
      </Text>
      <Text color="gray-600" fontSize="text-s" pr="2.25rem">
        {t('RePostWarning.1', {
          transactionDate: transactionDateText,
          swName,
        })}
      </Text>
    </ModalBody>
    <ModalFooter>
      <Flex justifyContent="flex-end">
        <Button onClick={closeModal}>{t('Cancel')}</Button>
        <Button
          onClick={confirmAction}
          ml="0.75rem"
          variant="secondary"
          fill={true}
        >
          {t('RePost')}
        </Button>
      </Flex>
    </ModalFooter>
  </Modal>;
};

const ResyncRepost = ({ isTypePo, disableActions, isDisabledClose, swName, rowData, closeSyncDetails, hasNoData }) => {
  const [isShowingWarning, setIsShowingWarning] = useState(false);
  const currentLocation = useLocation();
  let type = 'repost';
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const [user] = useRole();
  const { addMessage } = useMessage();

  if ([0, false].includes(rowData.success) || hasNoData) {
    type = 'resync';
  } else if ([4].includes(rowData.success)) {
    type = 'post'
  }

  const handleButtonClick = () => {
    if (type === 'repost') {
      setIsShowingWarning(true);
    } else {
      makeRequest();
    }
  };

  const closeWarning = () => {
    setIsShowingWarning(false);
  };

  const handleError = (res) => {
    disableActions(false);
    addMessage(res, { default: t('Communication failure') });
  };

  const makeRequest = () => {
    if (!isDisabledClose) {
      disableActions(true);
      if (type === 'repost') {
        closeWarning();
      }

      const payload = {
        orderNumber: rowData.id,
        repostingData: type === 'repost',
        userType: user.type,
      };

      const resync = isTypePo ? repostPoOrder : repostOrder

      resync(payload, currentLocation.id).then(res => {
        if (res.data) {
          addToast(t('Order queued'), { appearance: 'success' });
          closeSyncDetails({
            type,
            id: rowData.id,
          });
        } else {
          handleError(res);
        }
      }).catch(err => {
        handleError(err.response);
      });
    }
  };

  const handlePost = () => {
    const payload = {
      orderNumber: rowData.id,
      userType: user.type,
    }

    postOrder(payload, currentLocation.id).then(res => {
      if (res.data) {
        addToast(t('Order queued'), { appearance: 'success' });
        closeSyncDetails({
          type,
          id: rowData.id,
        });
      } else {
        handleError(res);
      }
    }).catch(err => {
      handleError(err.response);
    });
  }

  const getDesp = (type) => {
    switch (type) {
      case 'repost':
        return <>
          {target.isRevel ?
            'You may view the logs of the transaction.' :
            t('RePostDesc')
          }
        </>
      case 'post':
        return t('postDesp', { errorMessage: rowData.errorMessage })
      default:
        return t('ResyncDesc', { errorMessage: rowData.errorMessage })
    }
  }

  return <>
    {isShowingWarning &&
      <RepostWarning
        closeModal={closeWarning}
        confirmAction={makeRequest}
        swName={swName}
        actionDate={rowData.actionDate}
      />
    }
    <Flex justifyContent="space-between" alignItems="center">
      <Text>{getDesp(type)}</Text>
      <Flex>
        {
          (type !== "post") ?
            <Button
              variant="secondary"
              fill={true}
              onClick={handleButtonClick}
              loading={isDisabledClose}
            >{type === 'repost' ? t('RePost') : t('Resync')}</Button>
            :
            <Button
              variant="secondary"
              fill={true}
              onClick={handlePost}
              loading={isDisabledClose}
            >{t("post")}</Button>
        }
      </Flex>
    </Flex>
    {!hasNoData && <Divider mb="1.5rem" mt="1.5rem" />}
  </>;
};

const MetaDetailRow = ({ label, value }) => {
  return <Flex justifyContent="space-between" mb="0.375rem">
    <Label>{label}</Label>
    {value ?
      <Text>{value}</Text>
      :
      <Bone height="1rem" width="100px" />
    }
  </Flex>;
};

const MetaDetailsLeft = ({ rowData, transactionData, isTypePo }) => {
  const locale = useLocale();
  const transactionDate = new Date(rowData.actionDate.replace(/-/g, "/") + ' 0:0:0');
  const transactionDateText = new Intl.DateTimeFormat(locale, {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  }).format(transactionDate);
  const { t } = useTranslation();

  return <Box width="50%">
    <MetaDetailRow label={t(isTypePo ? 'PO Date' : 'Date')} value={transactionDateText} />
    <MetaDetailRow label={t('Sync status')} value={<SyncStatusBadge type={rowData.success} />} />
    <MetaDetailRow label={isTypePo ? 'PO Number' : 'Reference Number'} value={transactionData && transactionData.referenceNumber} />
    {isTypePo &&
      <>
        <MetaDetailRow label={t('Memo')} value={transactionData && transactionData.memo} />
      </>
    }
  </Box>;
};

const MetaDetailsRight = ({ transactionData, isTypePo }) => {
  const { t } = useTranslation();
  const { toCurrency } = useCurrency();

  return <Box width="50%">
    {isTypePo ?
      <>
        {<MetaDetailRow label="Vendor" value={transactionData && transactionData.vendorName} />}
        <MetaDetailRow label={t('Tax amount')}
          value={transactionData && toCurrency(transactionData.taxAmount)}
        />
        <MetaDetailRow label={t('Total amount')}
          value={transactionData && toCurrency(transactionData.totalAmount)}
        />
      </>
      :
      <>
        {(!isTypePo) && <MetaDetailRow label={t('Customer')} value={transactionData && transactionData.customer} />}
        {false && <MetaDetailRow label={t('Tax rate')} value={transactionData && transactionData.taxRate} />}
        <MetaDetailRow label={t('Tax amount')}
          value={transactionData && toCurrency(transactionData.taxAmount)}
        />
        <MetaDetailRow label={t('Total amount')}
          value={transactionData && toCurrency(transactionData.totalAmount)}
        />
      </>}
  </Box>;
};

const MetaDetails = (props) => {
  // TODO: Vertical divider
  return <Flex mb="1.125rem">
    <MetaDetailsLeft {...props} />
    <Box pl="1.5rem" pr="1.5rem" />
    <MetaDetailsRight {...props} />
  </Flex>;
};

export const SyncDetails = ({ isTypePo, closeModal, swName, rowData, ...props }) => {
  const [isDisabledClose, setIsDisabledClose] = useState(false);
  const { t } = useTranslation();
  const [transactionData, setTransactionData] = useState({});
  const { addToast } = useToasts();
  const [hasLoaded, setHasLoaded] = useState(false);
  const { addMessage } = useMessage();
  const hasNoData = (![0, 1, 2, 4, true, false].includes(rowData.success));
  const selectedLocation = useLocation();

  const handleClose = () => {
    if (!isDisabledClose) {
      closeModal();
    }
  };

  useEffect(() => {
    let mounted = true;
    if (rowData.success !== 3) {

      const orderDetails = isTypePo ? getPurchaseOrderDetails : getSalesOrderDetails

      orderDetails(rowData.id, selectedLocation.id).then(res => {
        if (mounted) {
          if (res && res.data) {
            setTransactionData(res.data);
          } else {
            addToast(t('Communication failure'), { appearance: 'error' });
          }
        }
      }).catch(err => {
        if (mounted) {
          addMessage(err.response, { default: t('Fetch failure') });
        }
      }).then(() => {
        if (mounted) {
          setHasLoaded(true);
        }
      });
    }

    return () => { mounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, rowData.orderNumber]);

  const getRows = (fieldName) => {
    let result;

    if (hasLoaded) {
      result = transactionData ? transactionData[fieldName] : [];
      result = result || [];
    }

    return result;
  };

  const CustomModal = styled(Modal)`
    max-height: 100%;
  `;

  return <CustomModal
    isOpen={true}
    onRequestClose={handleClose}
  >
    <ModalHeader>{t('Sync details')}</ModalHeader>
    <ModalBody style={{ maxWidth: '800px', width: '800px' }} scroll={true}>
      {false && <ErrorReason />}
      {(rowData.success !== 2) &&
        <ResyncRepost
          disableActions={setIsDisabledClose} swName={swName}
          isDisabledClose={isDisabledClose}
          rowData={rowData}
          closeSyncDetails={closeModal}
          hasNoData={hasNoData}
          isTypePo={isTypePo}
        />
      }
      {!hasNoData &&
        <>
          <MetaDetails isTypePo={isTypePo} rowData={rowData} transactionData={transactionData} {...props} />
          {isTypePo && <PurchasesTable
            data={getRows('purchases')}
            postingType={transactionData && transactionData.postingType}
            {...props}
          />}
          {!isTypePo && <SalesTable
            data={getRows('sales')}
            mappingType={transactionData && transactionData.mappingType}
            {...props}
          />}
          {!isTypePo && <SalesTable
            data={getRows('refundSales')}
            heading={t('Refund Sales')}
            mappingType={transactionData && transactionData.mappingType}
            {...props}
          />}
          {!isTypePo && <PaymentsTable data={getRows('payments')} {...props} />}
          {!isTypePo && <PaymentsTable data={getRows('refundPayments')} heading={t('Refund Payments')} {...props} />}
          <AdjustmentTable data={getRows('adjustments')} heading={t('Adjustments')} />
        </>
      }
    </ModalBody>
    <ModalFooter>
      <Flex justifyContent="flex-end">
        <Button onClick={handleClose} disabled={isDisabledClose}>
          {t('Close')}
        </Button>
      </Flex>
    </ModalFooter>
  </CustomModal>;
};
