import {
  Table,
  Thead,
  Th,
  Td,
  Tr,
} from '@lightspeed/cirrus-table';
import { Bone } from '@lightspeed/flame/Bone';
import { TableWithHeading } from './TableWithHeading';
import { IconCheckmark } from '@lightspeed/flame/Icon/Checkmark';
import { useTranslation } from 'react-i18next';
import { useCurrency } from '../../util/hooks/useCurrency';

const TaxCell = ({ value }) => {
  return <>
    {
      value === true &&
      <IconCheckmark />
    }
  </>;
};

const TableContent = ({ data, mappingType, isJeLayout }) => {
  const { t } = useTranslation();
  const { toCurrency } = useCurrency();

  return <Table>
    <Thead>
      <Tr>
        <Th>{t('Account')}</Th>
        <Th>{t((mappingType === 1) ? 'Product' : 'Category')}</Th>
        {isJeLayout ?
          <>
            <Th>{t('Debits')}</Th>
            <Th>{t('Credits')}</Th>
            <Th>{t('Tracking')}</Th>
          </>
          :
          <>
            <Th>{t('Unit price')}</Th>
            <Th>{t('Quantity')}</Th>
            <Th>{t('Amount')}</Th>
            <Th>{t('Tax')}</Th>
            <Th>{t('Class')}</Th>
          </>
        }
      </Tr>
    </Thead>
    <tbody>
      {
        data ?
          <>
            {data.length === 0 ?
              <Tr>
                <td colSpan="2">{t('No rows to show')}</td>
              </Tr> :
              data.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.account}</Td>
                  <Td>{item.name}</Td>
                  {isJeLayout ?
                    <>
                      <Td style={{ whiteSpace: 'pre' }}>{toCurrency(item.debits)}</Td>
                      <Td>{item.credits}</Td>
                      <Td>{item.tracking}</Td>
                    </>
                    :
                    <>
                      <Td style={{ whiteSpace: 'pre' }}>{toCurrency(item.unitPrice)}</Td>
                      <Td>{item.quantity}</Td>
                      <Td style={{ whiteSpace: 'pre' }}>{toCurrency(item.amount)}</Td>
                      <Td><TaxCell value={item.taxEnabled} /></Td>
                      <Td>{item.class}</Td>
                    </>
                  }
                </Tr>
              ))
            }
          </> :
          [1, 2, 3, 4, 5].map((item, index) => (
            <Tr key={index}>
              <Td><Bone height="1rem" /></Td>
              <Td><Bone height="1rem" /></Td>
              <Td><Bone height="1rem" /></Td>
              <Td><Bone height="1rem" /></Td>
              <Td><Bone height="1rem" /></Td>
              {!isJeLayout &&
                <>
                  <Td><Bone height="1rem" /></Td>
                  <Td><Bone height="1rem" /></Td>
                </>
              }
            </Tr>
          ))
      }
    </tbody>
  </Table>;
};

export const SalesTable = ({ heading, ...props }) => {
  const { t } = useTranslation();
  const { data } = props;
  const canShowTable = data ? data.length > 0 : true;

  return <>
    {canShowTable &&
      <TableWithHeading heading={heading || t('Sales')}>
        <TableContent {...props} />
      </TableWithHeading>
    }
  </>;
};
