import { useState, useEffect } from 'react';
import { Text } from '@lightspeed/flame/Text';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Divider } from '@lightspeed/flame/Divider';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Bone } from '@lightspeed/flame/Bone';
import { useSelector, useDispatch } from 'react-redux';
import { getTaxesMappingList, getTaxAgency } from '../../api/advancedConfig';
import useLocation from '../../util/useLocation';
import { FieldArray, Form } from 'formik';
import { Autocomplete } from '../fields';
import useConnectionMode from '../../util/useConnectionMode';
import { useIgnoreMapping, useShowTaxError } from '../../util/hooks/useShowTaxError';
import { getCurrentTarget } from '../../util';
import { useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';
import { useMessage } from '../../util/hooks/useMessage';
import { useJeAsCategory } from '../../util/hooks/useJeAsCategory';
import useIsInputsOnlyLayout from '../../util/hooks/useIsInputsOnlyLayout';
import { LabelRequired } from '../custom/LabelRequired';
import { setReload, setSupplierList, setTaxAccounts } from '../../state/globalsReducer';

const DropdownInput = ({ name, options, hasError, loading, fieldName, formik }) => {
  const isLoading = !options || loading;
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const isAgency = name?.split(".")[2] === "agency" ?? false;

  return <>
    {isLoading ?
      <Bone height="2em" /> :
      <Autocomplete
        name={name}
        formik={formik}
        fieldName={fieldName}
        isLoadingOptions={isLoading}
        options={options}
        placeholder={
          t(isInputsOnlyLayout ? 'Enter account' : isAgency ? 'Select tax agency' : 'Select tax account')
        }
        hasError={hasError}
      />
    }
  </>;
};

const TableCell = ({ data, name, options, config, canShowError, loading, formik, ...props }) => {
  const type = config.type || 'label';
  let hasError = false;

  if (canShowError && [null, undefined, ''].includes(data)) {
    hasError = true;
  }

  return <>
    {
      type === 'label' ?
        <Flex alignItems="center" height="100%">
          <Text>{data}</Text>
        </Flex> :
        <DropdownInput name={name} formik={formik} options={options} canShowError={canShowError} hasError={hasError} loading={loading} />
    }
  </>;
};

const MappingRow = ({ data, type, config, name, rowIndex, canShowError, loading, formik, ...props }) => {
  const isLoading = !data;
  const numCells = config.length;
  const width = `${100 / numCells}%`;

  const getKey = (index, fieldName) => {
    return `${name}.${index}.${fieldName}`;
  };

  const content = config.map((columnData, columnIndex) => {
    const { field, options } = columnData;
    const cellName = getKey(rowIndex, field);

    return <Box width={width} key={columnIndex} pl="0.8rem" pr="0.8rem">
      {type === 'header' ?
        <Text fontWeight="bold">{config[columnIndex].name}</Text> :
        <>
          {isLoading ?
            <Bone height="2rem" /> :
            <TableCell
              {...props}
              config={columnData}
              data={data[field]}
              options={options}
              name={cellName}
              canShowError={canShowError}
              loading={loading}
              formik={formik}
            />
          }
        </>
      }
    </Box>;
  });

  return <>
    <CardSection>
      <Flex>
        {content}
      </Flex>
    </CardSection>
    <Divider />
  </>;
};

const TableData = ({ config, formik, name, arrayHelpers, canShowError, loading }) => {
  const rowsData = formik.values[name];
  return <>
    {
      rowsData ?
        <>{
          rowsData.length > 0 ?
            rowsData.map((rowData, index) => {
              return <MappingRow
                data={rowData}
                key={index}
                rowIndex={index}
                name={name}
                config={config}
                fieldName={rowData?.id}
                canShowError={canShowError}
                formik={formik}
                loading={loading}
              />
            })
            :
            <CardSection>
              <Flex justifyContent="center">
                <Text>No rows to show</Text>
              </Flex>
            </CardSection>
        }</>
        :
        <MappingRow config={config} />
    }
  </>;
};

const MapTaxCodesContent = (props) => {
  const {
    config,
    name,
    onSuccessfulSaveSubject,
    setInitialValues,
    formik,
    loading
  } = props;
  // usePersist(formik, 'taxMapping');
  const [updateInitialValuesPending, setUpdateInitialValuesPending] = useState(false);

  // Update initialValues to the current values
  useEffect(() => {
    if (updateInitialValuesPending) {
      setUpdateInitialValuesPending(false);
      setInitialValues(Object.assign({}, formik.values));
    }
  }, [formik.values, setInitialValues, updateInitialValuesPending]);

  // Register subscriptions. This should be run only once to avoid re-subscribing which will throw error
  useEffect(() => {
    onSuccessfulSaveSubject.subscribe(() => {
      setUpdateInitialValuesPending(true);
    });

    return () => {
      onSuccessfulSaveSubject.unsubscribe();
    }
  }, [onSuccessfulSaveSubject]);

  return <Form>
    <Card>
      <MappingRow config={config} type="header" />
      <FieldArray name={name}>
        {
          arrayHelpers =>
            <TableData
              {...props}
              arrayHelpers={arrayHelpers}
            />
        }
      </FieldArray>
    </Card>
  </Form>;
};

export const MapTaxCodes = ({ formik, isTaxAgency }) => {
  const { ignoreFetchedMapping } = useIgnoreMapping();
  const mapping = useSelector((state) => state.globals.config.tax)
  const fetchedMapping = ignoreFetchedMapping ? null : mapping;
  const isJE = useSelector(state => state.forms.entities.dashboard?.values.postingMethod) === 'journalEntry'
  const [, softwareName] = useConnectionMode();
  // const [accountsList, setAccountsList] = useState();
  const currentLocation = useLocation();
  const [initialValues, setInitialValues] = useState({
    taxMapping: null,
  });
  const [isReloadAccounts, setIsReloadAccounts] = useState(false);
  const canShowError = useShowTaxError({ tax: fetchedMapping });
  const target = getCurrentTarget();
  const { t } = useTranslation();
  const [onSuccessfulSaveSubject] = useState(new Subject());
  const { addMessage } = useMessage();
  const [isJeAsCategory] = useJeAsCategory();
  const dispatch = useDispatch();
  const isReload = useSelector(state => state.globals.isReload);
  const { taxAccounts, supplierList, isTaxFetching } = useSelector(state => state.globals)
  const isChangedPostingMethod = useSelector(state => state.forms.meta.isChangedPostingMethod)

  useEffect(() => {
    isReload && setIsReloadAccounts(isReload);
  }, [isReload])

  const config = [
    {
      name: t('LsTaxRates', { target: target.fullName }),
      field: 'taxRate',
    },
    {
      name: <LabelRequired>
        {`${softwareName}`} {t('SwAccounts')}
      </LabelRequired>,
      field: 'account',
      type: 'dropdown',
      options: taxAccounts,
    },
    (isTaxAgency) && {
      name: "Tax agency",
      field: 'agency',
      type: 'dropdown',
      options: supplierList,
    }
  ].filter(Boolean);

  const handleError = (res) => {
    addMessage(res);
  };

  useEffect(() => {
    if (isJE || isChangedPostingMethod) {
      setIsReloadAccounts(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isJE])

  // Refresh accounts list
  useEffect(() => {
    let mounted = true;
    const params = {
      journalEntry: (!isJeAsCategory) && isJE,
    };

    if (isReloadAccounts) {
      dispatch(setTaxAccounts([]));
      getTaxesMappingList(params, currentLocation.id).then(res => {
        if (mounted) {
          if (res.data) {
            const resData = res.data;
            dispatch(setTaxAccounts(resData.accountingSoftware.map(op => {
              return {
                value: op.id,
                label: op.name,
              }
            })));
          } else {
            handleError(res);
          }
        }

        if (isTaxAgency) {
          getTaxAgency(currentLocation.id).then(res => {
            let data = []
            // tax agency option transformation
            for (let i in res.data) {
              if (res.data[i].name !== "") {
                data.push({
                  value: res.data[i].name,
                  label: res.data[i].name
                })
              }
            }

            dispatch(setReload(false))
            dispatch(setSupplierList(data))
          }).catch(err => {
            if (mounted) {
              handleError(err.response);
            }
            dispatch(setReload(false))
          })
        } else {
          dispatch(setReload(false))
        }

      }).catch(err => {
        if (mounted) {
          handleError(err.response);
        }
        dispatch(setReload(false))
      }).then(() => {
        if (mounted) {
          setIsReloadAccounts(false);
        }
      });

      return () => { mounted = false; };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReloadAccounts]);

  return (
    <>
      <MapTaxCodesContent
        formik={formik}
        config={config}
        name="taxMapping"
        canShowError={canShowError}
        onSuccessfulSaveSubject={onSuccessfulSaveSubject}
        setInitialValues={setInitialValues}
        loading={isReload || isReloadAccounts}
      />
    </>
  )
};
