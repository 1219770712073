import { AnnotatedLayout } from '@lightspeed/flame/Layout';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Label } from '@lightspeed/flame/FormField';
import { LabelRequired } from '../custom/LabelRequired';
import { TextInput } from '../fields';
import { useTranslation } from 'react-i18next';

const CustomLabel = ({ label, required, ...props }) => {
  const LabelComp = required === false ? Label : LabelRequired;
  return <LabelComp
    descriptionProps={{ marginTop: '0.375rem' }}
    {...props}
  >
    {label}
  </LabelComp>;
};

const HalfBox = ({ children }) => {
  return <Box width="50%" padding="0.75rem">
    {children}
  </Box>;
};

const FinancialInput = ({
  label,
  isLoading,
  name,
  placeholder,
  required,
}) => {
  return <HalfBox>
    <CustomLabel
      label={label}
      htmlFor={name}
      required={required}
    />
    <TextInput
      isLoading={isLoading}
      id={name}
      name={name}
      placeholder={placeholder}
    />
  </HalfBox>;
};

const FinancialInputsMapping = () => {
  const { t } = useTranslation();

  return <Box>
    <Flex flexWrap="wrap">
      <FinancialInput
        label={t('Tax-Adviser name')}
        name="taxAdvisorName"
        placeholder={t('Enter name')}
      />
      <FinancialInput
        label={t('Tax adviser')}
        name="taxAdviserNumber"
        placeholder={t('Enter number')}
      />
      <FinancialInput
        label={t('Mandator')}
        name="mandatorNumber"
        placeholder={t('Enter number')}
      />
      <FinancialInput
        label={t('Fiscal-Year begin')}
        name="fiscalYearBegin"
        placeholder={t('Enter date')}
      />
      <FinancialInput
        label={t('Interim account')}
        name="interimAccount"
        placeholder={t('Enter value')}
        required={false}
      />
      <FinancialInput
        label={t('Length of Ledger account')}
        name="lengthOfLedger"
        placeholder={t('Enter length')}
      />
    </Flex>
  </Box>
};

const FinancialSectionContent = (props) => {
  return <Card ml="1rem">
    <CardSection>
      <FinancialInputsMapping {...props} />
    </CardSection>
  </Card>;
};

export const FinancialSection = (props) => {
  const { t } = useTranslation();

  return <AnnotatedLayout
    title={t('Financial')}
    mt="2.25rem"
    mb="2.25rem"
  >
    <FinancialSectionContent {...props} />
  </AnnotatedLayout>;
};
