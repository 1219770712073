import { getCurrentTarget } from './index';

export const getKey = (key) => {
  const target = getCurrentTarget();
  return `LS_APP_${target.name}_${key}`;
};

export const setItem = (key, value) => {
  return sessionStorage.setItem(getKey(key), value);
};

export const getItem = (key) => {
  return sessionStorage.getItem(getKey(key));
};
