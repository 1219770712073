import { PageLayout } from '../pageLayout/PageLayout';
import { Flex, Box } from '@lightspeed/flame/Core';
import { Text, Heading3, TextLink } from '@lightspeed/flame/Text';
import { Button } from '@lightspeed/flame/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setLocation } from '../../state/locationReducer';
import { resetPortalInfo } from '../../state/portalInfoReducer';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { IconVerified } from '@lightspeed/flame/Icon/Verified';
import { Modal, ModalBody, ModalHeader, ModalFooter } from '@lightspeed/flame/Modal';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getConnectionModeName } from '../../util';
import useLocale from '../../util/hooks/useLocale';
import { Bone } from '@lightspeed/flame/Bone';
import { validateSwConnection, copyAccountData } from '../../api/selectSoftware';
import { useMessage } from '../../util/hooks/useMessage';
import { IconDanger } from '@lightspeed/flame/Icon/Danger';
import { IconWarning } from '@lightspeed/flame/Icon/Warning';
import { Freshchat } from 'reactjs-freshchat';
import 'reactjs-freshchat/dist/index.css';

const getLocalSyncDate = (locale, actionDate) => {
  const dateObj = new Date(actionDate);
  const dateText = new Intl.DateTimeFormat(locale, { dateStyle: 'long' }).format(dateObj);
  const timeText = new Intl.DateTimeFormat(locale, { timeStyle: 'short' }).format(dateObj);

  return `${dateText}, ${timeText}`;
};

const getAccountsData = (locations, companiesData) => {
  const accountsData = {};

  for (let locationData of locations) {
    const { connectionMode, id } = locationData;
    if (connectionMode && companiesData[id] && companiesData[id].isConnected) {
      if (!accountsData[connectionMode]) {
        accountsData[connectionMode] = [];
      }
      accountsData[connectionMode].push({
        id,
        companyName: companiesData[id].companyName,
      });
    }
  }

  return [accountsData, Object.keys(accountsData).length > 0];
};

const SoftwareAccountsList = ({ connectionMode, accountsData, beginCopyLocation }) => {
  const { t } = useTranslation();
  const swName = getConnectionModeName(t, connectionMode);

  return <Flex flexDirection="column" padding="0.75rem" width="50%">
    <Text fontWeight="bold" fontSize="text-s">
      {t('Sw existing accounts', { swName })}
    </Text>
    {
      accountsData.map((accountData, index) => {
        return <TextLink
          fontWeight="bold"
          fontSize="text-s"
          mt="0.375rem"
          key={index}
          onClick={() => beginCopyLocation(accountData.id)}
        >
          {swName} {accountData.companyName}
        </TextLink>;
      })
    }
  </Flex>
};

const SelectExistingAccount = ({ accountsData, closeModal, beginSetup, beginCopyLocation }) => {
  const { t } = useTranslation();

  return <Modal
    isOpen={true}
    onRequestClose={closeModal}
  >
    <ModalHeader showCloseButton={false}>
      <Heading3>{t('Setup with existing account')}</Heading3>
    </ModalHeader>
    <ModalBody scroll={true}
      style={{ padding: '0.75rem', maxWidth: '600px', width: '600px' }}
    >
      <Box padding="0.75rem">
        {t('Copy config desc')}
      </Box>
      <Flex flexWrap="wrap">
        {
          Object.keys(accountsData).map((connectionMode, index) => {
            return <SoftwareAccountsList
              key={index}
              connectionMode={connectionMode}
              accountsData={accountsData[connectionMode]}
              beginCopyLocation={beginCopyLocation}
            />;
          })
        }
      </Flex>
    </ModalBody>
    <ModalFooter>
      <Flex width="100%" justifyContent="flex-end">
        <Button
          onClick={closeModal}
          mr="0.75rem"
        >Cancel</Button>
        <Button
          variant="secondary" fill="true"
          onClick={beginSetup}
        >Setup another account</Button>
      </Flex>
    </ModalFooter>
  </Modal>;
};

const LocationBox = ({
  data: locationData,
  handleSelection,
  isInAccountList,
  addToAccountList,
  copyLocationData,
  setCopyLocationData,
  handleConfirmSelection,
  isLoadingStatuses,
}) => {
  const locale = useLocale();
  const { t } = useTranslation();
  const { isConfigured, syncEnabled } = locationData;
  const [companyName, setCompanyName] = useState(null);
  const [isConnected, setIsConnected] = useState(null);
  const isCopyingLocation = ['ready', 'loading'].includes(copyLocationData.status);
  const { addMessage } = useMessage();
  const isPaused = syncEnabled === false;

  // Check that software is connected
  useEffect(() => {
    let mounted = true;
    validateSwConnection(locationData.id).then(res => {
      if (mounted) {
        if (res && res?.data?.companyName) {
          setCompanyName(res?.data?.companyName);
        }
        setIsConnected(true);
      }
    }).catch(() => {
      if (mounted) {
        setIsConnected(false);
      }
    });

    return () => { mounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ([true, false].includes(isConnected) && (isConnected !== isInAccountList)) {
      addToAccountList(locationData.id, isConnected, companyName);
    }
  }, [addToAccountList, companyName, locationData.id, isConnected, isInAccountList]);

  useEffect(() => {
    let mounted = true;

    if (isCopyingLocation &&
      (copyLocationData.to === locationData.id)) {
      const payload = {
        connectionMode: copyLocationData.connectionMode,
        locationFrom: copyLocationData.from,
      }

      const handleError = (res) => {
        setCopyLocationData({
          status: 'idle',
          from: 0,
          to: 0,
          connectionMode: '',
        });
        addMessage(res, { default: t('Communication failure') });
      };

      copyAccountData(payload, copyLocationData.to).then(res => {
        if (mounted) {
          handleConfirmSelection(copyLocationData.to, true);
        } else {
          handleError();
        }
      }).catch(err => {
        if (mounted) {
          handleError(err && err.response);
        }
      });
    }

    return () => { mounted = false; };
  }, [addMessage, copyLocationData, handleConfirmSelection, isCopyingLocation, locationData.id, setCopyLocationData, t]);

  return <Card mb="1.625rem">
    <CardSection>
      <Flex justifyContent="space-between" padding="0.75rem" alignItems="center">
        <Box>
          <Text fontWeight="bold">{locationData.name}</Text>
          <Text fontSize="text-s" color="gray-600" mt="0.375rem">
            {locationData.address}
          </Text>
          {(isConnected !== false) &&
            <Box mb="0.25rem" mt="1.5rem">
              <Flex alignItems="flex-end">
                {locationData.connectionMode &&
                  <>
                    {isConnected ?
                      <Text fontWeight="bold" mr="0.5rem">
                        {getConnectionModeName(t, locationData.connectionMode)}
                      </Text> :
                      <Bone height="1rem" width="7rem" style={{ marginRight: '1rem' }} />
                    }
                  </>
                }
                {isConnected ?
                  <>
                    {companyName &&
                      <Text fontSize="text-s" color="gray-600">
                        {companyName}
                      </Text>
                    }
                  </>
                  :
                  <Bone height="1rem" width="10rem" />
                }
              </Flex>
              {[true, false].includes(isConnected) ?
                <>
                  {isConnected &&
                    <Flex mt="0.375rem" alignItems="center">
                      {isConfigured ?
                        <>{isPaused ?
                          <IconWarning color="orange-500" />
                          :
                          <IconVerified color="green-700" />
                        }</> :
                        <IconDanger color="maple-700" />
                      }
                      <Text ml="0.75rem">
                        {isConfigured ?
                          <>{isPaused ?
                            <>{t('SyncPausedInfo')}</>
                            :
                            <>
                              {locationData.syncDateTime &&
                                <>
                                  Next sync on {getLocalSyncDate(locale, locationData.syncDateTime)}
                                </>
                              }
                            </>
                          }</>
                          :
                          t('UpdateMapping')
                        }
                      </Text>
                    </Flex>
                  }
                </>
                :
                <Flex mt="0.375rem" alignItems="center">
                  <Bone height="1rem" width="15rem" />
                </Flex>
              }
            </Box>
          }
        </Box>
        <Box>
          {
            ((isConnected === null) || isLoadingStatuses) ?
              <Bone height="2rem" width="8rem" /> :
              <Button
                variant={isConnected ? 'neutral' : 'secondary'} fill={true}
                onClick={() => handleSelection(locationData)}
                loading={
                  isCopyingLocation &&
                  (copyLocationData.to === locationData.id)
                }
                disabled={
                  isCopyingLocation &&
                  (copyLocationData.to !== locationData.id)
                }
              >
                {isConnected ?
                  'Edit mapping' :
                  'Setup Accounting'
                }
              </Button>
          }
        </Box>
      </Flex>
    </CardSection>
  </Card>
};

const LoadingListScreen = () => {
  return <>
    {[1, 2, 3].map(id =>
      <Card mb="1.625rem" key={id}>
        <CardSection>
          <Flex justifyContent="space-between" padding="0.75rem" alignItems="center">
            <Box>
              <Bone height="1rem" width="10rem" />
              <Bone height="1rem" width="13rem" style={{ marginTop: '0.375rem' }} />
              <Box mb="0.25rem" mt="1.5rem">
                <Flex alignItems="flex-end">
                  <Bone height="1rem" width="7rem" style={{ marginRight: '1rem' }} />
                  <Bone height="1rem" width="10rem" />
                </Flex>
                <Flex mt="0.375rem" alignItems="center">
                  <Bone height="1rem" width="15rem" />
                </Flex>
              </Box>
            </Box>
            <Bone height="2rem" width="8rem" />
          </Flex>
        </CardSection>
      </Card>
    )}
  </>;
};

const LocationForm = (props) => {
  const dispatch = useDispatch();
  const [locations, setLocations] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [companiesData, setCompaniesData] = useState({});
  const [copyLocationData, setCopyLocationData] = useState({
    status: 'idle',
    from: 0,
    to: 0,
    connectionMode: '',
  });
  const isLoadingStatuses = locations ? locations.length !== Object.keys(companiesData).length : true;
  const [accountsData, canCopyLocations] = isLoadingStatuses ? [] : getAccountsData(locations, companiesData);

  const { list } = useSelector(state => state.location)

  // setting location list directly, without any further api call again
  useEffect(() => {
    if (list?.length > 0) {
      setLocations(list)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLocationSelection = (locationData) => {
    if (companiesData[locationData.id].isConnected || (!canCopyLocations)) {
      handleConfirmSelection(locationData.id);
    } else {
      setSelectedLocation(locationData);
    }
  };

  const handleConfirmSelection = (locationId, hasCopied) => {
    const locationData = locations.find(opData => opData.id === locationId);

    dispatch(resetPortalInfo());
    dispatch(setLocation(locationData));
    if (hasCopied || companiesData[locationData.id].isConnected) {
      props.navigate('../dashboard');
    } else {
      props.navigate('../selectsw');
    }
  };

  const setCompanyData = (id, isConnected, companyName) => {
    setCompaniesData(Object.assign({}, companiesData, {
      [id]: {
        companyName,
        isConnected: isConnected,
      },
    }));
  };

  const handleCopyLocation = (sourceId) => {
    const locationData = locations.find(opData => opData.id === sourceId);

    setCopyLocationData({
      status: 'ready',
      connectionMode: locationData.connectionMode,
      from: sourceId,
      to: selectedLocation.id,
    });

    setSelectedLocation(null);
  };

  return <Box>
    {selectedLocation &&
      <SelectExistingAccount
        accountsData={accountsData}
        closeModal={() => { setSelectedLocation(null) }}
        beginSetup={() => { handleConfirmSelection(selectedLocation.id) }}
        beginCopyLocation={handleCopyLocation}
      />
    }
    {locations ?
      locations.map(opData => {
        return <LocationBox
          key={opData.id} data={opData}
          handleSelection={handleLocationSelection}
          setCompanyData={setCompanyData}
          copyLocationData={copyLocationData}
          setCopyLocationData={setCopyLocationData}
          isInAccountList={companiesData[opData.id] && companiesData[opData.id].isConnected}
          addToAccountList={setCompanyData}
          handleConfirmSelection={handleConfirmSelection}
          isLoadingStatuses={isLoadingStatuses}
        />;
      })
      :
      <LoadingListScreen />
    }
  </Box>;
};

export const SelectLocation = (props) => {
  return (
    <PageLayout>
      <LocationForm {...props} />
      <Freshchat
        token={'00b6ddea-bee5-4a57-b767-e51cb812490d'}
      />
    </PageLayout>
  );
};
