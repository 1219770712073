import { useEffect, useState } from 'react';
import { TextLink, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { AlertInCard } from '@lightspeed/flame/Alert';
import useAppConnected from '../../util/hooks/useAppConnected';
import useAppSyncing from '../../util/hooks/useAppSyncing';
import { useDispatch, useSelector } from 'react-redux';
import { IconCalendar } from '@lightspeed/flame/Icon/Calendar';
import { useTranslation } from 'react-i18next';
import useLocale from '../../util/hooks/useLocale';
import { Divider } from '@lightspeed/flame/Divider';
import { Bone } from '@lightspeed/flame/Bone';
import { BiRefresh } from 'react-icons/bi';
import { setRefetchSyncCount } from '../../state/globalsReducer';

const SyncStatusBox = ({ alertType, title, info, showLinks, navigate, loading }) => {
  const { t } = useTranslation();

  const handleClick = (type) => {

    if (type === "sales") {
      navigate('../sync-logs?isTypePo=false');
    } else {
      navigate('../sync-logs?isTypePo=true');
    }

  };

  return <Box width="50%" padding="0.75rem" >
    <AlertInCard
      height="100%"
      style={{ alignItems: 'flex-start' }}
      type={alertType}
      noCloseBtn
    >
      {loading ? <>
        <Flex flexDirection={"column"}>
          <Bone height={"1rem"} width={"200px"} />
          <Flex mt=".7rem" mb="1rem">
            <Bone height={".6rem"} width={"150px"} />
          </Flex>
        </Flex>
      </> :
        <>
          <Text
            color="textHeading"
            fontWeight="bold"
            fontSize="text"
            mt={0} mr={0} mb={1} ml={0}
          >
            {title}
          </Text>
          <Text mb="1.125rem" color="gray-600">{info}</Text>
        </>
      }
      {showLinks &&
        <>
          <Box>
            <TextLink fontWeight="bold" onClick={() => handleClick("sales")}>
              <IconCalendar color="blue-500" style={{ marginRight: "0.75rem" }} /> {t('Sync history')}
            </TextLink>
          </Box>
          <Box mt=".8rem" mb=".8rem">
            <TextLink fontWeight="bold" onClick={() => handleClick("po")}>
              <IconCalendar color="blue-500" style={{ marginRight: "0.75rem" }} /> {t('PO Sync history')}
            </TextLink>
          </Box>
        </>
      }
    </AlertInCard>
  </Box>;
};

const toDateTimeString = (locale, timeStamp) => {
  let result;

  if (timeStamp) {
    const dateFormatter = new Intl.DateTimeFormat(locale, { dateStyle: 'long' });
    const timeFormatter = new Intl.DateTimeFormat(locale, { timeStyle: 'short' });
    const nextSyncOnDate = timeStamp ? new Date(timeStamp) : '';
    result = nextSyncOnDate && `${dateFormatter.format(nextSyncOnDate)}, ${timeFormatter.format(nextSyncOnDate)}`;
  }

  return result;
};

const Configured = ({ swName, navigate, fetchedConfig, ...props }) => {
  const locale = useLocale();
  const data = useSelector(state => state.portalInfo.successAndFailCounts) ?? {};
  const successCounts = (data?.salesTransactionsSyncedWeekly ?? 0) + (data?.purchaseOrderTransactionsSyncedWeekly ?? 0);
  const failedCounts = (data?.saleTransactionsFailedToSync ?? 0) + (data?.purchaseOrderTransactionsFailedToSync ?? 0);
  const nextSyncOn = toDateTimeString(locale, data.nextSyncDateTime);
  const firstSyncOn = toDateTimeString(locale, data.firstSyncDateTime);
  const hasErrors = failedCounts > 0;
  const { t } = useTranslation();
  const errMsg = useSelector(state => state.globals.hasAnyErrorMsg)
  const dispatch = useDispatch()
  const { refreshSyncCount } = useSelector(state => state.globals)

  const getGenericTitle = () => {
    return hasErrors ? t('Sync error') : t('Sync enabled');
  };

  return <SyncStatusBox
    alertType={hasErrors ? 'warning' : 'success'}
    title={
      <>
        <Flex>
          {nextSyncOn ?
            t('NextSyncOn', { syncTime: nextSyncOn || '' })
            :
            getGenericTitle()}
          <Flex mb="5px" pl="5px">{
            !refreshSyncCount &&
            <BiRefresh size={"22"} onClick={() => { dispatch(setRefetchSyncCount(true)) }} />
          }
          </Flex>
        </Flex>
      </>
    }
    info={hasErrors ?
      <>
        {t('SyncErrorDesc', {
          count: failedCounts,
          swName
        })}
        {errMsg !== "" && <>
          <br />
          <Divider mt=".5rem" mb={".5rem"} />
          {errMsg}
        </>}
      </>
      :
      <>
        {
          t('SyncSuccessDesc', {
            count: successCounts,
            swName,
            syncTime: firstSyncOn || ''
          })
        }
        {errMsg !== "" && <>
          <br />
          <Divider mt=".5rem" mb={".5rem"} />
          {errMsg}
        </>}
      </>
    }
    showLinks={true}
    navigate={navigate}
    fetchedConfig={fetchedConfig}
    {...props}
  />;
};

const Disconnected = ({ swName, isAppSyncing, navigate, ...props }) => {
  const { t } = useTranslation();
  const errMsg = useSelector(state => state.globals.hasAnyErrorMsg)
  const dispatch = useDispatch()
  const { refreshSyncCount } = useSelector(state => state.globals)

  return <SyncStatusBox
    alertType="danger"
    title={
      <>
        <Flex>
          {
            isAppSyncing === false ?
              t('Sync error') :
              t('ConnectionLostDesc', { swName })
          }
          <Flex mb="5px" pl="5px">{
            !refreshSyncCount &&
            <BiRefresh size={"22"} onClick={() => { dispatch(setRefetchSyncCount(true)) }} />
          }
          </Flex>
        </Flex>
      </>
    }
    info={isAppSyncing === false ?
      <>
        {t('UpdateMapping')}
        {errMsg !== "" && <>
          <br />
          <Divider mt=".5rem" mb={".5rem"} />
          {errMsg}
        </>}
      </> :
      t('Reconnect to resume syncing')
    }
    showLinks={isAppSyncing === false}
    navigate={navigate}
    {...props}
  />;
};

const Paused = ({ navigate, ...props }) => {
  const { t } = useTranslation();
  const errMsg = useSelector(state => state.globals.hasAnyErrorMsg)
  const dispatch = useDispatch()
  const { refreshSyncCount } = useSelector(state => state.globals)

  return <SyncStatusBox
    alertType="warning"
    title={
    <>
        <Flex>
          {
            t('Sync paused')
          }
          <Flex mb="5px" pl="5px">{
            !refreshSyncCount &&
            <BiRefresh size={"22"} onClick={() => { dispatch(setRefetchSyncCount(true)) }} />
          }
          </Flex>
        </Flex>
      </>
    }
    info={
      <>
        {t('SyncPausedInfo')}
        {errMsg !== "" && <>
          <br />
          <Divider mt=".5rem" mb={".5rem"} />
          {errMsg}
        </>}
      </>
    }
    showLinks={true}
    navigate={navigate}
    {...props}
  />;
};

const SyncStatus = ({ isConnected, isAppSyncing, ...props }) => {
  const { fetchedConfig } = props;
  const [isPaused, setIsPaused] = useState(false);
  const savedForms = useSelector(state => state.forms);
  const lastConfigSource = savedForms.meta.lastSave.source;
  const syncRange = savedForms.meta.lastSave.entities?.dashboard?.values?.syncRange;

  useEffect(() => {
    let newValue;
    if (lastConfigSource === 'save') {
      newValue = syncRange ? syncRange === '0' : false;
    } else {
      newValue = fetchedConfig && fetchedConfig?.syncEnabled === 0 ? true : false;
    }

    if ([true, false].includes(newValue) && (newValue !== isPaused)) {
      setIsPaused(newValue);
    }
  }, [fetchedConfig, isPaused, lastConfigSource, syncRange]);

  return <>
    {(isConnected && isAppSyncing) ?
      <>
        {isPaused ?
          <Paused {...props} /> :
          <Configured {...props} />
        }
      </> :
      <Disconnected isAppSyncing={isAppSyncing} {...props} />
    }
  </>;
};

const SyncStatusWrapper = ({ isSyncStatusLoading, softwareName, ...props }) => {
  const [isConnected] = useAppConnected();
  const [isAppSyncing] = useAppSyncing();
  const { refreshSyncCount } = useSelector(state => state.globals)
  const isLoading = props.isLoading || isSyncStatusLoading || (isConnected === null) || refreshSyncCount;
  const { t } = useTranslation();

  return <>
    {
      <SyncStatus
        isConnected={isConnected}
        isAppSyncing={isAppSyncing}
        loading={isLoading}
        swName={softwareName || t('Accounting Software')}
        navigate={props.navigate}
        fetchedConfig={props.fetchedConfig}
      />
    }
  </>;
};

export { SyncStatusWrapper as SyncStatus };