import React, { useState, useEffect, useRef } from 'react';
import { TextLink } from '@lightspeed/flame/Text';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Spinner } from '@lightspeed/flame/Spinner'
import useLocation from '../../util/useLocation';
import { Button } from '@lightspeed/flame/Button';
import { getCurrentTarget } from '../../util';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../../util/hooks/useMessage';
import { Box } from '@lightspeed/flame/Core';
import { getAccounts } from '../../api/advancedConfig';
import { Input } from '@lightspeed/flame/Input';
import DataTable from 'react-data-table-component';

import "./AccountsTable.css";

const AccountsList = (props) => {

    const {
        navigate
    } = props;

    const currentLocation = useLocation();
    const { addMessage } = useMessage();
    const { t } = useTranslation();
    const inputRef = useRef(null)

    const [tableData, setTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [loading, setLoading] = useState(true);

    // Get Data on initial mount
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // on user search, filter the result!
    useEffect(() => {
        if (searchInput !== "") {
            const result = tableData?.filter((data) => {
                return Object.values(data)?.join('')?.toLowerCase()?.toString()?.includes(searchInput?.toLowerCase())
            })
            setFilteredData(result);
        } else {
            setFilteredData(tableData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput])

    /**
     * 
     *  @function getData
     * 
     *  @param null
     * 
     *  @returns null
     * 
     *  Get Accounts Data
     * 
     */
    const getData = () => {
        setLoading(true)
        inputRef.current.value = "";
        getAccounts(currentLocation.id)
            .then(res => {
                if (res.data) {
                    setTableData(res.data);
                    setFilteredData(res.data);
                }
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                addMessage(err, { default: t('Fetch failure') });
            });
    }

    /** 
     * 
     * @function gotoDashboad
     * 
     * @props null
     * 
     * @returns null
     * 
     * 
     *   Redirect to Main Dashboard
     * 
     */
    const gotoDashboard = () => {
        navigate('../dashboard')
    }

    /**
     * 
     * @function getAccountsTable
     * 
     * @param null
     * 
     * @returns <Table />
     * 
     *   Create Table Component
     * 
     */
    const getAccountsTable = () => {

        const columns = [
            {
                name: "Account Number",
                selector: row => row.id ?? ""
            },
            {
                name: "Account Name",
                selector: row => row.name ?? "",
                sortable: true,
            },
            {
                name: "Account Type",
                selector: row => row.type ?? "",
                sortable: true,
            },
        ]

        return (
            <>
                {
                    !loading ?
                        <DataTable
                            pagination
                            columns={columns}
                            data={filteredData ?? []}                   
                        />
                        :
                        <div className='spinner'>
                            <Spinner loading={loading} />
                        </div>
                }
            </>
        )
    }
    
    /**
     * 
     * @function getHeader
     * 
     * @param null
     * 
     * @returns <Header /> contains refresh btn and search input
     * 
     * 
     *   Refresh btn for refetch accounts data
     *   
     *   Search input
     */
    const getHeader = () => {
        return (
            <div className="header">
                <Button
                    loading={loading}
                    onClick={getData}
                    variant="secondary"
                    fill={true}
                >{t('Refresh')}</Button>
                <div className='input-search'>
                    <Input
                        icon='search'
                        placeholder='Search...'
                        onChange={(e) => setSearchInput(e.target.value)}
                        ref={inputRef}
                    />
                </div>
            </div>
        )
    }

    return (
        <>
            <Box mt="5.3rem" ml="1.1rem">
                <Box mb="1.5rem" mt="1.5rem" >
                    <TextLink variant="secondary" onClick={gotoDashboard}>{t(`< ${getCurrentTarget()?.fullName} Accounting`)}</TextLink>
                </Box>
                <>
                    <Card className='card-box'>
                        <CardSection className='card-section'>
                            {getHeader()}
                        </CardSection>
                        <CardSection className='card-section'>
                            {getAccountsTable()}
                        </CardSection>
                    </Card>
                </>
            </Box>
        </>
    )
}

export default AccountsList;