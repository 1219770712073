import { TextLink, Text, Heading3 } from '@lightspeed/flame/Text';
import { Box } from '@lightspeed/flame/Core';
import useAppSubmitting from '../../util/useAppSubmitting';
import { AlertInCard } from '@lightspeed/flame/Alert';
import { useShowTaxError } from '../../util/hooks/useShowTaxError';
import useConnectionMode from '../../util/useConnectionMode';
import { useTranslation } from 'react-i18next';

export const MapPurchaseTax = ({ fetchedConfig, ...props }) => {
  const [isAppSubmitting] = useAppSubmitting();
  const showError = useShowTaxError(fetchedConfig, true);
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();

  const gotoTaxMapping = () => {
    if (!isAppSubmitting) {
      props.navigate('../map-purchase-tax', { state: { fetchedMapping: fetchedConfig && fetchedConfig.poTaxes } });
    }
  };

  return <Box>
    <Heading3 mb="0.375rem">{t('Purchase tax rates')}</Heading3>
    <Text color="gray-600" mb="1.125rem" size="small">
      {t('Purchase tax desc', { swName })}
    </Text>
    <TextLink size="small" fontWeight="bold" onClick={gotoTaxMapping}>
      {t('Map purchase  tax rates')}
    </TextLink>
    {showError &&
      <Box width="49%" pr="1.1rem" pt="1.5rem">
        <AlertInCard
          type="danger"
          width="auto"
          noCloseBtn
        >
          {t('TaxRateError')}
        </AlertInCard>
      </Box>
    }
  </Box>;
};