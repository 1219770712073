import {
  getSubmitConfigUrl,
} from './urls';
import axios from 'axios';
import { getHeaders } from '../util/api';

export const sendConfigData = (payload, locationId, isUpdating = false) => {
  let method = axios.post;
  if (isUpdating) {
    method = axios.put;
  };

  return method(getSubmitConfigUrl(), payload, {
    headers: getHeaders(locationId),
  });
};