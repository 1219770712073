import { useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from '@lightspeed/flame/Modal';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Button } from '@lightspeed/flame/Button';
import { useDispatch } from 'react-redux';
import useLocation from '../../util/useLocation';
import { createAccount } from '../../api/dashboard';
import { useToasts } from '@lightspeed/flame/Toaster';
import { Heading3 } from '@lightspeed/flame/Text';
import { TextInput } from '../fields';
import { LabelRequired } from '../custom/LabelRequired';
import { setAccountsList } from '../../state/accountsReducer';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useConnectionMode from '../../util/useConnectionMode';
import { useTranslation } from 'react-i18next';

const CreateAccountForm = ({ formik, handleClose }) => {
  const { t } = useTranslation();

  return <>
    <ModalBody>
      <Box maxWidth="600px" width="600px">
        <Box>
          <LabelRequired htmlFor="accountName"
            mb="0.375rem"
          >
            {t('Account name')}
          </LabelRequired>
          <TextInput
            disabled={formik.isSubmitting}
            name="accountName"
            id="accountName" placeholder={t('Name')}
          />
        </Box>
        <Box mt="1.5rem">
          <LabelRequired htmlFor="accountNumber"
            mb="0.375rem"
          >
            {t('Number')}
          </LabelRequired>
          <TextInput
            disabled={formik.isSubmitting}
            name="accountNumber"
            id="accountNumber" placeholder={t('Number')}
          />
        </Box>
      </Box>
    </ModalBody>
    <ModalFooter>
      <Flex width="100%" justifyContent="flex-end">
        <Button
          onClick={handleClose} mr={2}
          disabled={formik.isSubmitting}
        >{t('Cancel')}</Button>
        <Button variant="secondary" fill="true"
          loading={formik.isSubmitting}
          type="submit"
        >{t('Create')}</Button>
      </Flex>
    </ModalFooter>
  </>;
};

const getValidationSchema = (connectionMode, t) => {
  const swToMaxLength = {
    'Xero': 10,
    'Online': 7,
    'Sage One': 8,
    'Sage50': 8,
    'Sage200': 8,
  };
  const maxLength = swToMaxLength[connectionMode] || 10;
  const largestAccount = parseInt('9'.repeat(maxLength), 10);

  const schemaObject = {
    accountName: Yup.string()
      .required(t('Required')),
    accountNumber: Yup.number()
      .typeError(t('DigitsOnly'))
      .max(largestAccount, t('MaxDigits', { maxLength }))
      .required(t('Required')),
  };

  return Yup.object(schemaObject);
};

export const CreateAccount = ({ closeModal, ...props }) => {
  const { accountType, accountName } = props;
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const currentLocation = useLocation();
  const [canClose, setCanClose] = useState(true);
  const [connectionMode, swName] = useConnectionMode();
  const { t } = useTranslation();

  const handleError = (res) => {
    let msg = t('Communication failure');
    if (res && res.status === 404) {
      msg = typeof res.data === 'string' ? res.data : msg;
    }
    addToast(msg, { appearance: 'error' });
  };

  const handleClose = () => {
    if (canClose) {
      closeModal();
    }
  };

  const onSubmit = (values, { setSubmitting }) => {
    setCanClose(false);
    const payload = Object.assign({}, values);
    createAccount(payload, currentLocation.id).then(res => {
      if (res.data && res.data.length > 0) {
        // Update account list
        dispatch(setAccountsList(res.data));
        addToast(t('Account added successfully'), { appearance: 'success' });
        closeModal();
      } else {
        setSubmitting(false);
        setCanClose(true);
        handleError();
      }
    }).catch(err => {
      setSubmitting(false);
      setCanClose(true);
      handleError(err.response);
    });
  };

  return <Modal
    isOpen={true}
    shouldCloseOnOverlayClick={false}
    onRequestClose={handleClose}
  >
    <ModalHeader>
      <Heading3>{t('CreateAccountHeading', { swName })}</Heading3>
    </ModalHeader>
    <Formik
      initialValues={{
        accountType,
        accountName,
        accountNumber: '',
      }}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(connectionMode, t)}
    >
      {formik =>
        <Form>
          <CreateAccountForm formik={formik} handleClose={handleClose} {...props} />
        </Form>
      }
    </Formik>
  </Modal>;
};
