import { useState, useEffect } from 'react';
import { Text } from '@lightspeed/flame/Text';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Divider } from '@lightspeed/flame/Divider';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Bone } from '@lightspeed/flame/Bone';
import { useSelector, useDispatch } from 'react-redux';
import useLocation from '../../util/useLocation';
import { FieldArray, Formik, Form } from 'formik';
import { Autocomplete } from '../fields';
import { usePersist } from '../../util/usePersist';
import { useShowTaxError, useIgnoreMapping } from '../../util/hooks/useShowTaxError';
import { useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';
import { useMessage } from '../../util/hooks/useMessage';
import * as Yup from 'yup';
import { getLocationsList, getClassesList } from '../../api/advancedConfig';
import { setClasses, setLocations } from '../../state/globalsReducer';
import { useConnectionMode } from '../../util/useConnectionMode';

const DropdownInput = ({ name, options, fieldName, hasError, formik, isLocation }) => {
  const isLoading = !options;
  const { t } = useTranslation();
  let disableClass = false, disableLocation = false;
  const isQbo = useConnectionMode()[0] === "Online"

  const mapping = formik?.values?.floorMapping

  if (mapping?.length !== 0) {
    for (let i = 0; i < mapping?.length; i++) {
      if (mapping[i]?.location !== '') {
        disableClass = true;
      } else if (mapping[i]?.account !== '') {
        disableLocation = true;
      }
    }
  }

  if (!isQbo) {
    disableClass = disableLocation = false;
  }
  
  return <>
    {isLoading ?
      <Bone height="2em" /> :
      isLocation ?
      <Autocomplete
        name={name}
        isLoadingOptions={isLoading}
        options={options}
        fieldName={fieldName}
        disabled={disableLocation}
        placeholder={t('Select a location')}
        hasError={hasError}
        formik={formik}
      /> :
      <Autocomplete
        name={name}
        isLoadingOptions={isLoading}
        options={options}
        fieldName={fieldName}
        disabled={disableClass}
        placeholder={t('Select a class')}
        hasError={hasError}
        formik={formik}
      />
    }
  </>;
};

const TableCell = ({ data, name, options, config, fieldName, canShowError, formik, ...props }) => {
  const type = config.type || 'label';
  let hasError = false;

  if (canShowError && [null, undefined, ''].includes(data)) {
    hasError = true;
  }

  return <>
    {
      type === 'label' ?
        <Flex alignItems="center" height="100%">
          <Text>{data}</Text>
        </Flex> :
        <DropdownInput formik={formik} name={name} fieldName={fieldName} options={options} canShowError={canShowError} hasError={hasError}
          {...props}
        />
    }
  </>;
};

const MappingRow = ({ data, type, config, name, rowIndex, fieldName, canShowError, formik }) => {
  const isLoading = !data;
  const numCells = config.length;
  const width = `${100 / numCells}%`;

  const getKey = (index, fieldName) => {
    return `${name}.${index}.${fieldName}`;
  };

  const content = config.map((columnData, columnIndex) => {
    const { field, options } = columnData;
    const cellName = getKey(rowIndex, field);

    return <Box width={width} key={columnIndex} pl="0.8rem" pr="0.8rem">
      {type === 'header' ?
        <Text fontWeight="bold">{config[columnIndex].name}</Text> :
        <>
          {isLoading ?
            <Bone height="2rem" /> :
            <TableCell
              config={columnData}
              data={data[field]}
              options={options}
              name={cellName}
              isLocation={columnData?.alias === "location"}
              formik={formik}
              canShowError={canShowError}
              fieldName={fieldName}
            />
          }
        </>
      }
    </Box>;
  });

  return <>
    <CardSection>
      <Flex>
        {content}
      </Flex>
    </CardSection>
    <Divider />
  </>;
};

const TableData = ({ config, formik, name, arrayHelpers, canShowError }) => {
  const rowsData = formik.values[name];

  return <>
    {
      rowsData ?
        <>{
          rowsData.length > 0 ?
            rowsData.map((rowData, index) => {
              return <MappingRow
                data={rowData}
                key={index}
                rowIndex={index}
                fieldName={rowData?.id}
                name={name}
                config={config}
                canShowError={canShowError}
                formik={formik}
              />
            })
            :
            <CardSection>
              <Flex justifyContent="center">
                <Text>No rows to show</Text>
              </Flex>
            </CardSection>
        }</>
        :
        <MappingRow config={config} />
    }
  </>;
};

const MapFloorCodesContent = (props) => {
  const {
    config,
    name,
    onSuccessfulSaveSubject,
    setInitialValues,
    formik,
  } = props;
  usePersist(formik, 'floorMapping');
  const [updateInitialValuesPending, setUpdateInitialValuesPending] = useState(false);

  // Update initialValues to the current values
  useEffect(() => {
    if (updateInitialValuesPending) {
      setUpdateInitialValuesPending(false);
      setInitialValues(Object.assign({}, formik.values));
    }
  }, [formik.values, setInitialValues, updateInitialValuesPending]);

  // Register subscriptions. This should be run only once to avoid re-subscribing which will throw error
  useEffect(() => {
    onSuccessfulSaveSubject.subscribe(() => {
      setUpdateInitialValuesPending(true);
    });

    return () => {
      onSuccessfulSaveSubject.unsubscribe();
    }
  }, [onSuccessfulSaveSubject]);

  return <Form>
    <Card ml="1rem">
      <MappingRow config={config} type="header" />
      <FieldArray name={name}>
        {
          arrayHelpers =>
            <TableData
              {...props}
              arrayHelpers={arrayHelpers}
            />
        }
      </FieldArray>
    </Card>
  </Form>;
};
const getValidationSchema = () => {
  return Yup.object().shape({
    floorMapping: Yup.array()
      .of(
        Yup.object().shape({
          account: Yup.string().nullable(true).required(' '),
          location: Yup.string().nullable(true).required(' '),
        })
      )
  });
};

export const MapClassFloorCode = (props) => {
  const { ignoreFetchedMapping } = useIgnoreMapping();
  const fetchedMapping = ignoreFetchedMapping ? null : props?.fetchedMapping?.fetchedMapping;
  const savedForm = useSelector(state => state.forms.entities.floorMapping);
  const currentLocation = useLocation();
  const [initialValues, setInitialValues] = useState({});
  const canShowError = useShowTaxError({ floorMapping: fetchedMapping });
  const { t } = useTranslation();
  const [onSuccessfulSaveSubject] = useState(new Subject());
  const { addMessage } = useMessage();
  const [classList, setClassList] = useState();
  const [locationsList, setLocationsList] = useState();
  const reducerState = useSelector(state =>
    state?.forms?.entities?.dashboard?.values?.isSplitRevenueRecordByFloor
  );

  const fetchedLocations = useSelector(state => state.globals.config.floorLocationMapping);

  const fetchedClasses = useSelector(state => state.globals.config.floorClassMapping);

  const isSplitRevenueRecordByFloor = props?.formik?.values?.isSplitRevenueRecordByFloor ?? reducerState;

  const locationList = useSelector((state) => state.globals.locations)

  const floorDetailList = useSelector(state => state.portalInfo.setFloorDetails)

  const dispatch = useDispatch();

  const isQbo = useConnectionMode()[0] === "Online";

  const config = [
    {
      name: t('LsFLoors'),
      field: 'floorDetails',
    },
    {
      name: t('Select a class'),
      field: 'account',
      type: 'dropdown',
      options: classList,
    },
    isQbo && {
      name: t('Select a location'),
      field: 'location',
      type: 'dropdown',
      alias: "location",
      options: locationsList,
    },
  ].filter((val) => { return Boolean(val) });

  const handleError = (res) => {
    addMessage(res);
  };

  useEffect(() => {
    props.formik.setFieldValue('isMapClass', false);
    if (locationList.length !== 0) {
      !isQbo && setClassList(locationList);
      let rowList;
      if (savedForm && savedForm.dirty) {
        setInitialValues(savedForm.values);
      } else {
        // Set row data
        rowList = floorDetailList?.map(rowInfoRaw => {
          let formValues;

          if (isQbo) {
            formValues = {
              [config[0].field]: rowInfoRaw.name,
              [config[1].field]: (fetchedClasses && fetchedClasses[rowInfoRaw.id]) || '',
              [config[2].field]: (fetchedLocations && fetchedLocations[rowInfoRaw.id]) || '',
              id: rowInfoRaw.id,
            };
          } else {
            formValues = {
              [config[0].field]: rowInfoRaw.name,
              [config[1].field]: (fetchedClasses && fetchedClasses[rowInfoRaw.id]) || '',
              id: rowInfoRaw.id,
            };
          }

          return formValues;
        });
        setInitialValues({ floorMapping: rowList });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let mounted = true;

    if (isSplitRevenueRecordByFloor && floorDetailList) {
      getLocationsList(currentLocation.id).then(res => {
        if (mounted) {
          let rowList;
          if (res.data) {
            let list = res.data.map(op => {
              return {
                value: op.id,
                label: op.name,
              }
            })
            // Set options list
            isQbo ? setLocationsList(list) : setClassList(list);
            dispatch(setLocations(list))
            if (savedForm && savedForm.dirty) {
              setInitialValues(savedForm.values);
            } else {
              // Set row data
              rowList = floorDetailList.map(rowInfoRaw => {
                let formValues;

                if (isQbo) {
                  formValues = {
                    [config[0].field]: rowInfoRaw.name,
                    [config[1].field]: (fetchedClasses && fetchedClasses[rowInfoRaw.id]) || '',
                    [config[2].field]: (fetchedLocations && fetchedLocations[rowInfoRaw.id]) || '',
                    id: rowInfoRaw.id,
                  };
                } else {
                  formValues = {
                    [config[0].field]: rowInfoRaw.name,
                    [config[1].field]: (fetchedClasses && fetchedClasses[rowInfoRaw.id]) || '',
                    id: rowInfoRaw.id,
                  };
                }

                return formValues;
              });
              setInitialValues({ floorMapping: rowList });
            }
          } else {
            handleError(res);
          }
        }
      }).catch(err => {
        props.formik.setFieldValue('isMapClass', false);
        addMessage(err.response, { default: t('Fetch failure') });
      });

      isQbo && getClassesList(currentLocation.id).then(res => {
        let list = res.data.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setClassList(list)
        dispatch(setClasses(list))
      }).catch(err => {
        props.formik.setFieldValue('isMapClass', false);
        addMessage(err.response, { default: t('Fetch failure') });
      })
    }
    return () => { mounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSplitRevenueRecordByFloor, floorDetailList]);

  return <Formik
    initialValues={initialValues}
    fetchedMapping={fetchedMapping}
    enableReinitialize={true}
    validationSchema={getValidationSchema()}
  >
    {formik =>
      <MapFloorCodesContent
        {...props}
        formik={formik}
        config={config}
        name="floorMapping"
        onSuccessfulSaveSubject={onSuccessfulSaveSubject}
        setInitialValues={setInitialValues}
        canShowError={canShowError}
      />
    }
  </Formik>
};
