import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    Table,
    Thead,
    Th,
    Td,
    Tr,
    TableActions
} from '@lightspeed/cirrus-table';
import { Button } from '@lightspeed/flame/Button';
import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import { Bone } from '@lightspeed/flame/Bone';
import { SyncStatusBadge } from '../custom/SyncStatusBadge';
import { TextLink, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Spinner } from '@lightspeed/flame/Spinner';
import { useEffect } from 'react';
import { useCurrency } from '../../util/hooks/useCurrency';
import { useIsInputsOnlyLayout } from '../../util/hooks/useIsInputsOnlyLayout';
import { IconDownload } from '@lightspeed/flame/Icon/Download';
import useRole from '../../util/hooks/useRole';
import { Select } from '@lightspeed/flame/Select';
import { DatePicker } from './DatePicker';

export const SyncTable = (props) => {
    const {
        rows,
        getData,
        openSyncDetailsModal,
        refreshData,
        loadingStatus,
        isEnd,
        handleDateFilter,
        handlePostStatus,
        isTypePo,
        selectedPostStatus
    } = props;

    const [loading, setLoading] = useState(false)
    const { t } = useTranslation();
    const { toCurrency } = useCurrency();
    const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

    useEffect(() => {
        setLoading(loadingStatus)
    }, [loadingStatus])

    const handleSyncDetails = (data) => {
        openSyncDetailsModal(data);
    };

    const RefreshButton = ({ refreshData }) => {
        const { t } = useTranslation();

        return <Button
            loading={loading}
            onClick={refreshData}
            variant="secondary"
            fill={true}
        >{t('Refresh')}</Button>;
    };

    const Loading = () => {
        return (
            [1, 2, 3, 4, 5].map((item, index) => (
                <Tr key={index}>
                    <Td><Bone height="1.5rem" /></Td>
                    <Td><Bone height="1.5rem" /></Td>
                    <Td><Bone height="1.5rem" /></Td>
                    {(isTypePo) && <Td><Bone height="1.5rem" /></Td>}
                    <Td><Bone height="1.5rem" /></Td>
                    <Td><Bone height="1.5rem" /></Td>
                    <Td><Bone height="1.5rem" /></Td>
                    {!isTypePo && <Td><Bone height="1.5rem" /></Td>}
                </Tr>
            ))
        )
    }

    const handleClick = (url) => {
        window.open(url, '_blank');
    };

    const PostStatusFilter = ({ onApplyFilter }) => {
        const { t } = useTranslation();
        const [userRole] = useRole();

        const options = [
            {
                value: "-1",
                label: t('All')
            },
            {
                value: "1",
                label: t('Success')
            },
            {
                value: "0",
                label: t('Failure')
            },
            {
                value: "2",
                label: t('Queued')
            },
            {
                value: "3",
                label: t('No Data')
            },
            userRole.isSupport && {
                value: "4",
                label: t('hold')
            },
        ].filter(Boolean)

        return <>
            <Select value={selectedPostStatus ? parseInt(selectedPostStatus) : null} onChange={(e) => onApplyFilter(e.target.value)} className="fl-select">
                {options?.map(item => {
                    return <option value={item?.value}>{item?.label}</option>
                })}
            </Select>
        </>
    };

    return (
        <>
            <TableActions.Provider>
                <TableActions.ActionsBar
                    leftHandActions={
                        <>
                            <Box width="20%" padding="0.75rem" >
                                <PostStatusFilter onApplyFilter={handlePostStatus} />
                            </Box>
                            <DatePicker onApplyFilter={handleDateFilter} loadingStatus={loadingStatus} />
                            <RefreshButton refreshData={() => {
                                refreshData()
                            }} loadingStatus={loading} />
                        </>
                    }
                />
            </TableActions.Provider>
            <InfiniteScroll
                dataLength={rows?.length ?? []}
                next={getData}
                hasMore={isEnd}
                loader={
                    <Flex justifyContent={"center"} m={"1rem"}>
                        <Spinner size='20' />
                        <Text ml=".3rem">{t("Loading")}...</Text>
                    </Flex>
                }
            >
                <Table>
                    <Thead>
                        <Tr>
                            <Th width="20%">{t('Date posted')}</Th>
                            <Th width="20%">{t('Post status')}</Th>
                            <Th width="20%">{t('Reference number')}</Th>
                            {isTypePo && <Th width="20%">{t('Supplier name')}</Th>}
                            <Th width="20%">{t('Total')}</Th>
                            {isInputsOnlyLayout &&
                                <Th width="20%">{t('Data')}</Th>
                            }
                            {!isTypePo && <Th width="20%">{t('View invoice')}</Th>}
                        </Tr>
                    </Thead>
                    <tbody>
                        {
                            (rows) ?
                                <>
                                    {rows?.length === 0 ?
                                        <Tr>
                                            <td colSpan="2">{t('No rows to show')}</td>
                                        </Tr> :
                                        rows?.map((item, index) => (
                                            <Tr key={index}>
                                                <Td>
                                                    {(item?.success !== 3) ?
                                                        <TextLink
                                                            fontWeight="bold"
                                                            onClick={() => { handleSyncDetails(item) }}
                                                        >
                                                            {item.actionDate}
                                                        </TextLink> :
                                                        <>
                                                            {item.actionDate}
                                                        </>
                                                    }
                                                </Td>
                                                <Td><SyncStatusBadge type={item.success} /></Td>
                                                <Td>{(item.success === 3) ? '' : item.orderNumber}</Td>
                                                {isTypePo && <Td>{item?.contact ?? ""}</Td>}
                                                <Td>{(item.success === 3) ? '' : toCurrency(item.totalAmount)}</Td>
                                                {isInputsOnlyLayout &&
                                                    <Td>{
                                                        item?.transactionResponse?.response?.data?.file ?
                                                            <Button
                                                                fill={true}
                                                                onClick={() => {
                                                                    handleClick(item?.transactionResponse?.response?.data?.file)
                                                                }}
                                                                pl="0.563rem"
                                                                pt="0.625rem"
                                                                pb="0.625rem"
                                                            >
                                                                <IconDownload />
                                                                {t('Download')}
                                                            </Button>
                                                            :
                                                            <Text>{t('Not available')}</Text>
                                                    }</Td>
                                                }
                                                <Td>
                                                    {(item.success === 1 && item?.url && Object?.entries(item?.url)?.length !== 0) ?
                                                        Object.keys(item?.url ?? {})?.map((key) => (
                                                            <>
                                                                <TextLink fontWeight="bold" href={item?.url[key]} target="_blank">View {key}</TextLink>
                                                                <Flex mb={".5rem"} />
                                                            </>
                                                        ))
                                                        :
                                                        ''
                                                    }
                                                </Td>
                                            </Tr>
                                        ))
                                    }
                                </> :
                                <Loading />
                        }
                    </tbody>
                </Table>
            </InfiniteScroll>
        </>
    )
};