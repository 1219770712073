import React from 'react';
import { useState, useEffect } from 'react';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Autocomplete } from '../fields';
import useLocation from '../../util/useLocation';
import { getLocationsList, getClassesList } from '../../api/advancedConfig';
import useConnectionMode from '../../util/useConnectionMode';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../../util/hooks/useMessage';
import { SwitchLayout } from '../custom/SwitchLayout';
import { setLocations, setClasses } from '../../state/globalsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@lightspeed/flame/Text';
import { Label } from '@lightspeed/flame/FormField';

export const MultipleClass = ({ formik }) => {
    const dispatch = useDispatch()
    const [classList, setClassList] = useState();
    const [locationsList, setLocationsList] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { isMapClass } = formik.values;
    const currentLocation = useLocation();
    const [, swName] = useConnectionMode();
    const { t } = useTranslation();
    const { addMessage } = useMessage();
    const locationList = useSelector((state) => state.globals.locations)
    const classesList = useSelector((state) => state.globals.classes)
    const location = formik?.values.locationMapping ?? ''
    const classes = formik?.values.classMapping ?? ''

    useEffect(() => {
        if (locationList.length !== 0) {
            setLocationsList(locationList)
            setIsLoading(false);
        } else if (classesList.length !== 0) {
            setClassList(classesList);
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (classList && locationList) {
            setIsLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classList, locationList])

    useEffect(() => {
        let mounted = true;
        if (isMapClass) {
            setIsLoading(true);
            getLocationsList(currentLocation.id).then(res => {
                if (mounted === true) {
                    let list = res.data.map(op => {
                        return {
                            value: op.id,
                            label: op.name,
                        }
                    })
                    setLocationsList(list);
                    dispatch(setLocations(list))
                }
            })
                .catch(err => {
                    if (mounted) {
                        // Reset switch
                        formik.setFieldValue('isMapClass', false);
                        // Show toast
                        addMessage(err.response, { default: t('Fetch failure') });
                    }
                })

            getClassesList(currentLocation.id).then(res => {
                let list = res.data.map(item => {
                    return {
                        value: item.name,
                        label: item.name
                    }
                })
                setClassList(list)
                dispatch(setClasses(list))
            })
                .catch(err => {
                    formik.setFieldValue('isMapClass', false);
                    addMessage(err.response, { default: t('Fetch failure') });
                })
        }

        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMapClass]);

    return (
        <SwitchLayout
            heading={t('Location tracking', { swName })}
            description={t('ClassMappingKSeries', { swName })}
            switchFieldName="isMapClass"
            isSwitchOn={isMapClass}
            isLoading={isLoading}
        >
            <Flex>
                <Box width="49%" pr="1.1rem">
                    <Label htmlFor="locationMapping">
                        {t('Select a Location')}
                    </Label>
                    <Autocomplete
                        id="locationMapping"
                        name="locationMapping"
                        isLoadingOptions={!classList || isLoading}
                        options={locationsList}
                        disabled={classes !== ""}
                        placeholder={t('Select a location')}
                        formik={formik}
                    />
                </Box>
                <Box width="49%" pr="1.1rem">
                    <Label htmlFor="classMapping">
                        {t('Select a Class')}
                    </Label>
                    <Autocomplete
                        id="classMapping"
                        name="classMapping"
                        isLoadingOptions={!classList || isLoading}
                        disabled={location !== ""}
                        options={classList}
                        placeholder={t('Select a class')}
                        formik={formik}
                    />
                </Box>
            </Flex>
            <Flex mt="1rem" ml=".2rem">
                <><Text fontWeight={"bold"} mr=".3rem">Required : </Text> Either Location mapping or Class Mapping.</>
            </Flex>
        </SwitchLayout>
    )
}