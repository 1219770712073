import { AnnotatedLayout } from '@lightspeed/flame/Layout';
import { TextLink } from '@lightspeed/flame/Text';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { useTranslation } from 'react-i18next';
import { ClassMapping } from './ClassMapping';
import { EComMapping } from './EComMapping';
import { Divider } from '@lightspeed/flame/Divider';

const LocationSectionContent = (props) => {
  return <>
    <Card ml="1rem">
      <CardSection>
        <ClassMapping {...props} />
      </CardSection>
      <Divider />
      <CardSection>
        <EComMapping {...props} />
      </CardSection>
    </Card>
  </>;
};

export const LocationSection = (props) => {
  const { t } = useTranslation();

  return <AnnotatedLayout
    title={t('Location and eCom')}
    description={t('LocationSectionDesc')}
    renderExtras={
      <>
        {false && <TextLink size="small" fontWeight="bold">
          {t('Learn more')}
        </TextLink>}
      </>
    }
    mb="2.25rem"
    mt="2.25rem"
  >
    <LocationSectionContent {...props} />
  </AnnotatedLayout>;
};