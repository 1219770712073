import { CheckboxField } from '../fields';
import { Box } from '@lightspeed/flame/Core';
import { CheckboxLabel } from '@lightspeed/flame/Checkbox';
import { useSelector } from 'react-redux';
import { AlertInCard } from '@lightspeed/flame/Alert';
import { useTranslation } from 'react-i18next';

export const MappingOptions = ({ isMapPurchaseInvoice, isTrackInventory, isMapCogs }) => {
  const disableInventory = isMapCogs || (!isMapPurchaseInvoice);
  const hasSubmittedWithErrors = useSelector(state => state.globals.hasSubmittedWithErrors);
  const showError = !(isMapPurchaseInvoice || isMapCogs) && hasSubmittedWithErrors;
  const { t } = useTranslation();

  return <Box>
    <CheckboxField
      id="isMapPurchaseInvoice"
      name="isMapPurchaseInvoice"
      label="For each purchase order in Lightspeed, create a Purchase Invoice"
    />
    <Box mt="1.125rem" mb="1.125rem">
      <CheckboxLabel color={disableInventory ? 'gray-500' : 'black'}>
        <CheckboxField
          id="isTrackInventory"
          name="isTrackInventory"
          disabled={disableInventory}
        />
        Track Inventory
      </CheckboxLabel>
    </Box>
    <CheckboxField
      id="isMapCogs"
      name="isMapCogs"
      label="Track Cost of Goods Sold"
    />{showError &&
      <Box width="49%" pr="1.1rem" pt="1.5rem">
        <AlertInCard
          type="danger"
          width="auto"
          noCloseBtn
        >
          {t('Select an option to map your accounts')}
        </AlertInCard>
      </Box>
    }
  </Box>;
};
