import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAppSyncing } from '../../state/globalsReducer';

export const useAppSyncing = () => {
  const isSyncing = useSelector(state => state.globals.isAppSyncing);
  const dispatch = useDispatch();

  const setSyncing = (args) => {
    dispatch(setAppSyncing(args));
  };

  return [isSyncing, setSyncing];
};

export default useAppSyncing;
