import { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from '@lightspeed/flame/Modal';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Button } from '@lightspeed/flame/Button';
import { softwareImages } from '../../util/softwareImages';
import { useDispatch } from 'react-redux';
import useLocation from '../../util/useLocation';
import { navigateToMappingConfig } from '../../util/mappingConfig';
import { registerSw, validateSwConnection } from '../../api/selectSoftware';
import { useToasts } from '@lightspeed/flame/Toaster';
import { Heading3, TextLink, Text } from '@lightspeed/flame/Text';
import { getConnectionModeName, getCurrentTarget } from '../../util';
import { useAppConnected } from '../../util/hooks/useAppConnected';
import ellipseImg from '../../images/ellipse.svg';
import { IconVerified } from '@lightspeed/flame/Icon/Verified';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../../util/hooks/useMessage';
import usePosType from '../../util/hooks/usePosType';
import useConnectionMode from '../../util/useConnectionMode';
import useCompanyName from '../../util/hooks/useCompanyName';

const Ellipse = ({ size, status }) => {
  const style = { width: size, height: size, marginLeft: '3px', marginRight: '3px' };

  return <>
    {status === 'success' ?
      <IconVerified style={style} color="green" /> :
      <img
        src={ellipseImg} alt="connection"
        style={style}
      />
    }
  </>;
};

const ConnectionStatusIndicator = ({ isVerified }) => {
  return <Flex alignItems="center" ml="15px" mr="15px">
    <Ellipse size="6px" />
    <Ellipse size="9px" />
    <Ellipse size="16px" status={isVerified ? 'success' : 'disconnected'} />
    <Ellipse size="9px" />
    <Ellipse size="6px" />
  </Flex>;
};

const ConnectionSection = ({ swData, isVerified }) => {
  const target = getCurrentTarget();
  return <Flex
    height="60px" mt="60px" mb="60px" ml="auto" mr="auto"
    alignItems="center" justifyContent="center"
  >
    <img src={target.logo} alt={target.fullName}
      style={{ width: '60px', height: '60px' }}
    />
    <ConnectionStatusIndicator isVerified={isVerified} />
    <img src={softwareImages[swData.id]} alt={swData.accountingSoftware}
      style={{ width: swData.accountingSoftware === "Acomba" ? '150px' : '60px', height: '60px' }}
    />
  </Flex>;
};

const HelpSection = ({ swName, handleLoginRequest, currentState, connectionMode }) => {
  const { addToast } = useToasts();
  const target = getCurrentTarget();
  const { t } = useTranslation();
  const posType = usePosType();
  const disableSignIn = connectionMode === 'Datev' || connectionMode === 'Acomba';
  let helpText = t('OnlineSwHelp.0', { swName, target: target.fullName });

  if (disableSignIn) {
    helpText = helpText.split('.').slice(0, -1).join('.');
  }

  const handleSigning = () => {
    if (currentState === 'ready') {
      handleLoginRequest();
    } else {
      addToast(t('Still loading'), { appearance: 'error' });
    }
  };

  const openHelp = () => {
    if (posType.isLSeries) {
      window.open('https://resto-support.lightspeedhq.com/hc/en-us/articles/1260804626929', '_blank');
    }
  };

  return <Box>
    <Text style={{ marginBottom: '1.625rem' }}>
      {helpText}
      {!disableSignIn &&
        <>
          <TextLink onClick={handleSigning} fontWeight="bold">
            {' ' + t('OnlineSwHelp.1') + ' '}
          </TextLink>
          {t('OnlineSwHelp.2')}
        </>
      }
    </Text>
    <Text>
      {t('AuthorizeSignHelp.0', { swName })}
      <TextLink fontWeight="bold" onClick={openHelp}>
        {' ' + t('AuthorizeSignHelp.1', { swName }) + ' '}
      </TextLink>
      {t('AuthorizeSignHelp.2', { target: target.fullName })}
    </Text>
  </Box>
};

const PasswordSection = ({ password }) => {
  const { t } = useTranslation();

  return <>
    {password &&
      <Flex justifyContent="center">
        <Text style={{ marginBottom: '1rem' }}>
          {t('Password is', { password, })}
        </Text>
      </Flex>
    }
  </>;
};

const responseToSwInfo = (responseData, swData) => {
  let result;

  if (responseData) {
    result = {
      onlineAuthenticationUrl: responseData.onlineAuthenticationUrl,
      downloadUrl: responseData.appDownloadURL || responseData.qwcDownloadURL,
      id: swData.id,
      password: responseData.qwcDownloadPassword,
    }
  }

  return result;
};

export const AuthorizeSoftware = ({ swData, closeModal, isReconnect, reconnectInfo, ...props }) => {
  const { addToast } = useToasts();
  const [currentState, setCurrentState] = useState('loading');
  const [, setConnected] = useAppConnected();
  const [isVerified, setIsVerified] = useState(false);
  const [popupWindow, setPopupWindow] = useState();
  const [swInfo, setSwInfo] = useState(responseToSwInfo(reconnectInfo, swData));
  const dispatch = useDispatch();
  const selectedLocation = useLocation();
  const isOfflineSw = swInfo && swInfo.downloadUrl;
  const { t } = useTranslation();
  const swName = getConnectionModeName(t, swData.id);
  const [, setCompanyName] = useCompanyName();
  const { addMessage } = useMessage();
  const [, , setConnectionMode] = useConnectionMode();
  const requiresNoAuth = swInfo && !(
    swInfo.onlineAuthenticationUrl ||
    swInfo.appDownloadURL ||
    swInfo.qwcDownloadURL ||
    swInfo.qwcDownloadPassword
  );

  const handleError = (res, defaultMsg) => {
    addMessage(res, { default: defaultMsg || t('Communication failure') });
  };

  const openAuthUrl = () => {
    const popup = window.open(swInfo.onlineAuthenticationUrl, 'Authorize', 'width=600,height=400,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no');
    setPopupWindow(popup);
  };

  const handleLoginRequest = () => {
    if (swInfo.onlineAuthenticationUrl) {
      openAuthUrl();
    } else if (swInfo.downloadUrl) {
      window.open(swInfo.downloadUrl, '_blank');
    }

  };

  const handleClose = (meta) => {
    if (popupWindow && !popupWindow.closed) {
      popupWindow.close();
    }
    closeModal(meta);
  };

  const handleDone = () => {
    if (isReconnect) {
      handleClose({ success: true });
    } else {
      setConnectionMode(swInfo.id);
      navigateToMappingConfig(props.navigate);
    }
  };

  const handleDoneClick = () => {
    if (isOfflineSw || requiresNoAuth) {
      setCurrentState('verify');
    } else {
      handleDone();
    }
  }

  // Automatically open window when software info changes
  useEffect(() => {
    if (swInfo) {
      handleLoginRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swInfo]);

  // Load software information when modal opens
  useEffect(() => {
    let mounted = true;
    if (!swInfo) {
      setSwInfo(null);
        registerSw({
          connectionMode: swData.id,
        }, selectedLocation.id).then(res => {
          if (mounted) {
            const urlData = res.data;
            if (urlData) {
              setSwInfo(responseToSwInfo(urlData, swData));
            } else {
              handleError(res);
            }
          }
        }).catch(err => {
          if (mounted) {
            handleError(err.response)
          }
        });
    }
    return () => { mounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update loading indicator
  useEffect(() => {
    if (swInfo) {
      setCurrentState('ready');
    } else {
      setCurrentState('loading');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swInfo]);

  // Detect when popup window closes
  useEffect(() => {
    let mounted = true;
    if (popupWindow) {
      const detectCloseTimer = setInterval(() => {
        if (popupWindow.closed) {
          clearInterval(detectCloseTimer);
          if (mounted) {
            setCurrentState('verify');
          }
        }
      }, 1000);
    }

    return () => { mounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupWindow]);

  // Verify connection
  useEffect(() => {
    let mounted = true;
    if (currentState === 'verify') {
      validateSwConnection(selectedLocation.id).then((res) => {
        if (mounted) {
          setCompanyName(res.data.companyName)
          setConnected(true);
          setIsVerified(true);
          setCurrentState('ready');
          if (isOfflineSw || requiresNoAuth) {
            handleDone();
          }
        }
      }).catch(err => {
        if (mounted) {
          setCurrentState('ready');
          handleError(err.response, t('Sw verification failure'));
        }
      });
    }

    return () => { mounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, currentState, dispatch, props.navigate, selectedLocation.id, swInfo]);

  return <Modal
    isOpen={true}
    shouldCloseOnOverlayClick={false}
  >
    <ModalHeader showCloseButton={false}>
      <Heading3>{swName}</Heading3>
    </ModalHeader>
    <ModalBody scroll={true}>
      <Box maxWidth="600px" width="600px">
        <ConnectionSection swData={swData} isVerified={isVerified} />
        <PasswordSection password={swInfo && swInfo.password} />
        <HelpSection
          swName={swName}
          handleLoginRequest={handleLoginRequest}
          currentState={currentState}
          connectionMode={swData.id}
        />
      </Box>
    </ModalBody>
    <ModalFooter>
      <Flex width="100%" justifyContent="flex-end">
        {!isVerified && <Button
          onClick={() => { handleClose() }} mr={2}
          disabled={currentState !== 'ready'}
        >{t('Cancel')}</Button>}
        <Button variant="secondary" fill="true"
          loading={currentState !== 'ready'}
          disabled={!(isVerified || isOfflineSw || requiresNoAuth)}
          onClick={handleDoneClick}
        >{t('Done')}</Button>
      </Flex>
    </ModalFooter>
  </Modal>;
};
