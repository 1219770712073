import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MapGeneric } from '../mapGeneric/MapGeneric';
import { getItemsList } from '../../api/advancedConfig';
import useLocation from '../../util/useLocation';
import useAccounts from '../../util/useAccounts';
import useConnectionMode from '../../util/useConnectionMode';
import { CreateAccount } from '../createAccount/CreateAccount';
import { getCurrentTarget } from '../../util';
import { usePosType } from '../../util/hooks/usePosType';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../../util/hooks/useMessage';
import { getKeyByPosType } from '../../util/translation';

const getAddRowRadioButtons = (posType, t) => {
  let buttons = [];

  if (posType.isRetail) {
    buttons = [
      {
        label: t('Add the highest level categories'),
        value: 'highest',
      },
      {
        label: t('Add the deepest level categories'),
        value: 'deepest'
      },
    ];
  }

  return buttons;
};

export const MapIndividualProducts = (props) => {
  const posType = usePosType();
  const { isChangedPostingMethod } = useSelector(state => state.forms.meta);
  const isParentCategory = posType.isRetail && (isChangedPostingMethod ? true : props.location.state.isParentCategory);
  const recordCategoryForInvoiceByProduct = posType.isRetail && (isChangedPostingMethod ? null : props.location.state.recordCategoryForInvoiceByProduct);
  const location = useLocation();
  const [, accountsList] = useAccounts('items');

  const data = useSelector(state => state.globals.config)

  const postingMethod = useSelector(state => state.forms.entities.dashboard?.values.postingMethod) || {};
  const { defaultProductsAccount: defaultAccountValue } = useSelector(state =>
    state.forms.entities.dashboard?.values
  ) || {};
  const placeholderAccount = defaultAccountValue && accountsList.find(account => (account.value === defaultAccountValue));
  const placeholder = placeholderAccount && placeholderAccount.label;
  const [, softwareName] = useConnectionMode();
  const [createAccountData, setCreateAccountData] = useState({
    isOpen: false,
    accountName: '',
  });
  const [isTypeProduct, setIsTypeProduct] = useState(false);
  
  const target = getCurrentTarget();
  const { t } = useTranslation();
  const { addMessage } = useMessage();
  const formName = posType.isRetail ?
    (isTypeProduct ? 'productsMapping' : 'categoriesMapping')
    : 'productsMapping';

  const handleCreateAccount = (newAccountName) => {
    setCreateAccountData({
      isOpen: true,
      accountName: newAccountName,
    });
  };

  const closeAccountModal = () => {
    setCreateAccountData({ isOpen: false });
  };

  const config = [
    {
      title: t(getKeyByPosType(
        isTypeProduct ? 'LsProduct' : 'LsCategory',
        posType),
        {
          target: target.fullName,
        }
      ),
      name: 'product',
    },
    {
      title: t('SwAccounts', { swName: softwareName }),
      name: 'account',
      options: accountsList,
      type: 'dropdown',
      placeholder: placeholder,
      onCreateOption: handleCreateAccount,
    }
  ];

  const handleError = (res) => {
    addMessage(res, { default: t('Fetch failure') });
  };

  const fetchRows = (inputParams) => {
    const params = {
      recordItems: postingMethod === 'invoice',
    };

    let newFormName = formName;

    if (posType.isRetail) {
      if (inputParams.mappingType) {
        params.recordItems = inputParams.mappingType === 'product';
        setIsTypeProduct(params.recordItems);
        if (params.recordItems) {
          newFormName = 'productsMapping';
        }
      }
      if (!params.recordItems) {
        params.superParentCategory = inputParams.breakCategories === 'highest';
      }
    }

    const fetchedMapping = isChangedPostingMethod ? null : (
      newFormName === 'productsMapping' ? data?.items : data?.categories
    );

    return getItemsList(params, location.id).then(res => {
      let rowsList;
      if (res.data) {
        const resData = res.data;
        rowsList = resData.map((rowInfoRaw, rowIndex) => {
          const formValues = {
            [config[0].name]: rowInfoRaw.name,
            [config[1].name]: inputParams.hasMappingLevelChanged ? '' : ((fetchedMapping && fetchedMapping[rowInfoRaw.id]) || ''),
            id: rowInfoRaw.id,
            rowId: rowIndex,
          };

          return formValues;
        });

        return rowsList;
      } else {
        handleError(res);
      }
    }).catch(err => {
      handleError(err.response);
    });
  };

  const checkHasMapping = (rowData) => {
    return rowData && rowData.account;
  };

  const createAddRowOption = (rowData) => {
    return {
      value: rowData.id,
      label: rowData[config[0].name],
      rowId: rowData.rowId,
    };
  };

  const handleRemoveMapping = (rowData) => {
    return Object.assign({}, rowData, {
      [config[1].name]: '',
    });
  };

  return <>
    {createAccountData.isOpen === true &&
      <CreateAccount
        accountType="Income"
        closeModal={closeAccountModal}
        accountName={createAccountData.accountName}
      />
    }
    <MapGeneric
      pageTitle={t(getKeyByPosType(
        isTypeProduct ? 'Map Product' : 'Map Category',
        posType))
      }
      pageDescription={
        t(getKeyByPosType(
          isTypeProduct ? 'MapProductPageDesc' : 'MapCategoryPageDesc',
          posType),
          { swName: softwareName }
        )
      }
      title={t(getKeyByPosType(
        isTypeProduct ? 'Products' : 'Categories',
        posType),
      )}
      description={t(
        getKeyByPosType(
          isTypeProduct ? 'IndividualProductDesc' : 'IndividualCategoryDesc',
          posType),
        {
          swName: softwareName,
        })
      }
      config={config}
      fetchRows={fetchRows}
      name={formName}
      persistFormName={formName}
      key={formName}
      selectiveMapping={{
        enabled: isTypeProduct || posType.isRetail,
        heading: t(
          isTypeProduct ?
            getKeyByPosType('Add Product', posType) :
            t('Add categories')
        ),
        description: isTypeProduct ?
          t(getKeyByPosType('AddProductDesc', posType), {
            swName: softwareName
          })
          :
          t('Add category desc')
        ,
        placeholder: isTypeProduct ?
          t(getKeyByPosType('Enter product name', posType))
          :
          t('Select category name')
        ,
        hasMapping: checkHasMapping,
        rowToOption: createAddRowOption,
        removeMapping: handleRemoveMapping,
        radioButtons: {
          isShown: posType.isRetail && (!isTypeProduct),
          buttons: posType.isRetail && getAddRowRadioButtons(posType, t),
          field: 'breakCategories',
          initialValue: (Boolean(isParentCategory) === false) ? 'deepest' : 'highest',
        }
      }}
      mappingSwitch={{
        isEnabled: posType.isRetail && (postingMethod === 'invoice'),
        initialValue: isTypeProduct ? 'product' : 'category',
      }}
      {...props}
    />
  </>;
};
