import { useSelector, useDispatch } from 'react-redux';
import { setIsInputsOnlyLayout } from '../../state/globalsReducer';

export const useIsInputsOnlyLayout = () => {
  const currentValue = useSelector(state => state.globals.isInputsOnlyLayout);
  const dispatch = useDispatch();

  const setValue = (newValue) => {
    dispatch(setIsInputsOnlyLayout(newValue));
  };

  return [currentValue, setValue];
};

export default useIsInputsOnlyLayout;
