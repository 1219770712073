import { useState } from 'react';
import { AnnotatedLayout } from '@lightspeed/flame/Layout';
import { Heading3, Text } from '@lightspeed/flame/Text';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Divider } from '@lightspeed/flame/Divider';
import { Flex, Box } from '@lightspeed/flame/Core';
import { Bone } from '@lightspeed/flame/Bone';
import { Button } from '@lightspeed/flame/Button';
import { Label } from '@lightspeed/flame/FormField';
import { softwareImages } from '../../util/softwareImages';
import { RadioField, TextInput, SelectField } from '../fields';
import { Modal, ModalBody, ModalHeader, ModalFooter } from '@lightspeed/flame/Modal';
import useAppConnected from '../../util/hooks/useAppConnected';
import useAppSubmitting from '../../util/useAppSubmitting';
import useConnectionMode from '../../util/useConnectionMode';
import { getStateOnChangePostingMethod } from '../../util/dashboard';
import { deactivateAccount, reconnectAccount } from '../../api/dashboard';
import { useToasts } from '@lightspeed/flame/Toaster';
import { AuthorizeSoftware } from '../authorizeSoftware/AuthorizeSoftware';
import { SyncStatus } from '../syncStatus/SyncStatus';
import { IconUnlink } from '@lightspeed/flame/Icon/Unlink';
import { LabelRequired } from '../custom/LabelRequired';
import { useDispatch } from 'react-redux';
import { setPostingMethodChanged, setChangedDraftnApproved } from '../../state/formsReducer';
import { resetAccounts } from '../../state/accountsReducer';
import { getCurrentTarget } from '../../util';
import { usePosType } from '../../util/hooks/usePosType';
import { useTranslation } from 'react-i18next';
import useCompanyName from '../../util/hooks/useCompanyName';
import { useMessage } from '../../util/hooks/useMessage';
import { getKeyByPosType } from '../../util/translation';
import { LearnMore } from '../custom/LearnMore';
import useLocation from '../../util/useLocation';
import useIsInputsOnlyLayout from '../../util/hooks/useIsInputsOnlyLayout';
import { fetchAllAccounts } from '../../state/accountsReducer';
import useRole from '../../util/hooks/useRole';
import { useSelector } from 'react-redux';
import { setConfigurations, setIfAnyErrorMsg } from '../../state/globalsReducer';
import { PopupButton } from "react-calendly";

const ConfirmChange = ({ confirmMethods, label }) => {
  const { resolve, reject } = confirmMethods;
  const { t } = useTranslation();

  return <Modal
    isOpen={true}
    onRequestClose={reject}
  >
    <ModalHeader>{t('Warning')}</ModalHeader>
    <ModalBody style={{ maxWidth: '37.5rem' }}>
      <Text fontWeight="bold" fontSize="text-s" mb="0.8rem">
        {t('ChangeMethodWarning.0', { label })}
      </Text>
      <Text mb="0.5rem" color="gray-600" fontSize="text-s">
        {t('ChangeMethodWarning.1')}
      </Text>
    </ModalBody>
    <ModalFooter>
      <Flex justifyContent="flex-end">
        <Button onClick={reject} mr="0.8rem">{t('Cancel')}</Button>
        <Button fill="true" variant="danger" onClick={resolve}>
          {t('Change')}
        </Button>
      </Flex>
    </ModalFooter>
  </Modal>;
};

const PostingMethodRadio = ({ style, label, formik, ...props }) => {
  const [confirmMethods, setConfirmMethods] = useState();
  const dispatch = useDispatch();
  const posType = usePosType();
  const { config: defaultConfigs, taxLabels } = useSelector(state => state.globals)

  const confirmChange = ({ meta }) => {
    return new Promise((resolve, reject) => {

      const handleChange = () => {
        const newState = getStateOnChangePostingMethod(formik, posType, taxLabels);
        // Clear saved forms
        dispatch(setPostingMethodChanged(newState));
        dispatch(setConfigurations({ ...defaultConfigs, discountsMapping: "", payments: "" }))
        // Reset formik form. This will propagate and update saved forms
        formik.resetForm(newState);
        resolve();
      };

      if (meta.value === '') {
        resolve();
      } else {
        setConfirmMethods({ resolve: handleChange, reject });
      }
    }).finally(() => { setConfirmMethods(null) });
  };

  return (
    <>
      {confirmMethods &&
        <ConfirmChange
          label={label}
          confirmMethods={confirmMethods}
        />
      }
      <Box width="50%" style={style || {}}>
        <RadioField
          name="postingMethod"
          confirmChange={confirmChange}
          descriptionProps={{ color: 'gray-600' }}
          type="radio"
          label={<Box mb="0.375rem">{label}</Box>}
          {...props}
        />
      </Box>
    </>
  );
};

const PostingMethodRadioLoading = () => {
  return <Box width="49%">
    <Bone height="1rem" width="10rem" style={{ marginBottom: "1.5rem" }} />
    <Bone height="1rem" width="13rem" style={{ marginBottom: "0.5rem" }} />
    <Bone height="1rem" width="6rem" style={{ marginBottom: "0.5rem" }} />
    <Bone height="1rem" width="9rem" />
  </Box>;
};

const AccountingSwInfo = ({ connectionMode, softwareName }) => {
  const [companyName] = useCompanyName();

  return <Flex>
    <Flex width="110px" height="100%" flexDirection="column" justifyContent="center" alignItems="center">
      {connectionMode ?
        <img src={softwareImages[connectionMode]} alt={softwareName}
          style={{ width: '80px' }}
        /> :
        <Bone width="80px" height="80px" />
      }
    </Flex>
    <Flex justifyContent="center" flexDirection="column">
      {connectionMode ?
        <Heading3>{softwareName}</Heading3> :
        <Bone height="1.125rem" width="100px" />
      }
      {Boolean(companyName) &&
        <Text color="gray-600" size="small">{companyName}</Text>
      }
    </Flex>
  </Flex>;
};

const ConfirmDisconnect = ({ closeModal, confirm }) => {
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();

  return <Modal
    isOpen={true}
    onRequestClose={closeModal}
  >
    <ModalHeader>{t('Warning')}</ModalHeader>
    <ModalBody style={{ maxWidth: '37.5rem' }}>
      <Text fontWeight="bold" fontSize="text-s" mb="0.8rem">
        {t('DisconnectWarning.0', { swName })}
      </Text>
      <Text mb="0.5rem" color="gray-600" fontSize="text-s">
        {t('DisconnectWarning.1')}
      </Text>
    </ModalBody>
    <ModalFooter>
      <Flex justifyContent="flex-end">
        <Button onClick={closeModal} mr="0.8rem">{t('Cancel')}</Button>
        <Button fill="true" variant="danger" onClick={confirm}>{t('Disconnect')}</Button>
      </Flex>
    </ModalFooter>
  </Modal>;
};

const DeactivateButton = ({ isAppSubmitting, isConnected, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [, setConnected] = useAppConnected();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [, setCompanyName] = useCompanyName();
  const { addMessage } = useMessage();
  const selectedLocation = useLocation();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    setShowModal(false);
    dispatch(setIfAnyErrorMsg(""));
    deactivateAccount(selectedLocation.id).then(() => {
      setConnected(false);
      addToast(t('Account disconnected'), { appearance: 'success' });
      // Reset state
      dispatch(resetAccounts());
      setCompanyName('');
      // Navigate to software selection screen
      props.navigate('../selectsw');
    }).catch(err => {
      addMessage(err.response, { default: t('DisconnectFailure') });
      setIsLoading(false);
    });
  };

  const handleClick = () => {
    if (!(isLoading || showModal)) {
      setShowModal(true);
    }
  };

  return <>
    {
      showModal &&
      <ConfirmDisconnect
        closeModal={handleCloseModal}
        confirm={handleConfirm}
      />
    }
    <Button
      fill={true}
      disabled={isAppSubmitting}
      onClick={handleClick}
      loading={isLoading}
      pl="0.563rem"
      pt="0.625rem"
      pb="0.625rem"
    >
      <IconUnlink />
      {t('Disconnect')}
    </Button>
  </>;
};

const ReconnectButton = ({ isAppSubmitting, isConnected, setConnected, formik, callbackOnReconnect, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const [swId, swName] = useConnectionMode();
  const [reconnectInfo, setReconnectInfo] = useState();
  const { t } = useTranslation();
  const { addMessage } = useMessage();
  const selectedLocation = useLocation();
  const dispatch = useDispatch();
  const currentLocation = useLocation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

  const handleCloseModal = (args) => {
    setReconnectInfo(null);

    if (args && (args.success === true)) {
      callbackOnReconnect()
      if (!isInputsOnlyLayout) {
        dispatch(fetchAllAccounts(currentLocation.id)).then(res => {
          if (fetchAllAccounts.rejected.match(res)) {
            addMessage(res.payload && res.payload.response, { default: t('Fetch failure') });
          }
        });
      }
    }
  };

  const handleReconnect = () => {
    setIsLoading(true);
    dispatch(setIfAnyErrorMsg(""));
    reconnectAccount(selectedLocation.id).then(res => {
      if (res.data) {
        setReconnectInfo(res.data);
      } else {
        addToast(t('Communication failure'), { appearance: 'error' });
      }
    }).catch(err => {
      addMessage(err.response, { default: t('ReconnectFailure') });
    }).then(() => {
      setIsLoading(false);
    });
  };

  const handleClick = () => {
    if (isLoading === false) {
      handleReconnect();
    }
  };

  return <>
    {
      reconnectInfo &&
      <AuthorizeSoftware
        swData={{ id: swId, accountingSoftware: swName }}
        closeModal={handleCloseModal}
        isReconnect={true}
        reconnectInfo={reconnectInfo}
        {...props}
      />
    }
    <Button mr="0.75rem"
      disabled={isAppSubmitting}
      onClick={handleClick}
      loading={isLoading}
    >
      {t('Reconnect')}
    </Button>
  </>;
};

const ActionButtons = (props) => {
  const [isAppSubmitting] = useAppSubmitting();
  const [isConnected, setConnected] = useAppConnected();

  return <Flex alignItems="center" pr="0.5rem">
    {isConnected !== null ?
      <>
        <ReconnectButton
          isAppSubmitting={isAppSubmitting}
          isConnected={isConnected}
          setConnected={setConnected}
          {...props}
        />
        <DeactivateButton {...props}
          isAppSubmitting={isAppSubmitting}
          isConnected={isConnected}
        />
      </> :
      <>
        <Bone height="2.25rem" width="6.25rem" style={{ marginRight: '0.75rem' }} />
        <Bone height="2.25rem" width="6.25rem" />
      </>
    }
  </Flex>;
};

const InfoCardSectionTop = (props) => {
  return <Flex height="100px" justifyContent="space-between" width="100%">
    <AccountingSwInfo {...props} />
    <ActionButtons {...props} />
  </Flex>;
};

const SyncInfoForm = ({ isLoading, fetchedConfig, ...props }) => {
  const { t } = useTranslation();
  const state = [fetchedConfig && fetchedConfig.activationDate]
  const configdate = [fetchedConfig && fetchedConfig.configuredDate]
  const connectorLastRun = [fetchedConfig && fetchedConfig.connectorLastRun]
  const connectionMode = useConnectionMode()
  const filepath = [fetchedConfig && fetchedConfig.FilePath]
  const role = useRole()

  return <Box width="50%" padding="0.75rem" >
    <Box>
      {role[0].type === 1 ?
        <Label htmlFor="Active since" fontWeight="bold">{t('Active since')} : {state}</Label> :
        ''
      }
      {role[0].type === 1 ?
        <Label htmlFor="Configuration Date" fontWeight="bold" >{t('Configuration Date')} : {configdate}</Label> :
        ''
      }
      {connectionMode[0] === 'QB Desktop Edition' && role[0].type === 1 ?
        <Label htmlFor="filepath" fontWeight="bold" color="#2E61DE">{filepath}</Label>
        :
        ''
      }
      {connectionMode[0] === 'QB Desktop Edition' && role[0].type === 1 ?
        <Label htmlFor="ConnectorTime" fontWeight="bold">{t('ConnectorTime')} : {connectorLastRun}</Label>
        :
        ''
      }
    </Box>
    <Box mb="1.6rem">
      <Label htmlFor="syncRange">{t('Sync schedule')}</Label>
      <SelectField isLoading={isLoading} id="syncRange" name="syncRange">
        <option value="0">{t('Pause')}</option>
        <option value="1">{t('Sync daily')}</option>
        <option value="3">{t('Sync weekly on Mondays')}</option>
        <option value="4">{t('Sync weekly on Tuesdays')}</option>
        <option value="5">{t('Sync weekly on Wednesdays')}</option>
        <option value="6">{t('Sync weekly on Thursdays')}</option>
        <option value="7">{t('Sync weekly on Fridays')}</option>
        <option value="8">{t('Sync weekly on Saturdays')}</option>
        <option value="2">{t('Sync weekly on Sundays')}</option>
        {connectionMode[0] === 'Datev' &&
          <>
            <option value="9">{t('Sync weekly')}</option>
            <option value="10">{t('Sync monthly')}</option>
          </>
        }
      </SelectField>
    </Box>
    <Box>
      <LabelRequired htmlFor="email"
        description={t('NotificationEmailDescription')}
        descriptionProps={{ color: 'gray-600' }}
        mb="0.375rem"
      >
        {t('Notification email')}
      </LabelRequired>
      <TextInput
        isLoading={isLoading}
        name="email"
        id="email" placeholder={t('Enter email address')}
      />
    </Box>
  </Box>;
};


const InfoCardSectionBottom = (props) => {
  return <Flex>
    <SyncInfoForm {...props} />
    <SyncStatus {...props} />
  </Flex>;
};

const InfoCard = (props) => {
  return <>
    <InfoCardSectionTop {...props} />
    <InfoCardSectionBottom {...props} />
  </>;
};

const PostingMethod = ({ isLoading, formik }) => {
  const disabled = isLoading;
  const target = getCurrentTarget();
  const posType = usePosType();
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const connectionMode = useConnectionMode()

  return <Box padding="0.75rem">
    {(connectionMode[0] === 'Datev' || connectionMode[0] === 'Acomba') ? '' :
      <Heading3 mb="1.5rem">{t('Select posting method')}</Heading3>
    }
    {(((!posType.isKSeries) || (isInputsOnlyLayout === false)) && (connectionMode[0] !== "Acomba")) &&
      <Flex mb="1.5rem">
        {false && <PostingMethodRadio
          value="auto"
          id="postingAuto"
          label={t('Automatic')}
          disabled={disabled}
          style={{ paddingRight: '0.8rem' }}
          formik={formik}
          description={t('AutomaticDescription', { target: target.fullName })}
        />}
        <PostingMethodRadio
          value="invoice"
          id="postingInvoices"
          label={t(getKeyByPosType('Invoices by product', posType))}
          disabled={disabled}
          formik={formik}
          description={t(getKeyByPosType('InvoicesDescription', posType), {
            target: target.fullName,
          })}
        />
        <PostingMethodRadio
          value="invoiceCategory"
          id="postingCategories"
          label={t(getKeyByPosType('Invoices by category', posType))}
          disabled={disabled}
          style={{ paddingRight: '0.8rem' }}
          formik={formik}
          description={t(getKeyByPosType('InvoiceCategoriesDescription', posType), {
            target: target.fullName,
          })}
        />
      </Flex>
    }
    {(posType.isLSeries || posType.isKSeries || isInputsOnlyLayout) && (connectionMode[0] !== 'Datev') &&
      <Flex mb="1.5rem">
        <PostingMethodRadio
          value="journalEntry"
          id="postingJe"
          label={t('Journal entry')}
          disabled={disabled}
          formik={formik}
          description={t('JournalEntryDescription', {
            target: target.fullName
          })}
        />
      </Flex>
    }
  </Box>

};


const CreateReconAs = ({ isLoading, formik }) => {
  const { t } = useTranslation();
  const [, swName] = useConnectionMode();

  return <Box padding="0.75rem">
    <Label mt="0.75rem">
      {t('Create Reconciliation invoice as', { swName })}
    </Label>
    <Flex mt="1.00rem" mb="0.75rem">
      <Box mr="1.5rem">
        <ReconInvoiceRadio
          value="approved"
          label={t('Approved')}
          disabled={isLoading}
          formik={formik}
        />
      </Box>
      <ReconInvoiceRadio
        value="draft"
        label={t('Draft')}
        disabled={isLoading}
        formik={formik}
      />
    </Flex>
    <Text color="gray-500">
      {t('Create record as desc', { swName })}
    </Text>
  </Box>;
};


const ReconInvoiceRadio = ({ formik, label, disabled, ...props }) => {
  const [confirmMethods, setConfirmMethods] = useState();
  const dispatch = useDispatch();
  const { config } = useSelector(state => state.globals)
  const confirmChange = ({ event, meta }) => {
    return new Promise((resolve, reject) => {

      const handleChange = () => {
        // Clear saved forms
        dispatch(setChangedDraftnApproved(event.target.value));
        formik.setFieldValue("defaultPaymentAccount", "")
        dispatch(setConfigurations({ ...config, payments: {} }))
        resolve();
      };

      if (meta.value === '') {
        resolve();
      } else {
        setConfirmMethods({ resolve: handleChange, reject });
      }
    }).finally(() => { setConfirmMethods(null) });
  };

  return (
    <>
      {confirmMethods &&
        <ConfirmChange
          label={label}
          confirmMethods={confirmMethods}
        />
      }
      <RadioField
        name="createReconAs"
        confirmChange={confirmChange}
        type="radio"
        label={label}
        disabled={disabled}
        {...props}
      />
    </>
  );
};

const PostingMethodLoading = () => {
  return <Box padding="0.75rem">
    <Bone height="1.5rem" width="7rem" style={{ marginBottom: "1.5rem" }} />
    <Flex mb="1.5rem">
      {
        [1, 2].map(id => {
          return <PostingMethodRadioLoading key={id} />
        })
      }
    </Flex>
  </Box>;
};

const AccountingSoftwareContent = (props) => {
  const [connectionMode] = useConnectionMode();
  const posType = usePosType();
  const postingMethodShow = useSelector(state => state.forms.entities.dashboard?.values.postingMethod) || {};
  const { t } = useTranslation()

  return <Card ml="1rem">
    <CardSection>
      <Flex justifyContent={"end"}>
        <PopupButton
          text={t("Schedule time with Integration Developer to get help on setup")}
          url="https://calendly.com/posaccounting/30min"
          rootElement={document.getElementById("root")}
          className="chat"
        />
      </Flex>
      <InfoCard {...props} />
    </CardSection>
    <Divider />
    <CardSection>
      {connectionMode ?
        <PostingMethod {...props} /> :
        <PostingMethodLoading />
      }
      {(postingMethodShow !== 'journalEntry' ?
        <>
          <Divider />
          <CreateReconAs {...props} />
        </>
        :
        ''
      )}
    </CardSection>
  </Card>;
};

export const AccountingSoftware = (props) => {
  const { t } = useTranslation();

  return <AnnotatedLayout
    title={t('Accounting Software')}
    description={t('AccountingSoftwareDescription')}
    renderExtras={
      <LearnMore />
    }
    mb="2.25rem"
  >
    <AccountingSoftwareContent {...props} />
  </AnnotatedLayout>;
};