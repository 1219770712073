import { useSelector } from 'react-redux';
import usePosType from './hooks/usePosType';

const useLocation = () => {
  const locationData = useSelector(state => state.location.selected);
  return locationData;
};

export const useLocationData = () => {
  const { list, selected } = useSelector(state => state.location);
  const posType = usePosType();
  const isMultiLocation = posType.isRetail && list && (list.length > 1);

  return {
    currentLocation: selected,
    locationsList: list,
    isMultiLocation,
  };
};

export default useLocation;