import { combineReducers } from '@reduxjs/toolkit';
import locationReducer from './locationReducer';
import softwareListReducer from './softwareListReducer';
import initialParamsReducer from './initialParamsReducer';
import portalInfoReducer from './portalInfoReducer';
import formsReducer from './formsReducer';
import accountsReducer from './accountsReducer';
import globalsReducer from './globalsReducer';

const rootReducer = combineReducers({
  location: locationReducer,
  softwareList: softwareListReducer,
  initialParams: initialParamsReducer,
  portalInfo: portalInfoReducer,
  forms: formsReducer,
  accounts: accountsReducer,
  globals: globalsReducer,
});

export default rootReducer;