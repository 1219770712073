import lightspeedLogo from '../images/logo_lightspeed.svg';
import revelLogo from '../images/logo_revel.png';

export const targetLogo = {
  ls: lightspeedLogo,
  retail: lightspeedLogo,
  revel: revelLogo,
};

export const targetFullNames = {
  ls: 'Lightspeed',
  revel: 'Revel',
  mindbody: 'Mindbody',
  retail: 'Lightspeed',
};

export const getCurrentTarget = () => {
  const target = process.env.REACT_APP_TARGET;
  return {
    name: target,
    fullName: targetFullNames[target],
    logo: targetLogo[target],
    isLs: target === 'ls',
    isRevel: target === 'revel',
    isMindbody: target === 'mindbody',
    isEposNow: target === 'eposnow',
    isRetail: target === 'retail',
  }
};
