import { createSlice } from '@reduxjs/toolkit';
import { isNumberSame } from '../util';
import isEqual from 'react-fast-compare';

const initialState = {
  list: [],
  selected: {},
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocationList: (state, action) => {
      if (!isEqual(state.list, action.payload)) {
        state.list = action.payload;
        if (Object.keys(state.selected).length > 0) {
          const location = state.list.find(location => {
            return isNumberSame(location.id, state.selected.id);
          });
          if (location && (!isEqual(location, state.selected))) {
            state.selected = location;
          }
        }
      }
    },
    setLocation: (state, action) => {
      const location = state.list.find(location => {
        return isNumberSame(location.id, action.payload.id);
      });
      if (location) {
        state.selected = location;
      }
    },
    resetLocation: (state) => {
      state.selected = null;
    },
  }
});

export const {
  setLocationList,
  setLocation,
  resetLocation,
} = locationSlice.actions;

export default locationSlice.reducer;
