import { useState, useEffect } from 'react';
import { Flex, Box } from '@lightspeed/flame/Core';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import { useToasts } from '@lightspeed/flame/Toaster';
import { Button } from '@lightspeed/flame/Button';
import { Label } from '@lightspeed/flame/FormField';
import { Input } from '@lightspeed/flame/Input';
import { Card, CardHeader, CardSection, CardFooter } from '@lightspeed/flame/Card';
import { Text, TextLink } from '@lightspeed/flame/Text';
import { Modal, ModalBody, ModalHeader, ModalFooter } from '@lightspeed/flame/Modal';
import { getCurrentTarget } from '../../util/index';
import { Freshchat } from 'reactjs-freshchat';
import 'reactjs-freshchat/dist/index.css';

export const RedirectModal = ({ closeModal, openLink }) => {
  return <Modal
    isOpen={true}
    onRequestClose={closeModal}
  >
    <ModalHeader onCloseClicked={closeModal}>
      Redirect Alert
    </ModalHeader>
    <ModalBody scroll={true}>
      <Box width={400}>
        You'll be redirected automatically.
        In case you were not redirected, click the "Go" button to redirect manually
      </Box>
    </ModalBody>
    <ModalFooter>
      <Flex justifyContent="flex-end">
        <Button onClick={openLink} variant="primary" fill="true">Go</Button>
        <Button onClick={closeModal} ml={2}>Close</Button>
      </Flex>
    </ModalFooter>
  </Modal>;
};

const signupRevel = (payload, addToast, setRedirectLink, setSubmitting) => {

  import('../../api/revel/signup').then(({ sendSignupData }) => {
    sendSignupData(payload).then(res => {

      const resData = res?.data?.data
      const message = resData && resData.message ? resData.message : 'User registered';
      addToast(message, { appearance: 'success' });
      console.log(resData)

      setRedirectLink(resData?.oauthURL);
    }).catch(err => {

      console.log({ ...err })

      if (err.response) {
        const data = err.response.data;
        const message = (data && data.errorMessage) || 'Error signing up';
        addToast(message, { appearance: 'error' });
      } else {
        addToast('Failed to connect to server', { appearance: 'error' });
      }
      setSubmitting(false);
    });
  }).catch(() => {
    addToast('Build error', { appearance: 'error' });
  });
};

const signupMindbody = (payload, addToast, setSubmitting, setRedirectLink) => {

  const handleError = (res) => {
    const data = res.data;
    const message = (data && data.errorMessage) || 'Error signing up';
    addToast(message, { appearance: 'error' });
  };

  import('../../api/mindbody/signup').then(({ sendSignupData }) => {
    sendSignupData(payload).then(res => {
      const resData = res && res.data;
      if (resData && resData.mindBodyActivationLink) {
        setRedirectLink(resData.mindBodyActivationLink);
      } else {
        handleError(res || {});
        setSubmitting(false);
      }
    }).catch(err => {
      if (err.response) {
        handleError(err.response);
      } else {
        addToast('Failed to connect to server', { appearance: 'error' });
      }
      setSubmitting(false);
    });
  }).catch(() => {
    addToast('Build error', { appearance: 'error' });
  });
};

const createPayload = (values, target) => {
  const payload = {
    email: values.email,
    password: values.password,
    firstName: values.firstName,
    lastName: values.lastName,
    contactNumber: values.phone,
  };

  if (target.isRevel) {
    payload.domainName = values.domain;
    payload.apiKey = values.key;
    payload.secret = values.secret;
  } else {
    payload.siteId = values.siteId;
    payload.connectionMode = values.connectionMode;
  }

  return payload;
};

const getInitialValues = (target) => {
  const values = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPass: '',
    phone: '',
  };

  let targetFields = [];

  if (target.isRevel) {
    targetFields = ['domain', 'key', 'secret'];
  } else if (target.isMindbody) {
    targetFields = ['siteId', 'connectionMode'];
  }

  targetFields.forEach(fieldName => {
    values[fieldName] = '';
  });

  return values;
};

const TextInput = (props) => {
  const [field, meta, helpers] = useField(props);
  const [errMsg, setErrMsg] = useState();

  const { id, formik } = props

  const isPassField = ["password", "confirmPass"].includes(id)

  let status = {};

  if (Boolean(errMsg)) {
    status = { type: 'error', message: errMsg };
  }

  if (meta.touched && meta.error) {
    if (isPassField) {
      if (Boolean(errMsg)) {
        status = { type: 'error', message: errMsg };
      }
    } else {
      status = { type: 'error', message: meta.error };
    }
  }

  const handleOnValueChange = (evt) => {
    const val = evt.target.value

    if (isPassField) {

      let msg;

      if (Boolean(val)) {
        // need to contain atleast one upper case letter
        if (!(/[A-Z]/.test(val))) {
          msg = "Password should contain one capital letter"

          // need to contain atleast one lower case letter
        } else if (!(/[a-z]/.test(val))) {
          msg = "Password should contain one small letter"

          // need to check for on number
        } else if (!(/[0-9]/.test(val))) {
          msg = "Password should contain one number"

          // need to check for one special letter
        } else if (!(/[^a-zA-Z0-9]/.test(val))) {
          msg = "Password should contain one special character"

          // pwd need to be more than 8 character
        } else if (val?.length < 8) {
          msg = "Password should be more than 8 characters"

          // pwd need to be more than 8 character
        } else if (val?.length > 16) {
          msg = "Password should be less than 16 characters"

        } else if (["confirmPass"].includes(id)) {
          if (formik?.values?.password !== val) {
            msg = "Confirm password isn't matching with password"
          }
        }
      } else {
        msg = "Password is required"

      }

      setErrMsg(msg)

      helpers.setValue(val)
    } else {

      helpers.setValue(val)
    }
  }

  return (
    <>
      <Input
        status={status} {...field} {...props}
        value={field.value === undefined ? '' : field.value}
        onChange={handleOnValueChange}
      />
      {["password"].includes(id) && <Text mt={".4rem"} fontWeight={"700"} ml={".1rem"} fontSize={"11.5px"} color={"#524d4d91"}>
        Note : Password should have at least 8 characters and be less than 16. It should also contain one capital letter, one small letter, one number, and one special character.
      </Text>}
    </>
  );
};

const createValidationSchema = (target) => {
  const schemaObject = {
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    firstName: Yup.string()
      .required('First name is required'),
    lastName: Yup.string()
      .required('Last name is required'),
    password: Yup.string()
      .required('Password is required')
      .max(16, 'No more than 16 characters')
      .min(6, 'At least 6 characters required')
      .matches(
        '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$@$!%*?&]).{6,16}',
        'Must have one capital character, one number and one special character'
      ),
    confirmPass: Yup.string()
      .required('Please confirm your password')
      .oneOf([Yup.ref('password')], 'Does not match password'),
    phone: Yup.string()
      .required('Phone number is required')
      .matches(/^\d+$/, 'Only numeric digits are accepted')
      .length(10, 'Phone number must have 10 digits'),
  };

  if (target.isRevel) {
    schemaObject.domain = Yup.string()
      .required('Domain is required');
    schemaObject.key = Yup.string()
      .required('Key is required');
    schemaObject.secret = Yup.string()
      .required('Secret is required');
  } else if (target.isMindbody) {
    schemaObject.siteId = Yup.string()
      .required('Site ID is required');
    schemaObject.connectionMode = Yup.string()
      .required('Connection Mode is required');
  }

  return Yup.object().shape(schemaObject);
};

const SignupForm = ({ formik, target }) => {

  return <Form>
    {target.isRevel &&
      <>
        <Box mb={3}>
          <Label htmlFor="domain">Domain</Label>
          <TextInput id="domain" name="domain" placeholder="Enter URL" formik={formik} />
        </Box>
        <Box mb={3}>
          <Label htmlFor="key">Key</Label>
          <TextInput id="key" name="key" placeholder="Enter key" formik={formik} />
        </Box>
        <Box mb={3}>
          <Label htmlFor="secret">Secret</Label>
          <TextInput id="secret" name="secret" placeholder="Enter secret" formik={formik} />
        </Box>
      </>
    }
    {target.isMindbody &&
      <>
        <Box mb={3}>
          <Label htmlFor="siteId">Site ID</Label>
          <TextInput id="siteId" name="siteId" placeholder="Enter Site ID" formik={formik} />
        </Box>
        <Box mb={3}>
          <Label htmlFor="connectionMode">Connection Mode</Label>
          <TextInput id="connectionMode" name="connectionMode" placeholder="Enter connection mode" formik={formik} />
        </Box>
      </>
    }
    <Box mb={3}>
      <Label htmlFor="firstName">First Name</Label>
      <TextInput id="firstName" name="firstName" placeholder="Enter first name" formik={formik} />
    </Box>
    <Box mb={3}>
      <Label htmlFor="lastName">Last Name</Label>
      <TextInput id="lastName" name="lastName" placeholder="Enter last name" formik={formik} />
    </Box>
    <Box mb={3}>
      <Label htmlFor="email">Email</Label>
      <TextInput id="email" name="email" placeholder="Enter email" formik={formik} />
    </Box>
    <Box mb={3}>
      <Label htmlFor="password">Password</Label>
      <TextInput id="password" name="password" placeholder="Enter password" type="password" formik={formik} />
    </Box>
    <Box mb={3}>
      <Label htmlFor="confirmPass">Confirm Password</Label>
      <TextInput id="confirmPass" name="confirmPass" placeholder="Confirm password" type="password" formik={formik} />
    </Box>
    <Box mb={5}>
      <Label htmlFor="phone">Contact</Label>
      <TextInput id="phone" name="phone" placeholder="Enter phone number" formik={formik} />
    </Box>
    <Button variant="primary" type="submit" fill={true}
      disabled={!formik.isValid}
      loading={formik.isSubmitting}
      width="100%"
      mb={2}
    >
      Submit
    </Button>
  </Form>;
};

export const Signup = (props) => {
  const [redirectLink, setRedirectLink] = useState();
  const { addToast } = useToasts();
  const target = getCurrentTarget();

  const moveToLogin = () => {
    props.navigate('../login');
  };

  const closeModal = () => {
    moveToLogin();
  };

  const openLink = () => {
    window.open(redirectLink, '_blank');
  };

  const onSubmit = (values, { setSubmitting }) => {
    const payload = createPayload(values, target);

    if (target.isRevel || target.isRetail) {
      signupRevel(payload, addToast, setRedirectLink, setSubmitting);
    } else {
      signupMindbody(payload, addToast, setSubmitting, setRedirectLink);
    }
  };

  useEffect(() => {
    if (redirectLink) {
      openLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectLink]);

  return <>
    {redirectLink && <RedirectModal closeModal={closeModal} openLink={openLink} />}

    <Flex justifyContent="center">
      <Box width={450} marginTop={50}>
        <Card>
          <CardHeader title="Signup" />
          <CardSection>
            <Formik
              initialValues={getInitialValues(target)}
              validationSchema={createValidationSchema(target)}
              onSubmit={onSubmit}
              validateOnChange
            >
              {formik =>
                <SignupForm formik={formik} target={target} {...props} />
              }
            </Formik>
          </CardSection>
          <CardFooter>
            <Flex justifyContent="center">
              Already have an account? <TextLink ml={1} onClick={moveToLogin}>Login</TextLink>
            </Flex>
          </CardFooter>
        </Card>
      </Box>
      <Freshchat
        token={'00b6ddea-bee5-4a57-b767-e51cb812490d'}
      />
    </Flex>
  </>;
};
