import { Box, Flex } from '@lightspeed/flame/Core';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import { useTranslation } from 'react-i18next';
import useAccounts from '../../util/useAccounts';

const HalfBox = ({ children }) => {
  return <Box width="50%" ml="0.75rem" mr="0.75rem">
    {children}
  </Box>;
};

export const ShippingSection = (props) => {
  const [isLoading, accountsList] = useAccounts('shipping');
  const { t } = useTranslation();

  return <Box>
    <Flex>
      <HalfBox>
        <LabelRequired
          htmlFor="shippingAccount"
        >
          {t('Shipping')}
        </LabelRequired>
        <Autocomplete
          id="shippingAccount"
          name="shippingAccount"
          isLoading={isLoading}
          options={accountsList}
          placeholder={t('Select an account')}
        />
      </HalfBox>
      <HalfBox>
        <LabelRequired
          htmlFor="freightChargesAccount"
        >
          {t('Freight charges')}
        </LabelRequired>
        <Autocomplete
          id="freightChargesAccount"
          name="freightChargesAccount"
          isLoading={isLoading}
          options={accountsList}
          placeholder={t('Select an account')}
        />
      </HalfBox>
    </Flex>
    <Flex mt="1.5rem">
      <HalfBox>
        <LabelRequired
          htmlFor="otherExpensesAccount"
        >
          {t('Other expenses')}
        </LabelRequired>
        <Autocomplete
          id="otherExpensesAccount"
          name="otherExpensesAccount"
          isLoading={isLoading}
          options={accountsList}
          placeholder={t('Select an account')}
        />
      </HalfBox>
      <HalfBox></HalfBox>
    </Flex>
  </Box>
};
