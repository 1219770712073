import { useEffect, useState } from 'react';
import { Spinner } from '@lightspeed/flame/Spinner'
import { Heading1, Text } from '@lightspeed/flame/Text';
import { parse as parseQuery } from 'query-string';
import { fetchCompanyDetails } from '../../api/eposNow/authorize';
import { moveToNextScreen } from '../../util/navigation';
import { useDispatch } from 'react-redux';
import {
  authorizeId as authorizeIdRequest,
} from '../../api/authorize';
import { useToasts } from '@lightspeed/flame/Toaster';
import { saveCompanyToken } from '../../util/api';
import useConnectionMode from '../../util/useConnectionMode';

const LoadingScreen = () => {
  return (
    <>
      <Heading1>Authorizing...</Heading1>
      <Spinner size="40px" />
    </>
  );
}

const ErrorStatus = (props) => {
  return (
    <>
      <Heading1>Error</Heading1>
      <Text>
        Something went wrong. Please try again later.
      </Text>
    </>
  );
};

export const AuthorizeEpos = (props) => {
  const states = {
    loading: 1,
    error: 2,
  };
  const [state, setState] = useState(states.loading);
  const params = parseQuery(props.location.search);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [, , setConnectionMode] = useConnectionMode();

  const handleCompanyInfo = (companyId, appId) => {
    const companyToken = `${appId}_${companyId}`;
    saveCompanyToken(companyToken);

    authorizeIdRequest().then(res => {
      const resData = res.data;
      setConnectionMode(resData.connectionMode);
      moveToNextScreen(props.navigate, dispatch, './dashboard', resData);
    }).catch(err => {
      addToast('Failed to fetch data', { appearance: 'error' });
      setState(states.error);
    });
  };

  const getCompanyDetails = (appToken) => {
    setState(states.loading);
    fetchCompanyDetails(appToken).then(res => {
      const resData = res.data;
      if (resData) {
        const companyId = resData['CompanyGuid'];
        const appId = resData['AppLocationId'];

        if (companyId && appId) {
          handleCompanyInfo(companyId, appId);
        } else {
          setState(states.error);
        }
      } else {
        setState(states.error);
      }
    }).catch(err => {
      addToast('Failed to fetch data', { appearance: 'error' });
      setState(states.error);
    });
  };

  const startAuth = () => {
    const appToken = params && params.appToken;

    const paramsExist = Boolean(appToken);
    if (paramsExist) {
      getCompanyDetails(appToken);
    } else {
      addToast('Missing app identifier', { appearance: 'error' });
      setState(states.error);
    }
  };

  // Start auth automatically on load
  useEffect(() => {
    startAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{
      display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%',
      flexDirection: 'column'
    }}>
      {state === states.loading && <LoadingScreen />}
      {state === states.error && <ErrorStatus />}
    </div>
  );
};