import {
  getAccountsUrl,
  getItemsUrl,
  getTaxesUrl,
  getPaymentMethodsUrl,
  getLocationsUrl,
  getDiscountsUrl,
  getTaxAgencyUrl,
  getClassesUrl
} from './urls';
import {
  itemsUrl as itemsUrlRevel,
  taxesUrl as taxesUrlRevel,
  paymentMethodsUrl as paymentMethodsUrlRevel,
} from './revel/urls';
import axios from 'axios';
import { getHeaders } from '../util/api';
import { getCurrentTarget } from '../util';

const target = getCurrentTarget();

export const getTaxAgency = (location) => {
  return axios.get(getTaxAgencyUrl(), {
    headers: getHeaders(location)
  })
};

export const getAccounts = (location) => {
  return axios.get(getAccountsUrl(), {
    headers: getHeaders(location),
  });
};

export const getItemsList = (params, location) => {
  const url = target.isRevel ? itemsUrlRevel : getItemsUrl();
  return axios.get(url, {
    headers: getHeaders(location),
    params,
  });
};

export const getTaxesMappingList = (params, location) => {
  const url = target.isRevel ? taxesUrlRevel : getTaxesUrl();
  return axios.get(url, {
    headers: getHeaders(location),
    params,
  });
};

export const getPaymentMethodsList = (params, location) => {
  const url = target.isRevel ? paymentMethodsUrlRevel : getPaymentMethodsUrl();
  return axios.get(url, {
    headers: getHeaders(location),
    params,
  });
};

export const getLocationsList = (location) => {
  return axios.get(getLocationsUrl(), {
    headers: getHeaders(location),
    params: { class: false }
  });
};

export const getClassesList = (location) => {
  return axios.get(getClassesUrl(), {
    headers: getHeaders(location),
    params: { class: true }
  })
}

export const getDiscountsList = (location) => {
  return axios.get(getDiscountsUrl(), {
    headers: getHeaders(location),
  });
};

