import { useState } from 'react';
import { Box, Flex } from '@lightspeed/flame/Core';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import useConnectionMode from '../../util/useConnectionMode';
import { useTranslation } from 'react-i18next';
import { SwitchLayout } from '../custom/SwitchLayout';
import useAccounts from '../../util/useAccounts';
import { CreateAccount } from '../createAccount/CreateAccount';


export const Tipping = ({ formik, }) => {
  const [isLoading, accountsList] = useAccounts('tipping');
  const {isMapTipping} = formik.values;
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();
 
 
  const [createAccountData, setCreateAccountData] = useState({
    isOpen: false,
    accountName: '',
  });
  
  const handleCreateAccount = (newAccountName) => {
    setCreateAccountData({
      isOpen: true,
      accountName: newAccountName,
    });
  };

  const closeAccountModal = () => {
    setCreateAccountData({ isOpen: false });
  };

  return <SwitchLayout
    heading={t('Tipping')}
    description={t('TippingDesc', { swName })}
    switchFieldName="isMapTipping"
    isSwitchOn={isMapTipping}
    isLoading={isLoading}
  >
    {
      createAccountData.isOpen === true &&
      <CreateAccount
        accountType="Expense"
        closeModal={closeAccountModal}
        accountName={createAccountData.accountName}
      />
    }
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="tippingsAccount">
          {t('tippingInputLabel')}
        </LabelRequired>
        <Autocomplete
          formik={formik}
          id="tippingsAccount"
          name="tippingsAccount"
          isLoadingOptions={isLoading}
          options={accountsList}
          placeholder={t('Select or create a new account')}
          onCreateOption={handleCreateAccount}
        />
      </Box>
    
    </Flex>
  </SwitchLayout>;
};