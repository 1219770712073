import freshBooksLogo from '../images/softwares/FreshBooks-logo.png';
import msDynamics254Logo from '../images/softwares/microsoft-dynamics-365-logo.png';
import msdNavLogo from '../images/softwares/Microsoft-Dynamics-NAV-logo.png';
import msdGpLogo from '../images/softwares/ms-dynamics-gp-logo.png';
import netsuiteLogo from '../images/softwares/netsuite-logo-600x500.png';
import xeroLogo from '../images/softwares/logo_xero.svg';
import qbdLogo from '../images/softwares/QBD.png';
import quickbookOnlineLogo from '../images/softwares/logo_quickbooks.svg';
import zohoBooksLogo from '../images/softwares/zoho-books.png';
import sageLogo from '../images/softwares/logo_sage.svg';
import exactLogo from '../images/softwares/logo_exact.svg';
import myobLogo from '../images/softwares/logo_myob.svg';
import datevLogo from '../images/softwares/logo_datev.svg';
import acombaLogo from "../images/softwares/logo_acomba.png";

export const softwareImages = {
  1: freshBooksLogo,
  2: msDynamics254Logo,
  3: msdNavLogo,
  4: msdGpLogo,
  15: netsuiteLogo,
  'Xero': xeroLogo,
  7: qbdLogo,
  'Online': quickbookOnlineLogo,
  'QB Desktop Edition': quickbookOnlineLogo,
  'Sage One': sageLogo,
  5: zohoBooksLogo,
  'Sage50': sageLogo,
  'Sage200': sageLogo,
  'Exact Online': exactLogo,
  'Myob': myobLogo,
  'Datev': datevLogo,
  'Acomba': acombaLogo
};
