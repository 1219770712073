import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MapGeneric } from '../mapGeneric/MapGeneric';
import { getPaymentMethodsList } from '../../api/advancedConfig';
import useLocation from '../../util/useLocation';
import useAccounts from '../../util/useAccounts';
import useConnectionMode from '../../util/useConnectionMode';
import { CreateAccount } from '../createAccount/CreateAccount';
import { getCurrentTarget } from '../../util';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../../util/hooks/useMessage';

export const OverShortIndividual = (props) => {
  const { isChangedPostingMethod } = useSelector(state => state.forms.meta);
  const fetchedMapping = isChangedPostingMethod ? null : props.location.state.fetchedMapping;
  const location = useLocation();
  const [, overShortAccounts] = useAccounts('overShort');
  const { overShortAccount: defaultAccountValue } = useSelector(state =>
    state.forms.entities.dashboard?.values
  ) || {};
  const placeholderAccount = defaultAccountValue && overShortAccounts.find(account => (account.value === defaultAccountValue));
  const placeholder = placeholderAccount && placeholderAccount.label;
  const [, softwareName] = useConnectionMode();
  const [createAccountData, setCreateAccountData] = useState({
    isOpen: false,
    accountName: '',
  });
  const target = getCurrentTarget();
  const { t } = useTranslation();
  const { addMessage } = useMessage();

  const handleCreateAccount = (newAccountName) => {
    setCreateAccountData({
      isOpen: true,
      accountName: newAccountName,
    });
  };

  const closeAccountModal = () => {
    setCreateAccountData({ isOpen: false });
  };

  const config = [
    {
      title: t('LsOverShort', { target: target.fullName }),
      name: 'payment',
    },
    {
      title: t('SwOverShort', { swName: softwareName }),
      name: 'account',
      options: overShortAccounts,
      type: 'dropdown',
      placeholder: placeholder,
      onCreateOption: handleCreateAccount,
    }
  ];

  const handleError = (res) => {
    addMessage(res, { default: t('Fetch failure') });
  };

  const fetchRows = () => {
    return getPaymentMethodsList({}, location.id).then(res => {
      let rowsList;
      if (res.data) {
        const resData = res.data;
        rowsList = resData.map(rowInfoRaw => {
          const formValues = {
            [config[0].name]: rowInfoRaw.name,
            [config[1].name]: (fetchedMapping && fetchedMapping[rowInfoRaw.id]) || '',
            id: rowInfoRaw.id,
          };

          return formValues;
        });

        return rowsList;
      } else {
        handleError(res);
      }
    }).catch(err => {
      handleError(err.response);
    });
  };

  return <>
    {createAccountData.isOpen === true &&
      <CreateAccount
        accountType="Expense"
        closeModal={closeAccountModal}
        accountName={createAccountData.accountName}
      />
    }
    <MapGeneric
      pageTitle={t('Map OverShort')}
      pageDescription={t('OverShortIndividualDesc', { swName: softwareName })}
      title={t('OverShort')}
      description={t('OverShortAnnotation', { swName: softwareName })}
      config={config}
      fetchRows={fetchRows}
      name="overShortMapping"
      persistFormName="overShortMapping"
      {...props}
    />
  </>;
};
