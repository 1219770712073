import { useState } from 'react';
import { TextLink, Heading3, Text } from '@lightspeed/flame/Text';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Bone } from '@lightspeed/flame/Bone';
import { Label } from '@lightspeed/flame/FormField';
import { SelectField } from '../fields';
import { Autocomplete } from '../fields';
import useAccounts from '../../util/useAccounts';
import useConnectionMode from '../../util/useConnectionMode';
import { useToasts } from '@lightspeed/flame/Toaster';
import useAppSubmitting from '../../util/useAppSubmitting';
import { LabelRequired } from '../custom/LabelRequired';
import { CreateAccount } from '../createAccount/CreateAccount';
import { useTranslation } from 'react-i18next';
import useIsInputsOnlyLayout from '../../util/hooks/useIsInputsOnlyLayout';
import usePosType from '../../util/hooks/usePosType';

export const MapPaymentMethods = ({ fetchedConfig, formik, ...props }) => {
  const [isAppSubmitting] = useAppSubmitting();
  const [, softwareName] = useConnectionMode();
  const [isLoadingAccounts, accountsList] = useAccounts('payment');
  const [, itemAccountsList]=useAccounts('items');
  const { addToast } = useToasts();
  const { defaultPaymentAccount: defaultAccount } = formik.values;
  const [createAccountData, setCreateAccountData] = useState({
    isOpen: false,
    accountName: '',
  });
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const isLoading = isInputsOnlyLayout ? false : isLoadingAccounts;
  const posType = usePosType();

  const handleCreateAccount = (newAccountName) => {
    setCreateAccountData({
      isOpen: true,
      accountName: newAccountName,
    });
  };

  const closeAccountModal = () => {
    setCreateAccountData({ isOpen: false });
  };

  const gotoPaymentMapping = () => {
    const state = {
      fetchedMapping: fetchedConfig && fetchedConfig.payments,
    };

    props.navigate('../payment-method', { state });
  };

  const handleNavigation = () => {
    if (isAppSubmitting) {
      addToast(t('Please wait for data to save'), { appearance: 'error' });
    } else if (defaultAccount) {
      gotoPaymentMapping();
    } else {
      addToast(t(isInputsOnlyLayout ? 'DefaultAccountTextInputFirst' : 'SelectPaymentFirst'), {
        appearance: 'error'
      });
    }
  };

  return <Box padding="0.75rem">
    {createAccountData.isOpen === true &&
      <CreateAccount
        accountType="Bank"
        closeModal={closeAccountModal}
        accountName={createAccountData.accountName}
      />
    }
    <Heading3 mb="0.375rem">{t('Payment types')}</Heading3>
    <Text color="gray-600" mb="1.5rem" size="small">
      {t('DefaultPaymentDesc', { swName: softwareName })}
    </Text>
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="defaultPaymentAccount">
          {t('Default payment account')}
        </LabelRequired>
        <Autocomplete
          id="defaultPaymentAccount"
          name="defaultPaymentAccount"
          isLoading={isLoading}
          options={
            (formik.values.createReconAs === 'approved' ? accountsList : itemAccountsList)
          }
          formik={formik}
          placeholder={
            t(isInputsOnlyLayout ? 'Enter payment account' : 'Select an account')
          }
          onCreateOption={handleCreateAccount}
        />
      </Box>
      <Flex mt="1.4rem" alignItems="center" height="2.25rem">
        {isLoading ?
          <Bone height="1rem" width="12rem" /> :
          <TextLink size="small" fontWeight="bold"
            onClick={handleNavigation}
          >
            {t('Map individual payment types')}
          </TextLink>
        }
      </Flex>
    </Flex>
    {(!posType.isRetail) && (softwareName!=="Datev")?
      <Box width="49%" pr="1.1rem" mt="1.5rem">
        <Label htmlFor="splitCreditPayments">
          {t('SplitCardPayment')}
        </Label>
        <SelectField id="splitCreditPayments" name="splitCreditPayments" disabled={isLoading}>
          <option value="0">{t('No')}</option>
          <option value="1">{t('Yes')}</option>
        </SelectField>
      </Box>
      :
      ''
    }
  </Box>;
};