import { useState, useEffect } from 'react';
import { AnnotatedLayout } from '@lightspeed/flame/Layout';
import { TextLink, Text } from '@lightspeed/flame/Text';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Divider } from '@lightspeed/flame/Divider';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Bone } from '@lightspeed/flame/Bone';
import { PageLayout } from '../pageLayout/PageLayout';
import { useSelector } from 'react-redux';
import { getTaxesMappingList, } from '../../api/advancedConfig';
import useLocation from '../../util/useLocation';
import { FieldArray, Formik, Form } from 'formik';
import { Autocomplete } from '../fields';
import { usePersist } from '../../util/usePersist';
import useConnectionMode from '../../util/useConnectionMode';
import { RefreshAccountsSkeleton } from '../refreshAccounts/RefreshAccounts';
import { useShowTaxError, useIgnoreMapping } from '../../util/hooks/useShowTaxError';
import { getCurrentTarget } from '../../util';
import { useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';
import { useMessage } from '../../util/hooks/useMessage';
import { useJeAsCategory } from '../../util/hooks/useJeAsCategory';

const RefreshAccounts = ({ handleClick }) => {
  return <RefreshAccountsSkeleton handleClick={handleClick} />
};

const DropdownInput = ({ name, options, hasError }) => {
  const isLoading = !options;
  const { t } = useTranslation();

  return <>
    {isLoading ?
      <Bone height="2em" /> :
      <Autocomplete
        name={name}
        isLoadingOptions={isLoading}
        options={options}
        placeholder={t('Select tax account')}
        hasError={hasError}
      />
    }
  </>;
};

const TableCell = ({ data, name, options, config, canShowError }) => {
  const type = config.type || 'label';
  let hasError = false;

  if (canShowError && [null, undefined, ''].includes(data)) {
    hasError = true;
  }

  return <>
    {
      type === 'label' ?
        <Flex alignItems="center" height="100%">
          <Text>{data}</Text>
        </Flex> :
        <DropdownInput name={name} options={options} canShowError={canShowError} hasError={hasError} />
    }
  </>;
};

const MappingRow = ({ data, type, config, name, rowIndex, canShowError }) => {
  const isLoading = !data;
  const numCells = config.length;
  const width = `${100 / numCells}%`;

  const getKey = (index, fieldName) => {
    return `${name}.${index}.${fieldName}`;
  };

  const content = config.map((columnData, columnIndex) => {
    const { field, options } = columnData;
    const cellName = getKey(rowIndex, field);

    return <Box width={width} key={columnIndex} pl="0.8rem" pr="0.8rem">
      {type === 'header' ?
        <Text fontWeight="bold">{config[columnIndex].name}</Text> :
        <>
          {isLoading ?
            <Bone height="2rem" /> :
            <TableCell
              config={columnData}
              data={data[field]}
              options={options}
              name={cellName}
              canShowError={canShowError}
            />
          }
        </>
      }
    </Box>;
  });

  return <>
    <CardSection>
      <Flex>
        {content}
      </Flex>
    </CardSection>
    <Divider />
  </>;
};

const TableData = ({ config, formik, name, arrayHelpers, canShowError }) => {
  const rowsData = formik.values[name];

  return <>
    {
      rowsData && rowsData.length > 0 ?
        rowsData.map((rowData, index) => {
          return <MappingRow
            data={rowData}
            key={index}
            rowIndex={index}
            name={name}
            config={config}
            canShowError={canShowError}
          />
        })
        :
        <MappingRow config={config} />
    }
  </>;
};

const MapIndividualPurchaseTaxContent = (props) => {
  const {
    config,
    name,
    onSuccessfulSaveSubject,
    setInitialValues,
    formik,
  } = props;
  usePersist(formik, 'purchaseTaxMapping');
  const [updateInitialValuesPending, setUpdateInitialValuesPending] = useState(false);

  // Update initialValues to the current values
  useEffect(() => {
    if (updateInitialValuesPending) {
      setUpdateInitialValuesPending(false);
      setInitialValues(Object.assign({}, formik.values));
    }
  }, [formik.values, setInitialValues, updateInitialValuesPending]);

  // Register subscriptions. This should be run only once to avoid re-subscribing which will throw error
  useEffect(() => {
    onSuccessfulSaveSubject.subscribe(() => {
      setUpdateInitialValuesPending(true);
    });

    return () => {
      onSuccessfulSaveSubject.unsubscribe();
    }
  }, [onSuccessfulSaveSubject]);

  return <Form>
    <Card ml="1rem">
      <MappingRow config={config} type="header" />
      <FieldArray name={name}>
        {
          arrayHelpers =>
            <TableData
              {...props}
              arrayHelpers={arrayHelpers}
            />
        }
      </FieldArray>
    </Card>
  </Form>;
};

export const MapIndividualPurchaseTax = (props) => {
  const { ignoreFetchedMapping } = useIgnoreMapping();
  const fetchedMapping = ignoreFetchedMapping ? null : props.location.state.fetchedMapping;
  const savedForm = useSelector(state => state.forms.entities.purchaseTaxMapping);
  const dashboardForm = useSelector(state => state.forms.entities.dashboard);
  const [, softwareName] = useConnectionMode();
  const [accountsList, setAccountsList] = useState();
  const currentLocation = useLocation();
  const [initialValues, setInitialValues] = useState({
    purchaseTaxMapping: [],
  });
  const [isReloadAccounts, setIsReloadAccounts] = useState(false);
  const canShowError = useShowTaxError({ poTaxes: fetchedMapping }, true);
  const target = getCurrentTarget();
  const { t } = useTranslation();
  const [onSuccessfulSaveSubject] = useState(new Subject());
  const { addMessage } = useMessage();
  const [isJeAsCategory] = useJeAsCategory();

  const config = [
    {
      name: t('LsPurchaseTaxRates', { target: target.fullName }),
      field: 'taxRate',
    },
    {
      name: t('SwAccounts', { swName: softwareName }),
      field: 'account',
      type: 'dropdown',
      options: accountsList,
    },
  ];

  const handleError = (res) => {
    addMessage(res);
  };

  // Fetch all data when page loads
  useEffect(() => {
    let mounted = true;
    const params = {
      journalEntry: (!isJeAsCategory) && (dashboardForm.values.postingMethod === 'journalEntry'),
      purchaseTaxRate: true,
    };

    getTaxesMappingList(params, currentLocation.id).then(res => {
      if (mounted) {
        let rowsList;
        if (res.data) {
          const resData = res.data;
          // Set options list
          setAccountsList(resData.accountingSoftware.map(op => {
            return {
              value: op.id,
              label: op.name,
            }
          }));

          if (savedForm && savedForm.dirty) {
            setInitialValues(savedForm.values);
          } else {
            // Set row data
            rowsList = resData.LSTaxRates.map(rowInfoRaw => {
              const formValues = {
                [config[0].field]: rowInfoRaw.name,
                [config[1].field]: (fetchedMapping && fetchedMapping[rowInfoRaw.name]) || '',
                id: rowInfoRaw.id,
              };

              return formValues;
            });

            setInitialValues({ purchaseTaxMapping: rowsList });
          }
        } else {
          handleError(res);
        }
      }
    }).catch(err => {
      if (mounted) {
        handleError(err.response);
      }
    });

    return () => { mounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Refresh accounts list
  useEffect(() => {
    let mounted = true;
    const params = {
      journalEntry: (!isJeAsCategory) && (dashboardForm.values.postingMethod === 'journalEntry'),
      purchaseTaxRate: true,
    };

    if (isReloadAccounts) {
      setAccountsList([]);
      getTaxesMappingList(params, currentLocation.id).then(res => {
        if (mounted) {
          if (res.data) {
            const resData = res.data;
            setAccountsList(resData.accountingSoftware.map(op => {
              return {
                value: op.id,
                label: op.name,
              }
            }));
          } else {
            handleError(res);
          }
        }
      }).catch(err => {
        if (mounted) {
          handleError(err.response);
        }
      }).then(() => {
        if (mounted) {
          setIsReloadAccounts(false);
        }
      });

      return () => { mounted = false; };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReloadAccounts]);

  const handleRefreshAccounts = () => {
    const isBusy = (initialValues.purchaseTaxMapping.length === 0) || isReloadAccounts;
    if (!isBusy) {
      setIsReloadAccounts(true);
    }
  };

  const handleSuccessfulSave = () => {
    // Signal to update initialValues
    onSuccessfulSaveSubject.next();
  };

  return <PageLayout
    title={t('Map purchase tax rates')}
    description={t('MapPurchaseTaxDesc', { swName: softwareName })}
    showBack={true}
    navigate={props.navigate}
    handleSuccessfulSave={handleSuccessfulSave}
  >
    <AnnotatedLayout
      title={t('Tax rates')}
      description={t('MapTaxDesc', { swName: softwareName })}
      renderExtras={
        <>
          {false && <Box>
            <TextLink size="small" fontWeight="bold">
              {t('Learn more')}
            </TextLink>
          </Box>}
          <RefreshAccounts handleClick={handleRefreshAccounts} />
        </>
      }
      mb="2.25rem"
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
      >
        {formik =>
          <MapIndividualPurchaseTaxContent
            formik={formik}
            config={config}
            name="purchaseTaxMapping"
            canShowError={canShowError}
            onSuccessfulSaveSubject={onSuccessfulSaveSubject}
            setInitialValues={setInitialValues}
            {...props}
          />
        }
      </Formik>
    </AnnotatedLayout>
  </PageLayout>;
};
