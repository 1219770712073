import { useState } from 'react';
import { Box, Flex } from '@lightspeed/flame/Core';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import useConnectionMode from '../../util/useConnectionMode';
import { useTranslation } from 'react-i18next';
import { SwitchLayout } from '../custom/SwitchLayout';
import { Bone } from '@lightspeed/flame/Bone';
import { TextLink } from '@lightspeed/flame/Text';
import { useToasts } from '@lightspeed/flame/Toaster';
import useAppSubmitting from '../../util/useAppSubmitting';
import useAccounts from '../../util/useAccounts';
import { CreateAccount } from '../createAccount/CreateAccount';
import useIsInputsOnlyLayout from '../../util/hooks/useIsInputsOnlyLayout';

export const OverShort = ({ fetchedConfig, formik, navigate }) => {
  const [isLoading, accountsList] = useAccounts('overShort');
  const {
    isMapOverShort,
    overShortAccount: defaultAccount
  } = formik.values;
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [isAppSubmitting] = useAppSubmitting();
  const [createAccountData, setCreateAccountData] = useState({
    isOpen: false,
    accountName: '',
  });
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

  const gotoIndividualMapping = () => {
    const fetchedMapping = (fetchedConfig && fetchedConfig?.overShorts) || {};
    const state = {
      fetchedMapping,
    };

    navigate('../map-over-short', { state });
  };

  const handleNavigation = () => {
    if (isAppSubmitting) {
      addToast(t('Please wait for data to save'), { appearance: 'error' });
    } else if (defaultAccount) {
      gotoIndividualMapping();
    } else {
      addToast(t(isInputsOnlyLayout ? 'DefaultAccountTextInputFirst' : 'DefaultAccountFirst'), {
        appearance: 'error'
      });
    }
  };

  const handleCreateAccount = (newAccountName) => {
    setCreateAccountData({
      isOpen: true,
      accountName: newAccountName,
    });
  };

  const closeAccountModal = () => {
    setCreateAccountData({ isOpen: false });
  };

  return <SwitchLayout
    heading={t('OverShort')}
    description={t('OverShortDesc', { swName })}
    switchFieldName="isMapOverShort"
    isSwitchOn={isMapOverShort}
    isLoading={isLoading}
  >
    {
      createAccountData.isOpen === true &&
      <CreateAccount
        accountType="Expense"
        closeModal={closeAccountModal}
        accountName={createAccountData.accountName}
      />
    }
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="overShortAccount">
          {t('OverShortInputLabel')}
        </LabelRequired>
        <Autocomplete
          formik={formik}
          id="overShortAccount"
          name="overShortAccount"
          isLoadingOptions={isLoading}
          options={accountsList}
          placeholder={t('Select an account')}
          onCreateOption={handleCreateAccount}
        />
      </Box>
      <Flex mt="1.4rem" alignItems="center" height="2.25rem">
        {isLoading ?
          <Bone height="1rem" width="12rem" /> :
          <TextLink size="small" fontWeight="bold"
            onClick={handleNavigation}
          >
            {t('Map individual Over/Short')}
          </TextLink>
        }
      </Flex>
    </Flex>
  </SwitchLayout>;
};