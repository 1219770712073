import {
  getResyncUrl,
  getPostUrl,
  getPoResyncUrl
} from './urls';
import axios from 'axios';
import { getHeaders } from '../util/api';

export const repostOrder = (payload, locationId) => {
  return axios.post(getResyncUrl(), payload, {
    headers: getHeaders(locationId),
  });
};

export const repostPoOrder = (payload, locationId) => {
  return axios.post(getPoResyncUrl(), payload, {
    headers: getHeaders(locationId),
  });
};

export const postOrder = (payload, locationId) => {
  return axios.post(getPostUrl(), payload, {
    headers: getHeaders(locationId)
  })
}