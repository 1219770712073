import { useSelector } from 'react-redux';
import { filterAccountsByKeywords } from './index';
import useConnectionMode from './useConnectionMode';

const keywordsLists = {
  'Online': {
    items: ['income', 'liability', 'sales', 'revenue', 'liabilities'],
    discount: ['expense', 'income', 'sales', 'revenue'],
    giftCard: ['liability', 'liabilities'],
    payment: ['payments'],
    tips: ['liability', 'liabilities'],
    serviceCharge: ['liability', 'revenue', 'liabilities'],
    overShort: ['expense'],
    tipping: ['expense'],
    shipping: ['expense', "Cost of Goods Sold", "Direct Costs"],
    inventory: ['asset', 'inventory'],
    cogs: ['expense', "Cost of Goods Sold", "Direct Costs"],
    adjustment: ['asset', 'expense'],
    eCom: ['income', 'liability', 'sales', 'revenue', 'liabilities']
  },
  'Xero': {
    items: ['Current', 'Sales', 'Other income', 'Revenue', 'Income'],
  },
};

const getKeywords = (accountsType, connectionMode) => {
  let keywords = keywordsLists[connectionMode] ?
    keywordsLists[connectionMode][accountsType]
    :
    keywordsLists['Online'][accountsType];

  if (!keywords) {
    keywords = keywordsLists['Online'][accountsType];
  }

  return keywords ? keywords.map(keyword => keyword.toLowerCase()) : [];
};

export const useAccounts = (accountsType) => {
  const { status, entities, error } = useSelector(state => state.accounts);
  const dashValues = useSelector(state => state?.forms?.entities?.dashboard?.values)

  let postingMethod = ''

  if (dashValues) {
    if (dashValues?.postingMethod) {
      postingMethod = dashValues?.postingMethod
    }
  }

  const [connectionMode] = useConnectionMode();
  const keywords = getKeywords(accountsType, connectionMode);
  // TODO: check
  const isReady = ((status !== 'pending') && error === null && entities && entities.length > 0) || (connectionMode === "Datev" || connectionMode === "Acomba");
  const isLoading = !isReady;

  if (postingMethod) {
    if (postingMethod === "journalEntry") {
      return [isLoading, entities]
    }
  }

  return [isLoading, filterAccountsByKeywords(entities, keywords)];
};

export default useAccounts;
     