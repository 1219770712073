import { AnnotatedLayout } from '@lightspeed/flame/Layout';
import { Card, CardSection } from '@lightspeed/flame/Card';
import { Box, Flex } from '@lightspeed/flame/Core';
import { Label } from '@lightspeed/flame/FormField';
import { LabelRequired } from '../custom/LabelRequired';
import { TextInput, SelectField } from '../fields';
import { getCurrentTarget } from '../../util';
import useConnectionMode from '../../util/useConnectionMode';
import { usePosType } from '../../util/hooks/usePosType';
import { MapDifferences } from '../mapDifferences/MapDifferences';
import { useTranslation } from 'react-i18next';
import { ClassMapping } from '../locationSection/ClassMapping';
import { OverShort } from '../overShort/OverShort';
import { Tipping } from '../tipping/Tipping';
import useLocation from '../../util/useLocation';
import { FloorMapping } from '../floorSection/Floor';
import { useSelector } from 'react-redux';
import { MultipleClass } from '../locationSection/MultipleClass';

const CustomLabel = ({ label, required, ...props }) => {
  const LabelComp = required === true ? LabelRequired : Label;
  return <LabelComp
    descriptionProps={{ marginTop: '0.375rem' }}
    {...props}
  >
    {label}
  </LabelComp>;
};

const HalfBox = ({ children }) => {
  return <Box width="50%" ml="0.75rem" mr="0.75rem">
    {children}
  </Box>;
};

const OptionsMapping = (props) => {
  const isLoading = false;
  const target = getCurrentTarget();
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();

  return <Box mt="0.75rem" mb="0.75rem">
    <Flex>
      <HalfBox>
        <CustomLabel
          label={t('Default customer name')}
          description={t('DefaultNameDescription', { swName })}
          htmlFor="customerName"
          required={true}
        />
      </HalfBox>
      <HalfBox>
        <CustomLabel
          label={t('Custom transaction number')}
          description={t('CustomerTransactionDescription', { target: target.fullName })}
          htmlFor="transactionNumber"
        />
      </HalfBox>
    </Flex>
    <Flex>
      <HalfBox>
        <TextInput
          isLoading={isLoading}
          id="customerName"
          name="customerName"
          placeholder={t('Enter the default customer name')}
        />
      </HalfBox>
      <HalfBox>
        <TextInput
          isLoading={isLoading}
          id="transactionNumber"
          name="transactionNumber"
          placeholder={t('Enter the custom transaction number')}
        />
      </HalfBox>
    </Flex>
    {false &&
      <Flex>
        <Box width="50%" pr="0.75rem">
          <CustomLabel
            label="Keep invoices open for Credit Sales"
            htmlFor="keepInvoicesOpen"
            description="Description for this setting"
          />
          <SelectField
            isLoading={isLoading}
            id="keepInvoicesOpen"
            name="keepInvoicesOpen"
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </SelectField>
        </Box>
        <Box width="50%" pl="0.75rem">
          <CustomLabel
            label="Book receipt when finalized"
            htmlFor="bookReceipts"
            description="Description for this setting"
          />
          <SelectField
            isLoading={isLoading}
            id="bookReceipts"
            name="bookReceipts"
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </SelectField>
        </Box>
      </Flex>
    }
  </Box>
};

const OptionsSectionContent = (props) => {
  const posType = usePosType();
  const [swName] = useConnectionMode();
  const locationlist = useLocation()
  const reducerState = useSelector(state =>
    state?.forms?.entities?.dashboard?.values?.isSplitRevenueRecordByFloor
  );
  const isSplitRevenueRecordByFloor = props?.formik?.values?.isSplitRevenueRecordByFloor ?? reducerState;

  const ismultipleFloor = useSelector(state => state.portalInfo.setFloorinfo)
  const isQbo = useConnectionMode()[0] === "Online"

  return <>
    <Card ml="1rem">
      <CardSection>
        <OptionsMapping />
      </CardSection>
    </Card>
    {(posType.isLSeries) && ismultipleFloor === true && swName !== "Acomba" &&
      <Card ml="1rem" mt="1.125rem">
        <CardSection>
          <FloorMapping {...props}></FloorMapping>
        </CardSection>
      </Card>
    }
    {(!(posType.isRetail)) &&
      swName !== 'Sage One' &&
      isSplitRevenueRecordByFloor === false &&
      <Card ml="1rem" mt="1.125rem">
        <CardSection>
          {isQbo ?
            <MultipleClass {...props} />
            :
            <ClassMapping {...props} />}
        </CardSection>
      </Card>
    }
    {false && posType.isKSeries && // Disabled
      <Card ml="1rem" mt="1.125rem">
        <CardSection>
          <MapDifferences {...props} />
        </CardSection>
      </Card>
    }
    {posType.isRetail &&
      <Card ml="1rem" mt="1.125rem">
        <CardSection>
          <OverShort {...props} />
        </CardSection>
      </Card>
    }
    {posType.isRetail && locationlist.tippings === true &&
      <Card ml="1rem" mt="1.125rem">
        <CardSection>
          <Tipping {...props} />
        </CardSection>
      </Card>
    }
  </>;
};

export const OptionsSection = (props) => {
  const { t } = useTranslation();

  return <AnnotatedLayout
    title={t('Additional Settings')}
    mt="2.25rem"
    mb="2.25rem"
  >
    <OptionsSectionContent {...props} />
  </AnnotatedLayout>;
};
