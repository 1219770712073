export const isNumberSame = (first, second) => {
  return `${first}` === `${second}`;
};

export const filterAccountsByKeywords = (allAccounts, keywordsList) => {
  return allAccounts.filter(accountData => {
    return keywordsList.some(keyword => {
      return accountData.type.includes(keyword);
    });
  });
};

export const getConnectionModeName = (t, connectionMode) => {
  const connectionModeName = {
    'Xero': t('Xero'),
    'Online': t('QuickBooks Online'),
    'Sage One': t('Sage One'),
    'Sage50': t('Sage 50'),
    'Sage200': t('Sage 200'),
    'QB Desktop Edition': t('QuickBooks Desktop'),
    'Exact Online': 'Exact Online',
    'Datev': t('Datev'),
    'Acomba': 'Acomba'
  };

  return connectionModeName[connectionMode] || connectionMode;
};

export const getClassObject = (value) => {
  const [id, name] = [value, value];

  return { [id]: name };
};

export const parseClassMapping = (mapping) => {
  const accountId = Object.keys(mapping)[0];
  const name = mapping[accountId];
  return name;
};

export * from './target';