import { setItem, getItem } from './storage';

let token = '';
let companyToken = '';

export const saveToken = (newToken) => {
  try {
    setItem('token', newToken);
  } catch {
    token = newToken;
  }
};

export const getToken = () => {
  let result;

  try {
    result = getItem('token');
  } catch {
    result = token;
  }

  return result;
}

export const getHeaders = (locationId) => {
  const val = {
    'Authorization': getToken(),
  };

  if (locationId) {
    val.locationId = locationId;
  }

  return val;
};

export const saveCompanyToken = (newToken) => {
  try {
    setItem('companyToken', newToken);
  } catch {
    companyToken = newToken;
  }
};

export const getCompanyToken = () => {
  let result;

  try {
    result = getItem('companyToken');
  } catch {
    result = companyToken;
  }

  return result;
}