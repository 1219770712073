import {
  getRegisterSwUrl,
  getValidateSwConnectionUrl,
} from './urls';
import { getHeaders } from '../util/api';
import axios from 'axios';

export const registerSw = (payload, locationId, isUpdating) => {
  let requestMethod = axios.post;

  if (isUpdating) {
    requestMethod = axios.put;
  }

  return requestMethod(getRegisterSwUrl(), payload, {
    headers: getHeaders(locationId),
  });
};

export const validateSwConnection = (locationId) => {
  return axios.get(getValidateSwConnectionUrl(), {
    headers: getHeaders(locationId),
  });
};

export const copyAccountData = (payload, targetLocation) => {
  return axios.post(getRegisterSwUrl(), payload, {
    headers: getHeaders(targetLocation),
  });
};
