import { useState } from 'react';
import useConnectionMode from '../../util/useConnectionMode';
import { useTranslation } from 'react-i18next';
import { SwitchLayout } from '../custom/SwitchLayout';
import { MapClassFloorCode } from './MapFloorcode';
import { Flex } from '@lightspeed/flame/Core';
import { Text } from '@lightspeed/flame/Text';

export const FloorMapping = ({ formik, fetchedConfig, ...props }) => {
  const [isLoading] = useState(false);
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();
  const { isSplitRevenueRecordByFloor } = formik.values;
  const isQbo = useConnectionMode()[0] === "Online"

  return <SwitchLayout
    heading={t('Class And Floors')}
    description={t('floordiscription', { swName })}
    switchFieldName="isSplitRevenueRecordByFloor"
    isSwitchOn={isSplitRevenueRecordByFloor}
    isLoading={isLoading}
    formik={formik}
  >
    <MapClassFloorCode
      fetchedMapping={{ fetchedMapping: fetchedConfig && fetchedConfig.floorMapping }
      }
      formik={formik}
      {...props}>
    </MapClassFloorCode>
    <Flex mt="1rem" ml=".2rem">
      <><Text fontWeight={"bold"} mr=".3rem">Required : </Text> {isQbo && "Either Location mapping or"} Class Mapping.</>
    </Flex>
  </SwitchLayout>;
};