import {
  getAuthorizeIdUrl,
  getOauthRedirectUrl,
} from './urls';
import axios from 'axios';
import { getCurrentTarget } from '../util';
import { getToken } from '../util/api';

export const authorizeIdRetail = ({ code, state }) => {
  let url = getOauthRedirectUrl() + `?code=${code}&state=${state}`

  return axios.get(url, {
    headers: {
      'Authorization': getToken(),
    }
  })
}

export const authorizeIdRevelEpos = () => {
  return axios.get(getAuthorizeIdUrl(), {
    headers: {
      'Authorization': getToken(),
    },
  });
};

export const authorizeIdLightspeed = (id, posType, sourceId, locationData) => {
  const url = getAuthorizeIdUrl() + id;
  const extras = {
    params: {
      type: posType,
      source: sourceId
    }
  };

  if (typeof locationData === 'string') {
    extras.headers = {
      locationId: locationData
    }
  }

  if (locationData && locationData.id) {
    extras.headers = {
      locationId: locationData.id
    }
  }

  return axios.get(url, extras);
};

export const authorizeId = (id, posType, sourceId, locationData) => {
  const target = getCurrentTarget();
  let result;

  if (target.isRevel || target.isEposNow) {
    result = authorizeIdRevelEpos();
  } else {
    result = authorizeIdLightspeed(id, posType, sourceId, locationData);
  }

  return result;
};

export const sendOauthToken = (params) => {
  const url = new URL(getOauthRedirectUrl());

  url.searchParams.append('code', params.code);
  url.searchParams.append('state', params.state);

  return axios.get(url);
}
