import { useState, useEffect } from 'react';
import { Box, Flex } from '@lightspeed/flame/Core';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import useLocation from '../../util/useLocation';
import { getLocationsList } from '../../api/advancedConfig';
import useConnectionMode from '../../util/useConnectionMode';
import { usePosType } from '../../util/hooks/usePosType';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../../util/hooks/useMessage';
import { SwitchLayout } from '../custom/SwitchLayout';
import useAccounts from '../../util/useAccounts';

export const EComMapping = ({ formik }) => {
  const [isLoading, accountsList] = useAccounts('eCom');

  const { isMapECom } = formik.values;
  const [, swName] = useConnectionMode();
  const posType = usePosType();
  const { t } = useTranslation();

  return <SwitchLayout
    heading={t('eCom')}
    description={t('eComDesc', { swName })}
    switchFieldName="isMapECom"
    isSwitchOn={isMapECom}
    isLoading={isLoading}
  >
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="eComAccount">
          {posType.isKSeries ?
            t('SelectClassKSeries')
            :
            t('SelectClassLSeries')
          }
        </LabelRequired>
        <Autocomplete
          id="eComAccount"
          name="eComAccount"
          isLoading={isLoading}
          options={accountsList}
          placeholder={t('Select or create a  tracking value')}
        />
      </Box>
    </Flex>
  </SwitchLayout>;
};