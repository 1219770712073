import { getCurrentTarget } from '../util/target';
import { getCompanyToken } from '../util/api';

const serverUrl = process.env.REACT_APP_SERVER_URL;
const target = getCurrentTarget();

const createUrlGetter = (endpoint, options = {}) => {
  return () => {
    let result = '';

    // EposNow requires token in URL
    let companyTokenPart = '';
    if (target.isEposNow) {
      const companyToken = getCompanyToken();
      companyTokenPart = `${companyToken}/`;
    }

    const suffix = options.noSlash ? '' : '/';
    result = `${serverUrl}/${companyTokenPart}${endpoint}${suffix}`;

    return result;
  }
}

export const getAuthorizeIdUrl = createUrlGetter('authorize');
export const getOauthRedirectUrl = createUrlGetter('company/authorize', { noSlash: true });
export const getTaxesUrl = createUrlGetter('taxes');
export const getAccountsUrl = createUrlGetter('accounts');
export const getSubmitConfigUrl = createUrlGetter('configuration');
export const getRegisterSwUrl = createUrlGetter('connectionMode');
export const getItemsUrl = createUrlGetter('items');
export const getPaymentMethodsUrl = createUrlGetter('paymentMethods');
export const getLocationsUrl = createUrlGetter('posLocations');
export const getClassesUrl = createUrlGetter('posLocations')
export const getDiscountsUrl = createUrlGetter('discounts');
export const getChangeSyncStatusUrl = createUrlGetter('sync');
export const getSalesSyncDataUrl = createUrlGetter('getSyncData');
export const getPoSyncDataUrl = createUrlGetter('getPOSyncData');
export const getSalesOrderDetailsUrl = createUrlGetter('order', { noSlash: target.isRevel });
export const getPurchaseOrderDetailsUrl = createUrlGetter('purchaseOrder');
export const getValidateSwConnectionUrl = createUrlGetter('isCompanyAuthorize');
export const getReconnectAccountUrl = createUrlGetter('company/recreate');
export const getResyncUrl = createUrlGetter('resync');
export const getPoResyncUrl = createUrlGetter('resyncPurchaseOrder');
export const getHistoricalSyncUrl = createUrlGetter('historicalSync');
export const getTransactionAnalysisUrl = createUrlGetter('transactionAnalysis');
export const getDeatactivateUserUrl = createUrlGetter('deactivateUser');
export const getTaxAgencyUrl = createUrlGetter('supplierList')
export const getPostUrl = createUrlGetter('reviewModePosting')
export const getSyncStatusUrl = createUrlGetter('syncStatus')
