import { useSelector } from 'react-redux';
import { getClassObject, parseClassMapping } from './index';
import { getCurrentTarget } from './target';

const target = getCurrentTarget();

const parseDiscountMapping = (forms, payload) => {
  if (forms.discountsMapping) {
    const values = forms.discountsMapping.values;
    payload.discountsMapping = values.discountsMapping.map(row => {
      return {
        id: row.id,
        name: row.account,
      };
    });
  }
};

const parseProductMapping = (forms, payload) => {
  const hasItemsMapping = forms.productsMapping && forms.productsMapping.dirty;
  const hasCategoriesMapping = forms.categoriesMapping && (forms.categoriesMapping.dirty || forms?.categoriesMapping?.values?.hasMappingLevelChanged);
  const itemsMapping = hasItemsMapping && forms.productsMapping.values;
  const categoriesMapping = hasCategoriesMapping && forms.categoriesMapping.values;
  const mappingType = (itemsMapping && itemsMapping.mappingType) || (categoriesMapping && categoriesMapping.mappingType);

  if (mappingType) {
    payload.recordCategoryForInvoiceByProduct = mappingType === 'category';
  }

  if (hasCategoriesMapping) {
    if (payload.recordCategoryForInvoiceByProduct) {
      payload.isParentCategory = categoriesMapping.breakCategories === 'highest';
    }

    payload.categories = {};
    const mapping = categoriesMapping.categoriesMapping;
    mapping && mapping.forEach(row => {
      payload.categories[row.id] = {
        incomeAccount: row.account,
      };
    });
  }

  if (hasItemsMapping) {
    payload.items = {};
    const mapping = itemsMapping.productsMapping;
    mapping && mapping.forEach(row => {
      payload.items[row.id] = {
        incomeAccount: row.account,
      };
    });
  }
};

const parseIndividualCogsMapping = (forms, payload) => {
  const hasItemsMapping = forms.poProductsMapping && forms.poProductsMapping.dirty;
  const hasCategoriesMapping = forms.poCategoriesMapping && (forms.poCategoriesMapping.dirty || forms?.poCategoriesMapping?.values?.hasMappingLevelChanged);
  const itemsMapping = hasItemsMapping && forms.poProductsMapping.values;
  const categoriesMapping = hasCategoriesMapping && forms.poCategoriesMapping.values;
  const mappingType = (itemsMapping && itemsMapping.mappingType) || (categoriesMapping && categoriesMapping.mappingType);

  if (mappingType) {
    payload.swapCategoryFromProducts = mappingType === 'category';
  }

  if (hasCategoriesMapping) {
    payload.swapSuperParentCategoryFromProducts = categoriesMapping ? (categoriesMapping.breakCategories === 'highest') : true;

    payload.categorySpecificInventoryCogsAccounts = {};
    const mapping = categoriesMapping.poCategoriesMapping;
    mapping && mapping.forEach(row => {
      payload.categorySpecificInventoryCogsAccounts[row.id] = {
        inventoryAccount: row.inventoryAccount,
        expenseAccount: row.cogsAccount,
      };
    });
  }

  if (hasItemsMapping) {
    payload.itemSpecificInventoryCogsAccounts = {};
    const mapping = itemsMapping.poProductsMapping;
    mapping && mapping.forEach(row => {
      payload.itemSpecificInventoryCogsAccounts[row.id] = {
        inventoryAccount: row.inventoryAccount,
        expenseAccount: row.cogsAccount,
      };
    });
  }
};

const parsePaymentMapping = (forms, payload) => {
  if (forms.paymentsMapping && forms.paymentsMapping.dirty) {
    const values = forms.paymentsMapping.values;
    payload.payments = {};
    values.paymentsMapping.forEach(row => {
      payload.payments[row.id] = row.account;
    });
  }
};

const parseOverShortMapping = (forms, payload) => {
  if (forms.overShortMapping && forms.overShortMapping.dirty) {
    const values = forms.overShortMapping.values;
    payload.overShorts = {};
    values.overShortMapping.forEach(row => {
      payload.overShorts[row.id] = row.account;
    });
  }
};
const parseTippingMapping = (forms, payload) => {
  if (forms.tippingMapping && forms.tippingMapping.dirty) {
    const values = forms.tippingMapping.values;
    payload.tippings = {};
    values.tippingMapping.forEach(row => {
      payload.tippings[row.id] = row.account;
    });
  }
};

const parsePurchaseTax = (values) => {
  const mapping = {};

  if (values) {
    values.purchaseTaxMapping.forEach(taxData => {
      mapping[taxData.id] = taxData.account;
    });
  }

  return mapping;
};

const parseDashboard = (posType, values, forms, connectionMode) => {
  const syncRange = parseInt(values.syncRange, 12);
  const payload = {
    syncRange,
  };

  // Sync schedule
  if (syncRange === 0) {
    payload.syncRange = 0;
  }
  else if (syncRange === 1) {
    payload.syncRange = 1;
  }
  else if (syncRange > 1 && syncRange <= 8) {
    payload.weekDay = syncRange - 1;
    payload.syncRange = 2;
  }
  else if (syncRange === 9) {
    payload.weekDay = 2;
    payload.syncRange = 3;
  }
  else {
    payload.syncRange = 4;
  }

  payload.email1 = values.email;

  if (values.postingMethod !== '') {
    payload.defaultAccount = values.postingMethod === 'auto' ? 0 : 1;
    payload.useModificationdate = values.bookReceipts === '0' ? 0 : 1;
    payload.openInvoices = 0;


    if (posType.isKSeries || posType.isLSeries) {
      payload.invoiceType = values.createReconAs === 'draft' ? 1 : 0;
    }

    if (posType.isRetail) {
      payload.POInventoryCogs = values.isMapPoInventoryCogs;
    }

    if (values.postingMethod === 'auto') {
      payload.type = 0;
    } else {
      payload.type = 1;
      payload.recordItems = values.postingMethod === 'invoice';
      payload.journalEntry = values.postingMethod === 'journalEntry';
      payload.isSentDiscount = values.isMapDiscounts;
      payload.PaymentMethodTypeBreakdown = parseInt(values.splitCreditPayments, 10);
      payload.invoiceType = values.createReconAs === 'draft' ? 1 : 0;
      if (!posType.isRetail) {
        if (values.tipsAccount) {
          payload.Tips = values.tipsAccount;
        } else {
          payload.Tips = "";
        }
      }

      if (target.isRevel && values.serviceChargeAccount) {
        payload.ServiceCharge = values.serviceChargeAccount;
      }

      if (values.defaultPaymentAccount) {
        payload.defaultPaymentAccountName = values.defaultPaymentAccount;
        payload.enabledDefaultPaymentAccount = true;
      } else {
        payload.defaultPaymentAccountName = "";
        payload.enabledDefaultPaymentAccount = false;
      }

      if (values.isMapDiscounts) {
        if (values.defaultDiscountsAccount) {
          payload.defaultDiscountAccountName = values.defaultDiscountsAccount;
          payload.enabledDefaultDiscountAccount = true;
        } else {
          payload.defaultDiscountAccountName = "";
          payload.enabledDefaultDiscountAccount = false;
        }
        parseDiscountMapping(forms, payload);
      }

      if (values.defaultProductsAccount) {
        payload.incomeAccount = values.defaultProductsAccount;
      } else {
        payload.incomeAccount = "";
      }

      parsePaymentMapping(forms, payload);
      parseProductMapping(forms, payload);

      if (posType.isRetail) {
        parseIndividualCogsMapping(forms, payload);
      }
    }

    // Parse additional Settings
    payload.openInvoices = values.keepInvoicesOpen === '1' ? 1 : 0;
    payload.customTransactionNumber = values.transactionNumber;
    payload.defaultCustomer = values.customerName;

    payload.locationMapping = values.isMapClass && !values.classMapping;

    // Locations
    if (values.isMapClass && (values.locationMapping)) {
      payload.locationMapping = Boolean(values.locationMapping);
      payload.locations = getClassObject(values.locationMapping);
    }

    // class
    if (values.isMapClass && (values.classMapping)) {
      payload.classMapping = Boolean(values.classMapping);
      payload.classes = getClassObject(values.classMapping);
    }

    // Class and floor
    if (posType.isLSeries) {
      payload.splitRevenueRecordByFloor = values.isSplitRevenueRecordByFloor;
    }

    // Auto-Payout-tips
    payload.tipsMapping = values.isTipsPayout;

    if (posType.isRetail) {
      // eCom
      payload.eComSale = values.isMapECom;
      if (values.isMapECom && values.eComAccount) {
        payload.eComSaleMappedLocation = values.eComAccount;
      }

      // Over/Short
      payload.isSentOverShort = values.isMapOverShort;
      if (payload.isSentOverShort) {
        payload.defaultOverShortAccountName = values.overShortAccount;
        parseOverShortMapping(forms, payload);
      } else {
        payload.defaultOverShortAccountName = "";
      }

      // Tipping
      payload.recordTippings = values.isMapTipping;
      if (payload.recordTippings) {
        payload.tippingsAccount = values.tippingsAccount;
        parseTippingMapping(forms, payload);
      } else {
        payload.tippingsAccount = "";
      }

      // PO, Inventory and COGS
      if (payload.POInventoryCogs) {
        payload.isSentPurchaseOrder = values.isMapPurchaseInvoice;
        payload.trackInventory = values.isTrackInventory;
        payload.isSentCogs = values.isMapCogs;

        const inputCode = parseInt(['isMapPurchaseInvoice', 'isMapCogs', 'isTrackInventory',].map(field => {
          return Boolean(values[field]) ? 1 : 0;
        }).join(''), 2);

        if (inputCode !== 0) {
          payload.defaultInventoryAccountName = values.defaultInventoryAccount;

          if (inputCode !== 2) {
            payload.shipping = values.shippingAccount;
            payload.freightCharges = values.freightChargesAccount;
            payload.otherExpenses = values.otherExpensesAccount;
          }

          if (inputCode !== 4) {
            payload.defaultCogsAccountName = values.defaultCogsAccount;
          }
        }
      }
    }

    if (posType.isKSeries) {
      // Financial
      if (connectionMode === 'Datev') {
        payload.taxAdviserName = values.taxAdvisorName;
        payload.taxAdviserNumber = values.taxAdviserNumber;
        payload.mandatorNumber = values.mandatorNumber;
        payload.fiscalYearBegin = values.fiscalYearBegin;
        payload.interimAccount = values.interimAccount;
        payload.lengthOfLedgerAccount = values.lengthOfLedger;
      }
    }
  } else {
    payload.journalEntry = false;
    payload.tax = {}
    payload.vendorNameTax = {}
    payload.postingMethod = ""
    payload.type = 1;
  }

  return payload;
};

const parseTaxMapping = (values) => {
  const payload = {
    tax: {},
  };

  values?.taxMapping.forEach(taxData => {
    payload.tax[taxData.id] = taxData.account;
  });


  return payload;
};

const parseTaxVendorMapping = (values) => {
  const payload = {
    vendorNameTax: {}
  }

  values?.taxMapping.forEach(taxData => {
    payload.vendorNameTax[taxData.id] = taxData?.agency
  })

  return payload;
}

const parseFloorMapping = (values, flag) => {
  const payload = {
    floorLocationMapping: {},
    floorClassMapping: {}
  };


  if (!flag) {
    return payload;
  }

  values.floorMapping.forEach(floorData => {
    // floor location
    payload.floorLocationMapping[floorData.id] = floorData.location ?? "";
  })
  values.floorMapping.forEach(floorData => {
    // floor class
    payload.floorClassMapping[floorData.id] = floorData.account ?? "";
  })

  return payload;
};

export const createPayload = (posType, forms, meta, connectionMode, JeAsInvoice, defaultConfigs = {}) => {

  let payload = {
    connectionMode,
    isSentOverShort: false,
    trackInventory: false,
    isSentPayouts: false,
    recordTippings: false,
    JeAsInvoice: JeAsInvoice ?? false,
    payments: defaultConfigs?.payments,
    discountsMapping: defaultConfigs?.discountsMapping,
    tax: defaultConfigs?.tax,
    vendorNameTax: defaultConfigs?.vendorNameTax
  };

  // User changed posting method
  if (meta.isChangedPostingMethod === true) {
    payload.isParentCategory = true;
    payload.isResetConfiguration = true;
    payload.tax = {}
    payload.vendorNameTax = {}
    payload.poTaxes = {}
    payload.categorySpecificInventoryCogsAccounts = {}
    payload.items = {}
    payload.payments = {}
    payload.categories = {}
    payload.Tips = ""
    payload.ServiceCharge = ""
    payload.floorClassMapping = {}
    payload.floorLocationMapping = {}
  }

  // Parse all forms besides tax rates
  payload = Object.assign({}, payload, parseDashboard(posType, forms.dashboard.values, forms, connectionMode));

  // Parse tax mapping
  if (forms.dashboard.values.taxMapping) {
    payload = Object.assign({}, payload, parseTaxMapping(forms.dashboard.values));
    payload = Object.assign({}, payload, parseTaxVendorMapping(forms.dashboard.values));
  }

  // Parse floor mapping
  if (forms.floorMapping) {
    payload = Object.assign({}, payload, parseFloorMapping(forms.floorMapping.values, forms?.dashboard?.values?.isSplitRevenueRecordByFloor));
  }
  // Purchase tax
  if (payload.POInventoryCogs && forms?.purchaseTaxMapping?.dirty) {
    payload.poTaxes = parsePurchaseTax(forms?.purchaseTaxMapping?.values);
  }

  return payload;
};

const isNonNull = (val) => {
  let result = !([null, undefined, '', NaN]).includes(val);

  if (result && (typeof val === 'object')) {
    if (val.length && val.length === 0) {
      result = false;
    } else if (Object.keys(val) && Object.keys(val).length === 0) {
      result = false;
    }
  }

  return result;
}

const setConditional = (values, config, attributes, to, from, operation, operationArgs) => {
  let value;
  if (isNonNull(config[from])) {
    value = config[from];
  } else if (attributes && isNonNull(attributes[from])) {
    value = attributes[from];
  }

  if (isNonNull(value)) {
    if (operation === 'toString') {
      value = `${value}`;
    } else if (operation === 'toBoolean') {
      value = Boolean(value);
    } else if (operation === 'toClass') {
      value = parseClassMapping(value);
    } else if (operation === 'fromNested') {
      const nestedValue = value[operationArgs];
      value = isNonNull(nestedValue) ? nestedValue : null;
    }

    values[to] = value;
  }
};

export const parseFetchedConfig = (posType, config, taxConfig) => {
  // Parse attributes
  const attributes = (typeof config.attributes === 'string') ? JSON.parse(config.attributes) : config.attributes;
  // Parse syncRange
  let syncRange = config.syncRange ?? 1;
  if (syncRange === 2) {
    syncRange = 1 + attributes.weekDay;
  }
  else if (syncRange === 3) {
    syncRange = 9;
  }
  else if (syncRange === 4) {
    syncRange = 10;
  }
  const values = {
    email: config.email1 || '',
    syncRange: `${syncRange}`,
    postingMethod: ''
  };

  // parse tax
  if (Object.entries(config?.vendorNameTax ?? {})?.length === 0) {
    values.taxMapping = taxConfig.LSTaxRates.map(row => {
      return {
        taxRate: row?.name,
        account: config?.tax[row?.name] ?? "",
        id: row?.id
      }
    })
  } else {
    values.taxMapping = taxConfig.LSTaxRates.map(row => {
      return {
        taxRate: row?.name,
        account: config?.tax[row?.name] ?? "",
        agency: config?.vendorNameTax[row?.name] ?? "",
        id: row?.id
      }
    })
  }

  let operationsList = [];

  const configExists = config && !([null, undefined, ''].includes(config.configType));
  values.isMapClass = config.locationMapping || config.classMapping

  if (configExists) {
    operationsList = [
      ['customerName', 'defaultCustomer'],
      ['transactionNumber', 'customTransactionNumber'],
      ['locationMapping', 'locations', 'toClass'],
      ['classMapping', 'classes', 'toClass'],
      ['createReconAs', 'invoiceType'],
      ['isTipsPayout', 'tipsMapping', 'toBoolean']
    ];
  }

  if (config.configType === 0) {
    values.configType = 'auto';
  } else if (config.configType) {
    if (config.journalEntry) {
      values.postingMethod = 'journalEntry';
    } else if (config.recordItems === false) {
      values.postingMethod = 'invoiceCategory';
    } else {
      values.postingMethod = 'invoice';
    }

    operationsList = operationsList.concat([
      ['defaultProductsAccount', 'incomeAccount'],
      ['defaultPaymentAccount', 'defaultPaymentAccountName'],
      ['isMapDiscounts', 'isSentDiscount', 'toBoolean'],
      ['defaultDiscountsAccount', 'defaultDiscountAccountName'],
      ['tipsAccount', 'tips'],
      ['vendorNameTax', 'vendorNameTax'],
      ['tax', 'tax'],
      ['keepInvoicesOpen', 'openInvoices', 'toString'],
      ['bookReceipts', 'useModificationdate', 'toString'],
      ['splitCreditPayments', 'PaymentMethodTypeBreakdown', 'toString'],
      ['createReconAs', 'invoiceType'],
      ['tipsMapping', 'toBoolean']
    ]);

    if (posType.isRetail) {
      operationsList = operationsList.concat([
        ['isMapOverShort', 'isSentOverShort', 'toBoolean'],
        ['overShortAccount', 'defaultOverShortAccountName'],
        ['isMapTipping', 'recordTippings', 'toBoolean'],
        ['tippingsAccount', 'tippingsAccount'],
        ['isMapECom', 'eComSale', 'toBoolean'],
        ['eComAccount', 'eComSaleMappedLocation'],
        ['isMapPoInventoryCogs', 'POInventoryCogs', 'toBoolean'],
        ['isMapPurchaseInvoice', 'isSentPurchaseOrder', 'toBoolean'],
        ['isTrackInventory', 'trackInventory', 'toBoolean'],
        ['isMapCogs', 'isSentCogs', 'toBoolean'],
        ['shippingAccount', 'shipping'],
        ['freightChargesAccount', 'freightCharges'],
        ['otherExpensesAccount', 'otherExpenses'],
        ['defaultInventoryAccount', 'defaultInventoryAccountName'],
        ['defaultCogsAccount', 'defaultCogsAccountName'],
      ]);
    }
    if (posType.isLSeries) {
      operationsList = operationsList.concat([
        ['isSplitRevenueRecordByFloor', 'splitRevenueRecordByFloor', 'toBoolean']
      ]);
    }
    if (posType.isKSeries) {
      operationsList = operationsList.concat([
        ['taxAdvisorName', 'datevConfig', 'fromNested', 'taxAdviserName'],
        ['taxAdviserNumber', 'datevConfig', 'fromNested', 'taxAdviserNumber'],
        ['mandatorNumber', 'datevConfig', 'fromNested', 'mandatorNumber'],
        ['fiscalYearBegin', 'datevConfig', 'fromNested', 'fiscalYearBegin'],
        ['interimAccount', 'datevConfig', 'fromNested', 'interimAccount'],
        ['lengthOfLedger', 'datevConfig', 'fromNested', 'lengthOfLedgerAccount'],
        ['createReconAs', 'invoiceType'],
      ]);
    }

    if (target.isRevel) {
      operationsList.push(['serviceChargeAccount', 'serviceCharge']);
    }
  }

  operationsList.forEach(([to, from, operation, opArgs]) => {
    setConditional(values, config, attributes, to, from, operation, opArgs);
  });

  if (values.hasOwnProperty('createReconAs')) {
    values.createReconAs = values.createReconAs === 1 ? 'draft' : 'approved';
  }

  return values;
};


const getCleanState = (posType, taxRates = {}) => {
  const cleanState = {
    email: '',
    syncRange: '1',
    postingMethod: '',
    defaultProductsAccount: '',
    defaultPaymentAccount: '',
    splitCreditPayments: '0',
    isMapDiscounts: false,
    defaultDiscountsAccount: '',
    tipsAccount: '',
    customerName: '',
    transactionNumber: '',
    keepInvoicesOpen: '1',
    bookReceipts: '1',
    isMapClass: false,
    classMapping: '',
    locationMapping: '',
    isSplitRevenueRecordByFloor: false,
    floorLocationMapping: '',
    floorClassMapping: '',
    createReconAs: 'approved',
    tipsMapping: true,
  };

  if (target.isRevel) {
    cleanState.serviceChargeAccount = '';
  }

  if (Object.entries(taxRates).length !== 0) {
    cleanState.taxMapping = taxRates.LSTaxRates.map(row => {
      return {
        taxRate: row?.name,
        account: "",
        agency: "",
        id: row?.id
      }
    })
  }

  // Disabled for now
  if (false && posType.isKSeries) {
    cleanState.isMapDiff = false;
    cleanState.defaultDiffAccount = '';
  }

  if (posType.isRetail) {
    cleanState.eComAccount = '';
    cleanState.isMapECom = false;
    cleanState.overShortAccount = '';
    cleanState.isMapOverShort = false;
    cleanState.tippingsAccount = '';
    cleanState.isMapTipping = false;
    cleanState.isMapPoInventoryCogs = false;
    cleanState.isMapPurchaseInvoice = false;
    cleanState.isTrackInventory = false;
    cleanState.isMapCogs = false;
    cleanState.shippingAccount = '';
    cleanState.freightChargesAccount = '';
    cleanState.otherExpensesAccount = '';
    cleanState.defaultInventoryAccount = '';
    cleanState.defaultCogsAccount = '';
  }

  return cleanState;
};

export const getStateOnChangePostingMethod = (formik, posType, taxLabels = {}) => {
  let newState = {
    values: Object.assign({}, getCleanState(posType, taxLabels)),
    errors: {},
    touched: {},
  };

  ['email', 'syncRange', 'postingMethod'].forEach(field => {
    ['values', 'errors', 'touched',].forEach(key => {
      if (formik[key].hasOwnProperty(field)) {
        newState[key][field] = formik[key][field];
      }
    })
  });

  return newState;
};

export { getCleanState };
