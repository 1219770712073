/* eslint-disable no-control-regex */
import { useState, useEffect } from 'react';
import { PageLayout } from '../pageLayout/PageLayout';
import { Divider } from '@lightspeed/flame/Divider';
import { useToasts } from '@lightspeed/flame/Toaster';
import {
  fetchConfig,
  getDisconnectUser,
  getSyncStatus
} from '../../api/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { useLocationData } from '../../util/useLocation';
import { setSuccessAndFailCounts } from '../../state/portalInfoReducer';
import { AccountingSoftware } from './AccountingSoftware';
import { MapAccounts } from './MapAccounts';
import { Formik, Form } from 'formik';
import { usePersist } from '../../util/usePersist';
import { OptionsSection } from './OptionsSection';
import useConnectionMode from '../../util/useConnectionMode';
import { parseFetchedConfig, getCleanState } from '../../util/dashboard';
import useAppConnected from '../../util/hooks/useAppConnected';
import useAppSyncing from '../../util/hooks/useAppSyncing';
import { validateSwConnection } from '../../api/selectSoftware';
import { forgetSavedForms, setConfigSourceFetch } from '../../state/formsReducer';
import * as Yup from 'yup';
import { getCurrentTarget } from '../../util';
import usePosType from '../../util/hooks/usePosType';
import { useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';
import useCompanyName from '../../util/hooks/useCompanyName';
import { useMessage } from '../../util/hooks/useMessage';
import { LocationSection } from '../locationSection/LocationSection';
import { CogsInventorySection } from '../cogsInventory/CogsInventorySection';
import { useJeAsCategory } from '../../util/hooks/useJeAsCategory';
import { setLocationList } from '../../state/locationReducer';
import useIsInputsOnlyLayout from '../../util/hooks/useIsInputsOnlyLayout';
import { FinancialSection } from '../financial/Financial';
import { getTaxesMappingList } from '../../api/advancedConfig';
import { getTaxAgency } from '../../api/advancedConfig';
import { Freshchat } from 'reactjs-freshchat';
import 'reactjs-freshchat/dist/index.css';

import { setConfigurations, setErrorFields, setIfAnyErrorMsg, setIsTaxAgency, setRefetchSyncCount, setSupplierList, setTaxAccounts, setTaxLabels } from "../../state/globalsReducer"


const target = getCurrentTarget();

const DashboardContent = ({ currentLocation, onReconnectSuccess, ...props }) => {
  const [connectionMode, softwareName] = useConnectionMode();

  return <AccountingSoftware
    connectionMode={connectionMode}
    softwareName={softwareName}
    callbackOnReconnect={onReconnectSuccess}
    {...props}
  />;
};

const DashboardWrapper = ({
  formik,
  children,
  onFormDiscarded,
  isPendingDiscard,
  onSuccessfulSaveSubject,
  setInitialValues,
}) => {
  const persistFormName = 'dashboard';
  usePersist(formik, persistFormName);
  const posType = usePosType();
  const [updateInitialValuesPending, setUpdateInitialValuesPending] = useState(false);

  // Validate form when initialValues change
  useEffect(() => {
    formik.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.initialValues]);

  // Reset local form on discard
  useEffect(() => {
    if (isPendingDiscard) {
      formik.resetForm(formik.initialValues);
      onFormDiscarded(persistFormName, formik.initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPendingDiscard]);

  // Update initialValues to the current values
  useEffect(() => {
    if (updateInitialValuesPending) {
      setUpdateInitialValuesPending(false);

      // Reset stale values
      const newValues = Object.assign({}, formik.values);

      if (newValues.isMapClass === false) {
        delete newValues['classMapping'];
      }

      if (newValues.isMapDiscounts === false) {
        delete newValues['defaultDiscountsAccount'];
      }

      if (posType.isRetail) {
        if (newValues.isMapECom === false) {
          delete newValues['eComAccount'];
        }

        if (newValues.isMapOverShort === false) {
          delete newValues['overShortAccount'];
        }
        if (newValues.isMapTipping === false) {
          delete newValues['tippingsAccount'];
        }
        if (newValues.isTipsPayout === true) {
          delete newValues['tipsMapping'];
        }
        if (newValues.isSplitRevenueRecordByFloor === false) {
          delete newValues['splitRevenueRecordByFloor'];
        }
        if (newValues.isMapPoInventoryCogs === false) {
          delete newValues['shippingAccount'];
          delete newValues['freightChargesAccount'];
          delete newValues['otherExpensesAccount'];
          delete newValues['defaultInventoryAccount'];
          delete newValues['defaultCogsAccount'];
          newValues.isMapPurchaseInvoice = false;
          newValues.isTrackInventory = false;
          newValues.isMapCogs = false;
        }
      }
      // Update state
      const newInitialValues = Object.assign({}, getCleanState(posType), newValues);
      // Update state to make sure it isn't stale
      setInitialValues(newInitialValues);
      // resetForm is needed to mark dirty fields clean
      formik.resetForm(newInitialValues);
      setTimeout(() => {
        formik.validateForm(newInitialValues);
      });
    }
  }, [formik, posType, setInitialValues, updateInitialValuesPending]);

  // Register subscriptions. This should be run only once to avoid re-subscribing which will throw error
  useEffect(() => {
    onSuccessfulSaveSubject.subscribe(() => {
      setUpdateInitialValuesPending(true);
    });

    return () => {
      onSuccessfulSaveSubject.unsubscribe();
    }
  }, [onSuccessfulSaveSubject]);

  return <Form>{children}</Form>;
};

const getValidationSchema = (posType, t) => {
  const schemaObject = {
    postingMethod: Yup.string()
      .oneOf(['auto', 'journalEntry', 'invoiceCategory', 'invoice']),
    email: Yup.string()
      .test(
        'Required',
        t('Email is required'),
        (value) => Boolean(value),
      ).test(
        'no-space',
        t('Spaces not allowed'),
        (value, context) => value && value.split('').some(ch => ch !== ' '),
      ).test(
        'has-at',
        t('Must have one @ symbol'),
        (value, context) => value && value.split('@').length === 2,
      ).test(
        'has-domain',
        t('Must have a domain'),
        (value, context) => value && Boolean(value.split('@')[1]),
      ).test(
        'one-dot',
        t('At least one dot character'),
        (value, context) => value && value.split('').filter(ch => ch === '.').length >= 1,
      ).test(
        'valid-domain',
        t('Must have a valid domain'),
        (value, context) => value &&
          Boolean(value.split('@')[1]) &&
          value.split('@')[1].split('.').filter(Boolean).length > 1,
      ).test(
        'valid email',
        t('Email is not valid'),
        (value, context) => {
          // eslint-disable-next-line no-useless-escape
          const rEmail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

          let result = false;
          if (value) {
            result = value.match(rEmail);
          }

          return result;
        }

      ),
    defaultDiscountsAccount: Yup.string()
      .when(['postingMethod', 'isMapDiscounts'], {
        is: (method, isEnabled) => isEnabled && (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    defaultPaymentAccount: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    defaultProductsAccount: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    tipsAccount: Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      }),
    customerName: Yup.string()
      .required(t('Required')),
    locationMapping: Yup.string()
      .when(['isMapClass'], {
        is: (isEnabled) => isEnabled,
        then: Yup.string().required(" "),
      }),
    classMapping: Yup.string()
      .when(['isMapClass'], {
        is: (isEnabled) => isEnabled,
        then: Yup.string().required(" "),
      }),
    taxMapping: Yup.array()
      .of(
        Yup.object().shape({
          account: Yup.string().required(t('Required')),
          agency: Yup.string().required(t('Required')),
        })
      )
  };

  if (target.isRevel) {
    schemaObject.serviceChargeAccount = Yup.string()
      .when('postingMethod', {
        is: method => (method !== 'auto'),
        then: Yup.string().required(t('Required')),
      });
  }

  if (posType.isKSeries) {
    schemaObject.defaultDiffAccount = Yup.string()
      .when(['isMapDiff'], {
        is: (isEnabled) => isEnabled,
        then: Yup.string().required(t('Required')),
      });
    schemaObject.taxAdvisorName = Yup.string().nullable(true).required(t('Required'));
    schemaObject.taxAdviserNumber = Yup.string().nullable(true).required(t('Required'));
    schemaObject.mandatorNumber = Yup.string().nullable(true).required(t('Required'));
    schemaObject.fiscalYearBegin = Yup.string().nullable(true).required(t('Required'));
    schemaObject.lengthOfLedger = Yup.string().nullable(true).required(t('Required'));
  }

  if (posType.isRetail) {
    schemaObject.shippingAccount = Yup.string()
      .when(['postingMethod', 'isMapPoInventoryCogs', 'isMapPurchaseInvoice'], {
        is: (method, isEnabled, isMapPurchaseInvoice) => {
          return (method !== 'auto') && isEnabled && isMapPurchaseInvoice;
        },
        then: Yup.string().required(t('Required')),
      });
    schemaObject.freightChargesAccount = Yup.string()
      .when(['postingMethod', 'isMapPoInventoryCogs', 'isMapPurchaseInvoice'], {
        is: (method, isEnabled, isMapPurchaseInvoice) => {
          return (method !== 'auto') && isEnabled && isMapPurchaseInvoice;
        },
        then: Yup.string().required(t('Required')),
      });
    schemaObject.otherExpensesAccount = Yup.string()
      .when(['postingMethod', 'isMapPoInventoryCogs', 'isMapPurchaseInvoice'], {
        is: (method, isEnabled, isMapPurchaseInvoice) => {
          return (method !== 'auto') && isEnabled && isMapPurchaseInvoice;
        },
        then: Yup.string().required(t('Required')),
      });
    schemaObject.defaultInventoryAccount = Yup.string()
      .when(['postingMethod', 'isMapPoInventoryCogs', 'isMapCogs', 'isMapPurchaseInvoice'], {
        is: (method, isEnabled, isMapCogs, isMapPurchaseInvoice) => {
          return (method !== 'auto') && isEnabled && (isMapCogs || isMapPurchaseInvoice);
        },
        then: Yup.string().required(t('Required')),
      });
    schemaObject.defaultCogsAccount = Yup.string()
      .when(['postingMethod', 'isMapPoInventoryCogs', 'isMapCogs', 'isMapPurchaseInvoice', 'isTrackInventory'], {
        is: (method, isEnabled, isMapCogs, isMapPurchaseInvoice, isTrackInventory) => {
          return (method !== 'auto') && isEnabled && (isMapCogs || (isMapPurchaseInvoice && isTrackInventory));
        },
        then: Yup.string().required(t('Required')),
      });
    schemaObject.overShortAccount = Yup.string()
      .when(['isMapOverShort'], {
        is: (isEnabled) => isEnabled,
        then: Yup.string().required(t('Required')),
      });
    schemaObject.tippingsAccount = Yup.string()
      .when(['isMapTipping'], {
        is: (isEnabled) => isEnabled,
        then: Yup.string().required(t('Required')),
      });
    schemaObject.eComAccount = Yup.string()
      .when(['isMapECom'], {
        is: (isEnabled) => isEnabled,
        then: Yup.string().required(t('Required')),
      });
  }

  return Yup.object(schemaObject);
};

export const Dashboard = (props) => {

  const [isConfigLoading, setIsConfigLoading] = useState(true);
  const [isHydrated, setIsHydrated] = useState(false);
  const savedForm = useSelector(state => state.forms.entities.dashboard);
  const savedFormsMeta = useSelector(state => state.forms.meta);
  const [fetchedConfig, setFetchedConfig] = useState();
  const [isConnected, setConnected] = useAppConnected();
  const [, setAppSyncing] = useAppSyncing();
  const { currentLocation, isMultiLocation } = useLocationData();
  const dispatch = useDispatch();
  const posType = usePosType();
  const [initialValues, setInitialValues] = useState(getCleanState(posType, {}));
  const [isPendingDiscard, setIsPendingDiscard] = useState(false);
  const { t } = useTranslation();
  const [onSuccessfulSaveSubject] = useState(new Subject());
  const [, setCompanyName] = useCompanyName();
  const { addMessage } = useMessage();
  const [, setJeAsCategory] = useJeAsCategory();
  const [connectionMode, , setConnectionMode] = useConnectionMode();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const [isUserDeactivated, setIsUserDeactivated] = useState(false);
  const [fetchedTaxrates, setFetchedTaxRate] = useState();
  const { taxLabels } = useSelector(state => state.globals)
  const { isTaxAgency, supplierList, refreshSyncCount } = useSelector(state => state.globals);
  const [isRefetchConfig, setRefetchConfig] = useState(false);

  useEffect(() => {
    if (fetchedConfig && !fetchedTaxrates) {
      setIsConfigLoading(true);

      const params = {
        journalEntry: (!fetchedConfig?.JeAsInvoice) && fetchedConfig?.journalEntry
      }
      getTaxesMappingList(params, currentLocation.id).then(res => {
        setFetchedTaxRate(res.data)
        dispatch(setTaxLabels(res.data))
        dispatch(setTaxAccounts(res.data.accountingSoftware.map(op => {
          return {
            value: op.id,
            label: op.name,
          }
        })))

        setIsConfigLoading(false);
      }).catch(err => { 
        console.error(err)
        setIsConfigLoading(false);
       })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps	
  }, [fetchedConfig, fetchedTaxrates])

  useEffect(() => {
    if (isTaxAgency && supplierList.length === 0) {
      getTaxAgency(currentLocation.id).then(res => {
        let data = []
        // tax agency option transformation	
        for (let i in res.data) {
          if (res.data[i].name !== "") {
            data.push({
              value: res.data[i].name,
              label: res.data[i].name
            })
          }
        }
        dispatch(setSupplierList(data))
      }).catch(err => {
        console.error(err)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps	
  }, [isTaxAgency])

  useEffect(() => {
    isRefetchConfig && getConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps	
  }, [isRefetchConfig])

  useEffect(() => {
    isRefetchConfig && getConfig();
    setRefetchConfig(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetchConfig]);

  // Check that software is connected
  useEffect(() => {
    let mounted = true;
    validateSwConnection(currentLocation.id).then(res => {
      if (mounted) {
        if (res.data) {
          setCompanyName(res.data.companyName);
          setJeAsCategory(res?.data?.JeAsInvoice === true);
          dispatch(setIsTaxAgency(res?.data?.taxAgency ?? false))
        }
        // Set connected status
        if (isConnected !== true) {
          setConnected(true);
        }
        getConfig();
      }
    }).catch(err => {
      if (mounted) {
        const res = err.response;
        if (
          res &&
          (
            [430].includes(res.status) ||
            // Revel backend is unable to send 430 status
            (target.isRevel && res.status === 424)
          ) &&
          res.data
        ) {
          setConnected(false);
        } else if (res && (res.status === 404) && res.data) {
          addMessage(res, { default: t('Company not found') });
          props.navigate('../selectsw');
        } else {
          addMessage(res);
        }
      }
    });

    return () => { mounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshSyncCount) {
      getSyncStatus(currentLocation.id).then(res => {
          dispatch(setRefetchSyncCount(false))
          dispatch(setSuccessAndFailCounts(res?.data?.syncStatus));
      })
      .catch((e) => {
        dispatch(setRefetchSyncCount(false))
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshSyncCount])

  // Fetch and save latest data
  useEffect(() => {
    if (isConnected && !isConfigLoading) {
      getConfig();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  // Rehydrate form on first render
  useEffect(() => {
    if (savedForm && savedForm.dirty) {
      setInitialValues(savedForm.values);
      setFetchedTaxRate(taxLabels)
      setIsHydrated(true);
      setIsConfigLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Pre-populate form
  useEffect(() => {
    if (!fetchedTaxrates && taxLabels.LSTaxRate) {
      setFetchedTaxRate(taxLabels)
    }

    if (fetchedConfig && !isHydrated && fetchedTaxrates) {
      // Parse other values
      const updatedValues = parseFetchedConfig(posType, fetchedConfig, fetchedTaxrates);
      setInitialValues(Object.assign({}, initialValues, updatedValues));
      setIsConfigLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedConfig, fetchedTaxrates]);

  useEffect(() => {
    if (fetchedConfig && fetchedTaxrates) {
      setIsConfigLoading(false);
    }
  }, [fetchedConfig, fetchedTaxrates])

  const getConfig = () => {
    setIsConfigLoading(true);
    fetchConfig(currentLocation.id).then(res => {
      setIsConfigLoading(false);
      dispatch(setConfigurations({ ...res?.data?.attributes, ...res?.data}))
      const resData = {
        ...res?.data?.attributes, 
        ...res.data,
      };
      const errMsg = resData?.error;
      if (errMsg) {
        dispatch(setErrorFields(transformFields(errMsg.fields)))
        dispatch(setIfAnyErrorMsg(errMsg.message))
      }

      if (res?.data?.connectionMode) {
        setConnectionMode(res?.data?.connectionMode)
      }

      setFetchedConfig(resData || {});
      dispatch(setConfigSourceFetch());
      setAppSyncing(Boolean(resData && resData.isConfigured));
    }).catch(err => {
      const res = err && err.response;
      const mappingNotDoneCode = target.isRevel ? 403 : 430;
      if (res && (res.status === mappingNotDoneCode)) {
        setAppSyncing(false);
        setIsConfigLoading(false);
      }
      addMessage(res, { default: t('Fetch failure') });
    });
  }

  const transformFields = (data) => {
    let mapping = [
      ['defaultProductsAccount', 'incomeAccount'],
      ['defaultPaymentAccount', 'defaultPaymentAccountName'],
      ['defaultDiscountsAccount', 'defaultDiscountAccountName'],
      ['tipsAccount', 'Tips'],
      ['createReconAs', 'invoiceType'],
      ['overShortAccount', 'defaultOverShortAccountName'],
      ['eComAccount', 'eComSaleMappedLocation'],
      ['shippingAccount', 'shipping'],
      ['freightChargesAccount', 'freightCharges'],
      ['otherExpensesAccount', 'otherExpenses'],
      ['defaultInventoryAccount', 'defaultInventoryAccountName'],
      ['defaultCogsAccount', 'defaultCogsAccountName'],
      ['serviceChargeAccount', 'serviceCharge'],
      ['classMapping', 'locations']
    ]

    let defaultAccounts = [...data?.defaultMapping];

    for (let i = 0; i < defaultAccounts?.length; i++) {
      for (let j = 0; j < mapping?.length; j++) {
        if (mapping[j][1] === defaultAccounts[i]) {
          defaultAccounts[i] = mapping[j][0]
        }
      }
    }

    return {
      ...data,
      defaultAccounts
    }
  }

  const discardData = () => {
    let updatedValues = {
      postingMethod: '',
    };
    if (savedFormsMeta.lastSave.source === 'save') {
      updatedValues = savedFormsMeta.lastSave.entities.dashboard.values;
      setIsConfigLoading(false);
    } else if (fetchedConfig) {
      updatedValues = parseFetchedConfig(posType, fetchedConfig, fetchedTaxrates);
      setIsConfigLoading(false);
    }
    setInitialValues(Object.assign({}, getCleanState(posType, {}), updatedValues));
    setIsPendingDiscard(true);
  };

  const onFormDiscarded = (formName, values) => {
    setIsPendingDiscard(false);
    // Avoid flashing of content due to empty state on discard
    dispatch(forgetSavedForms({ name: formName, values }));
  };

  const handleSuccessfulSave = () => {
    // Signal to update initialValues
    onSuccessfulSaveSubject.next();
  };

  const disconnectUserLs = () => {

    getDisconnectUser(currentLocation.id).then(res => {
      setIsUserDeactivated(true);

    }).catch(err => {
      const res = err && err.response;
      addMessage(res, { default: t('Something went wrong for user deactivation') });
      setIsUserDeactivated(false);
    });
  }

  return <PageLayout
    loadAccounts={connectionMode && isConnected && (isInputsOnlyLayout === false)}
    showDisconnected={isConnected === false}
    discardData={discardData}
    handleSuccessfulSave={handleSuccessfulSave}
    showBack={isMultiLocation}
    path={props.path}
    navigate={props.navigate}
    title={isMultiLocation && currentLocation.name}
    description={isMultiLocation && t('locationMappingDesc')}
    disconnectUser={disconnectUserLs}
    isUserDeactivated={isUserDeactivated}
  >
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={getValidationSchema(posType, t)}
      validateOnMount={true}
    >
      {formik =>
        <DashboardWrapper
          formik={formik}
          isPendingDiscard={isPendingDiscard}
          onFormDiscarded={onFormDiscarded}
          onSuccessfulSaveSubject={onSuccessfulSaveSubject}
          setInitialValues={setInitialValues}
        >
          <DashboardContent
            formik={formik}
            isLoading={isConfigLoading}
            isSyncStatusLoading={false}
            currentLocation={currentLocation}
            fetchedConfig={fetchedConfig}
            onReconnectSuccess={() => setRefetchConfig(true)}
            {...props}
          />
          <Divider />
          {formik.values.postingMethod &&
            ((!posType.isKSeries) || (typeof isInputsOnlyLayout === "boolean")) &&
            <>
              <MapAccounts
                formik={formik}
                fetchedConfig={fetchedConfig}
                {...props}
              />
              {
                posType.isRetail &&
                <>
                  <Divider />
                  <CogsInventorySection
                    formik={formik}
                    fetchedConfig={fetchedConfig}
                    {...props} />
                </>
              }
              {
                (posType.isRetail) &&
                <>
                  <Divider />
                  <LocationSection
                    formik={formik}
                    fetchedConfig={fetchedConfig}
                    {...props} />
                </>
              }
              {
                (isInputsOnlyLayout && connectionMode !== "Acomba") &&
                <>
                  <Divider />
                  <FinancialSection formik={formik} {...props} />
                </>
              }
              {
                (Boolean(formik.values.postingMethod)) &&
                (!isInputsOnlyLayout || connectionMode === "Acomba") &&
                <>
                  <Divider />
                  <OptionsSection
                    formik={formik}
                    fetchedConfig={fetchedConfig}
                    {...props}
                  />
                </>
              }
            </>
          }
        </DashboardWrapper>
      }
    </Formik>
    <Freshchat
      token={'00b6ddea-bee5-4a57-b767-e51cb812490d'}
    />
  </PageLayout>;
};