import { useState, useEffect } from 'react';
import { Box, Flex } from '@lightspeed/flame/Core';
import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import useLocation from '../../util/useLocation';
import { getLocationsList } from '../../api/advancedConfig';
import useConnectionMode from '../../util/useConnectionMode';
import { usePosType } from '../../util/hooks/usePosType';
import { useTranslation } from 'react-i18next';
import { useMessage } from '../../util/hooks/useMessage';
import { SwitchLayout } from '../custom/SwitchLayout';
import useIsInputsOnlyLayout from '../../util/hooks/useIsInputsOnlyLayout';
import { setLocations } from '../../state/globalsReducer';
import { useDispatch, useSelector } from 'react-redux';

const descriptionKey = {
  'retail': 'ClassMappingRetail',
  'kseries': 'ClassMappingKSeries',
  'resto': 'ClassMappingLSeries',
};

const headingKey = {
  'retail': 'Location',
  'kseries': 'Location tracking',
  'resto': 'Class',
};

const inputLabelKey = {
  'retail': 'Select a location',
  'kseries': 'SelectClassKSeries',
  'resto': 'SelectClassLSeries',
};

const placeholderKey = {
  'retail': 'Select a location',
  'kseries': 'SelectClassKSeries',
  'resto': 'SelectClassLSeries',
};

export const ClassMapping = ({ formik }) => {
  const dispatch = useDispatch()
  const [classList, setClassList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { isMapClass } = formik.values;
  const currentLocation = useLocation();
  const [, swName] = useConnectionMode();
  const posType = usePosType();
  const { t } = useTranslation();
  const { addMessage } = useMessage();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const locationList = useSelector((state) => state.globals.locations)

  useEffect(() => {
    if (locationList.length !== 0) {
      setClassList(locationList);
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    classList && setIsLoading(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classList])

  useEffect(() => {
    let mounted = true;
    if (isMapClass && !classList && (isInputsOnlyLayout === false)) {
      setIsLoading(true);
      getLocationsList(currentLocation.id).then(res => {
        if (mounted === true) {
          let list = res.data.map(op => {
            return {
              value: op.id,
              label: op.name,
            }
          })
          setClassList(list);
          dispatch(setLocations(list))
          setIsLoading(false);
        }
      })
      .catch(err => {
        if (mounted) {
          // Reset switch
          formik.setFieldValue('isMapClass', false);
          setIsLoading(false);
          // Show toast
          addMessage(err.response, { default: t('Fetch failure') });
        }
      })
    }

    return () => { mounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classList, currentLocation.id, isMapClass, isInputsOnlyLayout, locationList]);

  return <SwitchLayout
    heading={t(headingKey[posType.name], { swName })}
    description={t(descriptionKey[posType.name], { swName })}
    switchFieldName="isMapClass"
    isSwitchOn={isMapClass}
    isLoading={isLoading}
  >
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="locationMapping">
          {t(inputLabelKey[posType.name])}
        </LabelRequired>
        <Autocomplete
          id="locationMapping"
          name="locationMapping"
          isLoadingOptions={!classList}
          options={classList}
          placeholder={t(placeholderKey[posType.name])}
          formik={formik}
        />
      </Box>
    </Flex>
  </SwitchLayout>;
};